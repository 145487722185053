import {
  PRICE_BREAKDOWN_SECTIONS,
  PriceBreakdownSectionProps,
} from '../entire-offer/entire-offer.types';
import { useContent } from '../content/offers-provider.content.provider';
import { useTaxes } from './price-breakdown.taxes.hook';
import { useSettings } from '../settings/offers-provider.settings.provider';
import { useMemo } from 'react';
import { usePriceLabels } from '../hooks/usePriceLabels';
import { AfterCheckoutInputProps } from './price-breakdown.types';
import {
  BREAKDOWN_ITEM_CONTENT_FIELD_BY_KEY,
  PRICE_SUMMARY_TEST_IDS,
} from './price-breakdown.constants';
import { getTestIdFromContentField } from '../utils';

const TEST_ID = PRICE_SUMMARY_TEST_IDS.DUE_BEFORE_DELIVERY;

export const useAfterCheckout = ({
  additionals,
  discountRateDisplay,
  discountRateDisplaySources,
  downPayment,
  financingPriceSummary,
  isCash,
  isDiscountApplied,
  isLeaseOrLoan,
  isDirectSales,
  priceSummary,
  vehiclePrice,
}: AfterCheckoutInputProps): PriceBreakdownSectionProps => {
  const { translate } = useContent();
  const {
    enableDownPayment,
    enableTwoPartDeposit,
    enablePriceBreakdownFirstMonthlyPaymentLineItem,
    enableEditConfiguration,
    dueTodayBreakdownItemContentField,
    enableDepositLineItem,
    marketChecks,
    showPreOrderSummaryFlow,
    supportsVAT,
  } = useSettings();
  const { getVatLabel, getVatLabelSources, totalLabel, totalLabelSources } =
    usePriceLabels();

  const leaseAndLoanIncludingVAT =
    enablePriceBreakdownFirstMonthlyPaymentLineItem
      ? financingPriceSummary?.breakdown?.totalDeposit?.displayPrice
          ?.includingVat
      : financingPriceSummary?.breakdown?.downpayment?.amount?.displayPrice
          ?.includingVat;

  const isPriceIncludingVAT = isLeaseOrLoan
    ? leaseAndLoanIncludingVAT
    : priceSummary?.aggregations?.total?.paymentPlan?.afterCheckout
        ?.displayPrice?.includingVat;

  const { taxes } = useTaxes({
    isDirectSales,
    isPriceExcludingVAT: !isPriceIncludingVAT,
    testId: TEST_ID,
    priceSummary,
    isUSNewModel: marketChecks?.US.isUSNewModel,
  });

  const isDownPaymentChosen =
    enableDownPayment && typeof downPayment?.value === 'number';

  const downPaymentDisplay = financingPriceSummary?.breakdown
    ? financingPriceSummary?.breakdown?.downpayment?.amount?.displayPrice
        ?.display ?? ''
    : priceSummary?.payments?.find(
        (payment) => payment.paymentType === 'downpayment',
      )?.amount?.displayPrice?.display ?? '';
  const downPaymentPrice = financingPriceSummary?.breakdown
    ? financingPriceSummary?.breakdown?.downpayment?.amount?.displayPrice
        ?.amount ?? 0
    : priceSummary?.payments?.find(
        (payment) => payment.paymentType === 'downpayment',
      )?.amount?.displayPrice?.amount ?? 0;

  const downPaymentVATAmount = financingPriceSummary?.breakdown
    ? financingPriceSummary?.breakdown?.downpayment?.amount?.vatAmount
        ?.amount ?? 0
    : priceSummary?.payments?.find(
        (payment) => payment.paymentType === 'downpayment',
      )?.amount?.vatAmount?.amount ?? 0;

  // After Checkout Price Text for Lease and Loan (PCP/VL)
  const afterCheckoutPriceTextForLeaseAndLoan =
    enablePriceBreakdownFirstMonthlyPaymentLineItem
      ? // After Checkout Total Price for US binding orders
        financingPriceSummary?.breakdown?.totalDeposit?.displayPrice?.display ??
        ''
      : financingPriceSummary?.breakdown?.downpayment?.amount?.displayPrice
          ?.display ?? '';
  const afterCheckoutPriceForLeaseAndLoan =
    enablePriceBreakdownFirstMonthlyPaymentLineItem
      ? // After Checkout Total Price for US binding orders
        financingPriceSummary?.breakdown?.totalDeposit?.displayPrice?.amount ??
        0
      : financingPriceSummary?.breakdown?.downpayment?.amount?.displayPrice
          ?.amount ?? 0;

  // After Checkout Price Text for all sales models except Lease and Loan (PCP/VL)
  const afterCheckoutPriceText =
    priceSummary?.aggregations?.total?.paymentPlan?.afterCheckout?.displayPrice
      ?.display ?? '';

  const afterCheckoutPrice =
    priceSummary?.aggregations?.total?.paymentPlan?.afterCheckout?.displayPrice
      ?.amount ?? 0;

  const refundableDepositPrice =
    priceSummary?.aggregations?.total?.paymentPlan?.atCheckout?.displayPrice
      ?.amount ?? 0;

  const refundableDepositPriceText =
    priceSummary?.aggregations?.total?.paymentPlan?.atCheckout?.displayPrice
      ?.display ?? '';

  const refundableDepositVatAmount =
    priceSummary?.aggregations?.total?.paymentPlan?.atCheckout?.vatAmount
      ?.amount ?? 0;

  const beforeDeliveryPrice = isLeaseOrLoan
    ? afterCheckoutPriceForLeaseAndLoan
    : afterCheckoutPrice;

  const beforeDeliveryPriceText = isLeaseOrLoan
    ? afterCheckoutPriceTextForLeaseAndLoan
    : afterCheckoutPriceText;

  const downPaymentPriceTextForLeaseAndLoan =
    financingPriceSummary?.breakdown?.downpayment?.amount?.displayPrice
      ?.display ?? '';
  const downPaymentPriceForLeaseAndLoan =
    financingPriceSummary?.breakdown?.downpayment?.amount?.displayPrice
      ?.amount ?? 0;
  const firstMonthPaymentPriceTextForLeaseAndLoan = enableEditConfiguration
    ? financingPriceSummary?.breakdown?.firstMonthPayment?.display ?? ''
    : financingPriceSummary?.breakdown?.perInstallmentPrice?.display ?? '';
  const firstMonthPaymentPriceForLeaseAndLoan = enableEditConfiguration
    ? financingPriceSummary?.breakdown?.firstMonthPayment?.amount ?? 0
    : financingPriceSummary?.breakdown?.perInstallmentPrice?.amount ?? 0;

  // Is this correct?
  const firstMonthPaymentPriceTextForLeaseAndLoanVAT =
    financingPriceSummary?.breakdown?.perInstallmentPrice?.taxAmount ?? 0;

  const shouldShowSection = enablePriceBreakdownFirstMonthlyPaymentLineItem
    ? !!financingPriceSummary?.breakdown?.totalDeposit?.displayPrice?.amount
    : isLeaseOrLoan
      ? !!financingPriceSummary?.breakdown?.downpayment?.amount?.displayPrice
          ?.amount
      : !!priceSummary?.aggregations?.total?.paymentPlan?.afterCheckout
          ?.displayPrice?.amount;

  // After Checkout Total Price With VAT Text for Lease and Loan (PCP/VL)
  const afterCheckoutTotalPriceTextWithVATForLeaseAndLoan =
    financingPriceSummary?.breakdown?.downpayment?.amount?.priceInclTax
      ?.display ?? '';
  const afterCheckoutTotalPriceWithVATForLeaseAndLoan =
    financingPriceSummary?.breakdown?.downpayment?.amount?.priceInclTax
      ?.amount ?? 0;

  // After Checkout Total Price With VAT Text for all sales models except Lease and Loan (PCP/VL)
  const afterCheckoutTotalPriceTextWithVAT =
    priceSummary?.aggregations?.total?.paymentPlan?.afterCheckout?.priceInclTax
      ?.display ?? '';
  const afterCheckoutTotalPriceWithVAT =
    priceSummary?.aggregations?.total?.paymentPlan?.afterCheckout?.priceInclTax
      ?.amount ?? 0;

  const beforeDeliveryTotalPriceTextWithVAT = isLeaseOrLoan
    ? afterCheckoutTotalPriceTextWithVATForLeaseAndLoan
    : afterCheckoutTotalPriceTextWithVAT;
  const beforeDeliveryTotalPriceWithVAT = isLeaseOrLoan
    ? afterCheckoutTotalPriceWithVATForLeaseAndLoan
    : afterCheckoutTotalPriceWithVAT;

  // After Checkout VAT Price Text for Lease and Loan (PCP/VL)
  const afterCheckoutVatTextForLeaseAndLoan =
    financingPriceSummary?.breakdown?.downpayment?.amount?.vatAmount;

  // After Checkout VAT Price Text for all sales models except Lease and Loan (PCP/VL)
  const afterCheckoutVatText =
    priceSummary?.aggregations?.total?.paymentPlan?.afterCheckout?.vatAmount;

  const beforeDeliveryVAT = isLeaseOrLoan
    ? afterCheckoutVatTextForLeaseAndLoan?.display ?? ''
    : afterCheckoutVatText?.display ?? '';

  const beforeDeliveryVATAmount = isLeaseOrLoan
    ? afterCheckoutVatTextForLeaseAndLoan?.amount ?? 0
    : afterCheckoutVatText?.amount ?? 0;

  const shouldShowVAT = supportsVAT && beforeDeliveryVATAmount > 0;

  const followsCustomerAccessories = additionals?.accessories?.followsCustomer;
  const services = additionals?.services;
  const followsCustomerPackages = additionals?.packages?.followsCustomer;
  const followsCarAccessories = additionals?.accessories?.followsCar;
  const followsCarPackages = additionals?.packages?.followsCar;

  const vatLabel = getVatLabel(!!isPriceIncludingVAT);
  const vatLabelSources = getVatLabelSources(!!isPriceIncludingVAT);

  return useMemo(() => {
    const breakdownItemContentField =
      BREAKDOWN_ITEM_CONTENT_FIELD_BY_KEY[
        dueTodayBreakdownItemContentField ?? ''
      ];

    return {
      sectionType: PRICE_BREAKDOWN_SECTIONS.BEFORE_DELIVERY,
      shouldShowSection,
      price: beforeDeliveryPrice,
      priceText: beforeDeliveryPriceText,
      priceTextSources: ['data:beforeDeliveryPriceText'],
      displayName:
        !isDirectSales && !isLeaseOrLoan && showPreOrderSummaryFlow
          ? translate('PriceBreakdown.priceSummary.totalPrice')
          : translate('PriceBreakdown.priceSummary.dueBeforeDelivery'),
      displayNameSources:
        !isDirectSales && !isLeaseOrLoan && showPreOrderSummaryFlow
          ? ['sharedDict:PriceBreakdown.priceSummary.totalPrice']
          : ['sharedDict:PriceBreakdown.priceSummary.dueBeforeDelivery'],
      vatLabel,
      vatLabelSources,
      testId: TEST_ID,
      priceBreakdownItems: [
        ...(enablePriceBreakdownFirstMonthlyPaymentLineItem
          ? [
              {
                displayName: translate(
                  'PriceBreakdown.priceSummary.firstMonthlyPayment',
                ),
                displayNameSources: [
                  'sharedDict:PriceBreakdown.priceSummary.firstMonthlyPayment',
                ],
                testId: `${TEST_ID}-first-monthly-payment`,
                vatLabel,
                vatLabelSources,
                price: firstMonthPaymentPriceForLeaseAndLoan,
                priceText: firstMonthPaymentPriceTextForLeaseAndLoan,
                priceTextSources: [
                  'data:firstMonthPaymentPriceTextForLeaseAndLoan',
                ],
                hideIndicativePrice: true,
                shouldShowVatLabel:
                  firstMonthPaymentPriceTextForLeaseAndLoanVAT > 0,
              },
              ...taxes,
            ]
          : []),
        ...(!isDownPaymentChosen
          ? [
              ...(isCash || !isDirectSales
                ? [
                    ...(isDiscountApplied
                      ? [
                          {
                            displayName: translate(
                              'PriceBreakdown.priceBreakdown.vehiclePriceBeforeDiscount',
                            ),
                            displayNameSources: [
                              'sharedDict:PriceBreakdown.priceBreakdown.vehiclePriceBeforeDiscount',
                            ],
                            testId: `${TEST_ID}-vehicle-price-before-discount`,
                            vatLabel: vehiclePrice.vatLabelBeforeDiscount,
                            vatLabelSources:
                              vehiclePrice.vatLabelBeforeDiscountSources.concat(
                                discountRateDisplaySources,
                              ),
                            price: vehiclePrice.beforeDiscountAmount ?? 0,
                            priceText: vehiclePrice.beforeDiscount ?? '',
                            priceTextSources: [
                              'data:vehiclePrice.beforeDiscount',
                            ],
                            shouldShowVatLabel:
                              vehiclePrice.vatAmountBeforeDiscount > 0,
                          },
                          {
                            displayName: `${translate(
                              'PriceBreakdown.priceBreakdown.discount',
                              {
                                discount: discountRateDisplay,
                              },
                            )}`,
                            displayNameSources: [
                              'sharedDict:PriceBreakdown.priceBreakdown.discount',
                              'data:discountRateDisplay',
                            ],
                            testId: `${TEST_ID}-discount`,
                            vatLabel,
                            vatLabelSources: vatLabelSources.concat(
                              discountRateDisplaySources,
                            ),
                            price: -(vehiclePrice.discountAmountValue ?? 0),
                            priceText: `- ${vehiclePrice.discountAmount}`,
                            priceTextSources: [
                              'text:-',
                              'data:vehiclePrice.discountAmount',
                            ],
                            shouldShowVatLabel:
                              vehiclePrice.vatAmountAfterDiscount > 0,
                            hasEmphasis: true,
                          },
                        ]
                      : [
                          {
                            displayName: translate(
                              'PriceBreakdown.priceBreakdown.vehiclePrice',
                            ),
                            testId: `${TEST_ID}-vehicle-price`,
                            displayNameSources: [
                              'sharedDict:PriceBreakdown.priceBreakdown.vehiclePrice',
                            ],
                            vatLabel: vehiclePrice.vatLabelAfterDiscount,
                            vatLabelSources:
                              vehiclePrice.vatLabelAfterDiscountSources,
                            price: vehiclePrice.afterDiscountAmount ?? 0,
                            priceText: vehiclePrice.afterDiscount ?? '',
                            priceTextSources: [
                              'data:vehiclePrice.afterDiscount',
                            ],
                            shouldShowVatLabel:
                              vehiclePrice.vatAmountAfterDiscount > 0,
                          },
                        ]),
                    ...(enableDepositLineItem
                      ? [
                          {
                            displayName: breakdownItemContentField
                              ? translate(breakdownItemContentField)
                              : '',
                            displayNameSources: [
                              `sharedDict:${breakdownItemContentField}`,
                            ],
                            testId: `${TEST_ID}-${getTestIdFromContentField(
                              dueTodayBreakdownItemContentField ?? '',
                            )}`,
                            vatLabel,
                            vatLabelSources: ['data:atCheckoutVatLabel'],
                            price: -refundableDepositPrice,
                            priceText: '-' + refundableDepositPriceText,
                            priceTextSources: [
                              'data:refundableDepositPriceText',
                            ],
                            shouldShowVatLabel: refundableDepositVatAmount > 0,
                          },
                        ]
                      : []),
                  ]
                : []),
              ...taxes,
            ]
          : [
              {
                displayName: enableTwoPartDeposit
                  ? translate('PriceBreakdown.priceSummary.variableDeposit')
                  : translate('PriceBreakdown.priceSummary.advancePayment'),
                displayNameSources: [
                  enableTwoPartDeposit
                    ? 'sharedDict:PriceBreakdown.priceSummary.variableDeposit'
                    : 'sharedDict:PriceBreakdown.priceSummary.advancePayment',
                ],
                testId: `${TEST_ID}-${
                  enableTwoPartDeposit ? 'variable-deposit' : 'advance-payment'
                }`,
                vatLabel,
                vatLabelSources,
                price: isLeaseOrLoan
                  ? downPaymentPriceForLeaseAndLoan
                  : downPaymentPrice,
                priceText: isLeaseOrLoan
                  ? downPaymentPriceTextForLeaseAndLoan
                  : downPaymentDisplay,
                priceTextSources: [
                  isLeaseOrLoan
                    ? 'data:downPaymentPriceTextForLeaseAndLoan'
                    : 'data:downPaymentDisplay',
                ],
                hideIndicativePrice: true,
                shouldShowVatLabel: downPaymentVATAmount > 0,
              },
              ...taxes,
            ]),
        ...(followsCustomerAccessories?.length && !isLeaseOrLoan
          ? followsCustomerAccessories.map((accessory) => ({
              displayName: accessory.displayName,
              displayNameSources: ['data:accessory.displayName'],
              vatLabel,
              vatLabelSources,
              price: accessory.price,
              priceText: accessory.priceText,
              priceTextSources: ['data:accessory.price'],
              shouldShowVatLabel: accessory.vatAmount > 0,
              testId: `${TEST_ID}-accessory-price`,
            }))
          : []),
        ...(followsCustomerPackages?.length && !isLeaseOrLoan
          ? followsCustomerPackages.map((pkg) => ({
              displayName: pkg.displayName,
              displayNameSources: ['data:pkg.displayName'],
              vatLabel,
              vatLabelSources,
              price: pkg.price,
              priceText: pkg.priceText,
              priceTextSources: ['data:pkg.price'],
              shouldShowVatLabel: pkg.vatAmount > 0,
              testId: `${TEST_ID}-package-price`,
            }))
          : []),
        ...(isCash && followsCarAccessories?.length && !isLeaseOrLoan
          ? followsCarAccessories.map((accessory) => ({
              displayName: accessory.displayName,
              displayNameSources: ['data:accessory.displayName'],
              vatLabel,
              price: accessory.price,
              priceText: accessory.priceText,
              priceTextSources: ['data:accessory.price'],
              shouldShowVatLabel: accessory.vatAmount > 0,
              testId: `${TEST_ID}-accessory-price`,
            }))
          : []),
        ...(isCash && followsCarPackages?.length && !isLeaseOrLoan
          ? followsCarPackages.map((pkg) => ({
              displayName: pkg.displayName,
              displayNameSources: ['data:pkg.displayName'],
              vatLabel,
              vatLabelSources,
              price: pkg.price,
              priceText: pkg.priceText,
              priceTextSources: ['data:pkg.price'],
              shouldShowVatLabel: pkg.vatAmount > 0,
              testId: `${TEST_ID}-package-price`,
            }))
          : []),
        // TODO: Should this be here?
        ...(services?.length && isCash
          ? services.map((pkg) => ({
              displayName: pkg.displayName,
              displayNameSources: ['data:pkg.displayName'],
              vatLabel,
              vatLabelSources,
              price: pkg.price,
              priceText: pkg.priceText,
              priceTextSources: ['data:pkg.price'],
              shouldShowVatLabel: pkg.vatAmount > 0,
              testId: `${TEST_ID}-service-price`,
            }))
          : []),
        ...(priceSummary?.appliedDiscounts?.map((discount) => {
          return {
            displayName: discount.name || '',
            displayNameSources: [`data:discount.name`],
            price: discount.discountAmount?.displayPrice?.amount || 0,
            priceText: '- ' + discount.discountAmount?.displayPrice?.display,
            priceTextSources: [
              'text:- ',
              'data:discount.discountAmount.displayPrice.display',
            ],
          };
        }) ?? []),
      ],
      totalBreakdownItem: {
        displayName: translate('PriceBreakdown.priceBreakdown.total'),
        displayNameSources: ['sharedDict:PriceBreakdown.priceBreakdown.total'],
        vatLabel,
        price: beforeDeliveryPrice,
        priceText: beforeDeliveryPriceText,
        priceTextSources: ['data:beforeDeliveryPriceText'],
        hasEmphasis: true,
        testId: `${TEST_ID}-total`,
      },
      totalAndVatSection: {
        items: [
          /** VAT title and amount */
          {
            shouldShowSection: shouldShowVAT,
            title: translate('PriceBreakdown.priceBreakdown.totalVat'),
            titleSources: ['sharedDict:PriceBreakdown.priceBreakdown.totalVat'],
            price: beforeDeliveryVATAmount,
            priceText: beforeDeliveryVAT,
            priceTextSources: ['data:beforeDeliveryVAT'],
            testId: `${TEST_ID}-total-vat`,
          },
          /** Total title and amount */
          {
            title: totalLabel,
            titleSources: totalLabelSources,
            price: beforeDeliveryTotalPriceWithVAT,
            priceText: beforeDeliveryTotalPriceTextWithVAT,
            priceTextSources: ['data:beforeDeliveryTotalPriceWithVAT'],
            testId: `${TEST_ID}-before-delivery-total-price-with-vat`,
          },
        ],
      },
    };
  }, [
    dueTodayBreakdownItemContentField,
    shouldShowSection,
    beforeDeliveryPrice,
    beforeDeliveryPriceText,
    isDirectSales,
    translate,
    vatLabel,
    vatLabelSources,
    enablePriceBreakdownFirstMonthlyPaymentLineItem,
    firstMonthPaymentPriceForLeaseAndLoan,
    firstMonthPaymentPriceTextForLeaseAndLoan,
    firstMonthPaymentPriceTextForLeaseAndLoanVAT,
    taxes,
    isDownPaymentChosen,
    isCash,
    isDiscountApplied,
    vehiclePrice.vatLabelBeforeDiscount,
    vehiclePrice.vatLabelBeforeDiscountSources,
    vehiclePrice.beforeDiscountAmount,
    vehiclePrice.beforeDiscount,
    vehiclePrice.vatAmountBeforeDiscount,
    vehiclePrice.discountAmountValue,
    vehiclePrice.discountAmount,
    vehiclePrice.vatAmountAfterDiscount,
    vehiclePrice.vatLabelAfterDiscount,
    vehiclePrice.vatLabelAfterDiscountSources,
    vehiclePrice.afterDiscountAmount,
    vehiclePrice.afterDiscount,
    discountRateDisplaySources,
    discountRateDisplay,
    enableDepositLineItem,
    refundableDepositPrice,
    refundableDepositPriceText,
    refundableDepositVatAmount,
    enableTwoPartDeposit,
    isLeaseOrLoan,
    downPaymentPriceForLeaseAndLoan,
    downPaymentPrice,
    downPaymentPriceTextForLeaseAndLoan,
    downPaymentDisplay,
    downPaymentVATAmount,
    followsCustomerAccessories,
    followsCustomerPackages,
    followsCarAccessories,
    followsCarPackages,
    services,
    shouldShowVAT,
    beforeDeliveryVATAmount,
    beforeDeliveryVAT,
    totalLabel,
    totalLabelSources,
    beforeDeliveryTotalPriceWithVAT,
    beforeDeliveryTotalPriceTextWithVAT,
    showPreOrderSummaryFlow,
    priceSummary?.appliedDiscounts,
  ]);
};
