import { FlagsProviderProps } from '@vcc-www/feature-flags';
import { PriceConfigurationInput } from '@vcc-www/federated-graph-types';
import {
  CustomerType,
  DeployEnv,
  SalesModels,
} from '@vcc-package/offers-utils';
import { OverlayProviderType } from '@vcc-www/part-exchange-tool/src/part-exchange.types';
import { PreviewState } from '@vcc-package/preview';
import type { DictionaryItemTypes } from '@vcc-www/shared-dictionaries/DictionaryItemTypes';
import { ERROR_CONTACT_TEAMS } from './offers.constants';
import {
  MarketSettings,
  SettingsFlagData,
} from './settings/offers-provider.settings.types';
import { MarketCTAs } from './types/cc-api/cc-api.gql-types';

export const OFFER_SECTIONS = {
  CUSTOMER_TYPE: 'customerType',
  SALES_MODEL: 'salesModel',
  CONTRACT_LENGTH: 'contractLength',
  MILEAGE: 'mileage',
  DOWN_PAYMENT: 'downPayment',
  PART_EXCHANGE: 'partExchange',
} as const;

export type OffersProviderMarketSettings = Pick<
  MarketSettings,
  | 'showDesignCode'
  | 'showFinanceDetailsDisclaimer'
  | 'showOfferSelection'
  | 'showPartExchange'
  | 'showSaveDesign'
  | 'showPreOrderSummaryFlow'
  | 'showSalesModelStageDisclaimer'
  | 'showGetQuoteCTAOrderSummaryPage'
  | 'supportedFinancialBreakdownItems'
>;

export type OfferSections =
  (typeof OFFER_SECTIONS)[keyof typeof OFFER_SECTIONS];

export type SelectedOfferProps = {
  customerType?: CustomerType;
  salesModel?: SalesModels;
  contractLength?: number | null;
  discountIds?: string[] | null;
  downPayment?: number | null;
  mileage?: number | null;
  token?: string | null;
  offerToken?: string | null;
  partExchange?: boolean;
  updatedOfferValue?: {
    key: OfferSections;
    value: string | number | null | undefined | boolean;
  };
};

type FuncInteractionType = (d: SelectedOfferProps) => void;

export const SHARED_PROVIDER_VARIANTS = {
  SETTINGS: 'settings',
  FLEET_SESSION: 'fleet session',
  // SHARED_CONTENT: 'shared content',
  OFFER_LISTINGS: 'offer listings',
  RETAIL_ASSISTED_SALES: 'retail assisted sales',
  MODELS: 'models',
  PART_EXCHANGE: 'part exchange',
  ALL: 'all',
} as const;

type SharedProviderVariants =
  (typeof SHARED_PROVIDER_VARIANTS)[keyof typeof SHARED_PROVIDER_VARIANTS];
export type SharedProvidersType = {
  variant: SharedProviderVariants;
  Component: any;
};

export type IncludeProvidersType = Array<SharedProviderVariants>;

export type PriceConfigInputType = PriceConfigurationInput;

export type OffersProviderAggregateType = OffersProviderBaseType &
  OffersProviderEnvType &
  OffersProviderOfferType &
  OffersProviderDotcomType &
  OffersProviderInitialType &
  OffersProviderClientTypeType;

export type CurrentOfferType = {
  currentOffer?: SelectedOfferProps;
};

export type OffersProviderType = OffersProviderBaseType & {
  env?: OffersProviderEnvType;
  marketSettings?: OffersProviderMarketSettings;
  offer?: OffersProviderOfferType;
  prices?: OffersProviderPricesType;
  dotcom?: OffersProviderDotcomType;
  initial?: OffersProviderInitialType;
  clientType?: OffersProviderClientTypeType;
  partExchangeTranslations?: Pick<
    OverlayProviderType,
    'content' | 'dictionaries'
  >;
  allowLoadingFeatureFlags?: boolean;
  useGA4CookieAsLDUserKey?: boolean;
  discountIds?: string[] | null;
  postcode?: string | null;
  retailerId?: string | null;
} & CurrentOfferType;

export type OffersProviderBaseType = {
  clientName: string;
  market: string | undefined;
  noApolloClient?: boolean;
  children?: React.ReactNode;
  includeProviders?: IncludeProvidersType;
  isDirectSales?: boolean;
  isStockPage?: boolean;
  // TODO: NOT SURE IF WE NEED THESE BELOW - REMOVE?
  retailAssistedSalesData?: any;
  previewState?: PreviewState;
  onError?: (error: OffersProviderOnErrorType) => void;
  vccSession?: string | undefined;
};

export type OffersProviderOnErrorType = {
  car?: OfferProviderError[] | null;
  offer?: OfferProviderError[] | null;
};

export type OffersProviderEnvType = {
  qawwwHeader?: string | null;
  testwwwHeader?: string | null;
  deployEnv?: DeployEnv;
  cceSource?: string | null;
  overrideSources?: PreviewState['overrideSources'];
};

export type OffersProviderOfferType = {
  onChangeDrawer?: FuncInteractionType;
  onOpenDrawer?: FuncInteractionType;
  onCloseDrawer?: FuncInteractionType;
  withoutSelector?: boolean;
  contractLength?: number | null;
  customerType?: string;
  downPayment?: number | null;
  mileage?: number | null;
  offerToken?: string | null;
  salesModel?: string;
  salesModelSelections?: string[];
  discountIds?: string[] | null;
  postcode?: string | null;
  retailerId?: string | null;
  token?: string | null;
  onFleetTokenUpdate?: ({
    token,
    offerToken,
  }: {
    token: string;
    offerToken?: string;
  }) => void;
};

export type OffersProviderPricesType = {
  purchasePriceSalesModel?: SalesModels | undefined;
  monthlyPriceSalesModel?: SalesModels | undefined;
};

export type OffersProviderDotcomType = {
  model?: string;
  modelYear?: string;
  pno12?: string;
  dealerId?: string | null;
  partExchange?: boolean;
  partnerCode?: string | null;
  postcode?: string | null;
  taxTerritory?: string | null;
  retailAssistedSales?:
    | OffersProviderDotcomRetailAssistedSalesType
    | undefined
    | null;
  preOrderId?: string;
};

export type OffersProviderDotcomRetailAssistedSalesType = {
  dealer_associate?: string;
  sales_channel?: string;
  selling_dealer?: string;
  opportunityId?: string;
};

export type OffersProviderInitialType = {
  content?: InitialContentType;
  prices?: InitialPricesType;
  flagsProviderProps?: FlagsProviderProps | undefined;
  overrideFlags?: Partial<SettingsFlagData>;
};

export type OffersProviderClientTypeType = {
  isDms?: boolean;
  isKompass?: boolean;
  isInStore?: boolean;
  isPrivateStockCar?: boolean;
  isStockCar?: boolean;
  isFleetSession?: boolean;
  isDirectSales?: boolean;
};

export type InitialContentType = {
  sharedTranslations: DictionaryItemTypes;
  marketCtas: MarketCTAs;
  translations: { [key: string]: string };
};

type InitialPricesType = any;

export type GetOfferSelectorAPIEndpointHeadersProps = OffersProviderEnvType & {
  clientName: string;
};

type ErrorContactTeamsType =
  (typeof ERROR_CONTACT_TEAMS)[keyof typeof ERROR_CONTACT_TEAMS];

export type ErrorContactTeamsKeys = keyof typeof ERROR_CONTACT_TEAMS;

export type OfferProviderError = {
  body: Record<string, any>;
  clientId: string;
  code: string;
  contact: ErrorContactTeamsType;
  message: string;
  pageUrl?: string;
  paths?: Array<string | number>;
  queryName: string;
  statusCode: number;
  url: string;
  variables?: Record<string, any>;
};
