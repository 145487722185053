export const isPreOrderModelOrderSummaryFlow = (
  model: string,
  preOrderId: string | undefined,
) => {
  return (
    (model === 'ex90-electric' || model === 'ex30-electric') && !preOrderId
  );
};

export const isPreOrderFlowShutDown = (model: string) => {
  return model === 'ex90-electric' || model === 'ex30-electric';
};
