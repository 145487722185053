import { useMemo } from 'react';
import { useContent } from '../content/offers-provider.content.provider';
import {
  EntireOfferFinanceReturnType,
  PRICE_BREAKDOWN_SECTIONS,
  SALES_MODELS,
} from '../entire-offer/entire-offer.types';
import { CUSTOMER_TYPE } from '../offers.constants';
import { mapSectionTypeToEventLabel } from '../entire-offer/entire-offer.utils';
import { useSettings } from '../settings/offers-provider.settings.provider';
import { capitalizeString } from '../utils';
import { useAfterCheckout } from './price-breakdown.after-checkout.hook';
import { useAtCheckout } from './price-breakdown.at-checkout.hook';
import { useDiscounts } from './price-breakdown.discounts.hook';
import { useFinanceDetails } from './price-breakdown.finance-details.hook';
import { useRecurring } from './price-breakdown.recurring.hook';
import { useTaxes } from './price-breakdown.taxes.hook';
import {
  UsePriceBreakdownAdapterProps,
  VehiclePriceType,
} from './price-breakdown.types';
import { usePriceLabels } from '../hooks/usePriceLabels';

export const usePriceBreakdownAdapter = ({
  additionals,
  contractLength,
  customerType,
  downPayment,
  financingPriceSummary,
  isDirectSales,
  mileage,
  priceSummary,
  salesModel,
}: UsePriceBreakdownAdapterProps): EntireOfferFinanceReturnType['priceBreakdown'] => {
  const { translate } = useContent();
  const {
    enablePriceBreakdownLineItems,
    enablePriceBreakdownTotalAndVatLineItem,
    enableEditConfiguration,
  } = useSettings();

  /** Pricing models*/
  const isCash = salesModel === SALES_MODELS.CASH;
  const isLease = salesModel === SALES_MODELS.LEASE;
  const isLoan = salesModel === SALES_MODELS.LOAN;
  const isLeaseOrLoan = isLease || isLoan; // PCP/VL

  /** VAT display logic */
  const isB2B = customerType?.id === CUSTOMER_TYPE.B2B;
  const isPriceExcludingVAT = isB2B;

  const { incentiveTaxes, ownershipTaxesFormatted } = useTaxes({
    isDirectSales,
    isPriceExcludingVAT,
    priceSummary,
    testId: 'tax-and-bonus',
  });

  /** Total Price & VAT */
  const totalPrice = priceSummary?.price?.displayPrice;

  /** Check if there is a valid price amount available for this offer */
  const hasPrice =
    !!priceSummary?.carPriceSummary?.totalPrice?.amount ||
    !!priceSummary?.price?.displayPrice?.amount;

  /** Labels */
  const { getVatLabel, getVatLabelSources } = usePriceLabels();

  const {
    discountAmount,
    discountAmountDisplayPrice,
    discountRateDisplay,
    discountRateDisplaySources,
    discountVatAmount,
    isDiscountAppliedForPriceBreakdown,
    isDiscountAppliedForFinanceDetails,
    priceBeforeDiscount,
    priceAmountBeforeDiscount,
    standardRateDisplay,
    aprDiscountRateDisplay,
    aprDiscountMinimumDownPaymentAmount,
    aprDiscountMinimumDownPaymentDisplay,
    shouldShowAprDiscountOffer,
    isDiscountAppliedApr,
  } = useDiscounts({ financingPriceSummary, priceSummary, downPayment });

  const vehiclePriceAfterDiscount =
    priceSummary?.carPrice?.price?.displayPrice?.display ?? '';
  const vehiclePriceAfterDiscountAmount =
    priceSummary?.carPrice?.price?.displayPrice?.amount ?? 0;

  const vatAmountAfterDiscount =
    priceSummary?.carPrice?.price?.vatAmount?.amount ?? 0;

  // Car price before discount (accessories not included)
  const vehiclePriceBeforeDiscount =
    priceSummary?.carPrice?.priceBeforeDiscount?.displayPrice?.display ?? '';
  const vehiclePriceAmountBeforeDiscount =
    priceSummary?.carPrice?.priceBeforeDiscount?.displayPrice?.amount ?? 0;

  const vatAmountBeforeDiscount =
    priceSummary?.carPrice?.priceBeforeDiscount?.vatAmount?.amount ?? 0;

  const vehiclePrice: VehiclePriceType = {
    beforeDiscount: isCash ? vehiclePriceBeforeDiscount : priceBeforeDiscount, // For cash we only want to show the vehicle price before discount
    beforeDiscountAmount: isCash
      ? vehiclePriceAmountBeforeDiscount
      : priceAmountBeforeDiscount, // For cash we only want to show the vehicle price before discount
    afterDiscount: vehiclePriceAfterDiscount,
    afterDiscountAmount: vehiclePriceAfterDiscountAmount,
    discountAmount: discountAmountDisplayPrice,
    discountAmountValue: discountAmount,
    discountVatAmount: discountVatAmount ?? 0,
    vatAmountBeforeDiscount: vatAmountBeforeDiscount ?? 0,
    vatAmountAfterDiscount: vatAmountAfterDiscount ?? 0,
    vatLabelBeforeDiscount: getVatLabel(
      !!priceSummary?.carPrice?.priceBeforeDiscount?.displayPrice?.includingVat,
    ),
    vatLabelBeforeDiscountSources: getVatLabelSources(
      !!priceSummary?.carPrice?.priceBeforeDiscount?.displayPrice?.includingVat,
    ),
    vatLabelAfterDiscount: getVatLabel(
      !!priceSummary?.carPrice?.price?.displayPrice?.includingVat,
    ),
    vatLabelAfterDiscountSources: getVatLabelSources(
      !!priceSummary?.carPrice?.price?.displayPrice?.includingVat,
    ),
  };

  const atCheckoutSection = useAtCheckout({
    isLeaseOrLoan,
    isCash,
    financingPriceSummary,
    priceSummary,
  });

  const afterCheckoutSection = useAfterCheckout({
    additionals,
    discountAmountDisplayPrice,
    discountRateDisplay,
    discountRateDisplaySources,
    downPayment,
    financingPriceSummary,
    hasPrice,
    isCash,
    isDiscountApplied: isDiscountAppliedForPriceBreakdown,
    isLeaseOrLoan,
    isDirectSales,
    priceSummary,
    salesModel,
    vehiclePrice,
  });

  const recurringSection = useRecurring({
    discountRateDisplay,
    discountRateDisplaySources,
    financingPriceSummary,
    isDiscountApplied: isDiscountAppliedForPriceBreakdown,
    isLeaseOrLoan,
    priceSummary,
    vehiclePrice,
  });

  /** Financing price details */
  const financeDetails = useFinanceDetails({
    contractLength,
    discountAmount,
    discountAmountDisplayPrice,
    discountRateDisplay,
    financingPriceSummary,
    isDiscountAppliedForFinanceDetails,
    isDiscountAppliedApr,
    mileage,
    priceBeforeDiscount,
    priceAmountBeforeDiscount,
  });

  /** Breakdown Sections */
  const breakdownSections = useMemo(
    () => [atCheckoutSection, afterCheckoutSection, recurringSection],
    [afterCheckoutSection, recurringSection, atCheckoutSection],
  );

  /** Breakdown Sections With Tracking added */
  const trackedBreakdownSections = useMemo(
    () =>
      breakdownSections.map((breakdownSection) => {
        const sectionType = mapSectionTypeToEventLabel(
          breakdownSection?.sectionType,
        );
        const name = `Price Breakdown - Accordion - ${capitalizeString(
          sectionType,
        )}`;

        return {
          ...breakdownSection,
          name,
          hideIndicativePrice:
            !enableEditConfiguration &&
            breakdownSection?.sectionType ===
              PRICE_BREAKDOWN_SECTIONS.BEFORE_DELIVERY,
          shouldHideLink:
            breakdownSection?.sectionType ===
              PRICE_BREAKDOWN_SECTIONS.BEFORE_DELIVERY &&
            !enablePriceBreakdownLineItems,
          shouldShowTotalAndVatSection: enablePriceBreakdownTotalAndVatLineItem,
        };
      }),
    [
      breakdownSections,
      enableEditConfiguration,
      enablePriceBreakdownLineItems,
      enablePriceBreakdownTotalAndVatLineItem,
    ],
  );

  return useMemo(
    () => ({
      totalPrice: {
        displayName: totalPrice?.display ?? '',
        amount: totalPrice?.amount,
      },
      breakdownSections: trackedBreakdownSections,
      financeDetails,
      taxAndBonus: {
        title:
          translate('PriceBreakdown.priceBreakdown.taxesSectionTitle') ?? '',
        description: translate(
          'PriceBreakdown.priceBreakdown.taxesSectionDescription',
        ),
        items: [...ownershipTaxesFormatted, ...incentiveTaxes],
      },
      checksum: financingPriceSummary?.realTimeBreakdown?.referenceId,
      isDiscountApplied:
        isDiscountAppliedForPriceBreakdown ||
        isDiscountAppliedForFinanceDetails ||
        isDiscountAppliedApr,
      aprData: {
        standardRateDisplay,
        aprDiscountRateDisplay,
        aprDiscountMinimumDownPaymentAmount,
        aprDiscountMinimumDownPaymentDisplay,
        shouldShowAprDiscountOffer,
        isDiscountAppliedApr,
      },
    }),
    [
      totalPrice?.display,
      totalPrice?.amount,
      trackedBreakdownSections,
      financeDetails,
      translate,
      ownershipTaxesFormatted,
      incentiveTaxes,
      financingPriceSummary?.realTimeBreakdown?.referenceId,
      isDiscountAppliedForPriceBreakdown,
      isDiscountAppliedForFinanceDetails,
      isDiscountAppliedApr,
      standardRateDisplay,
      aprDiscountRateDisplay,
      aprDiscountMinimumDownPaymentAmount,
      aprDiscountMinimumDownPaymentDisplay,
      shouldShowAprDiscountOffer,
    ],
  );
};
