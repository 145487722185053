import { useSettings } from '../settings/offers-provider.settings.provider';
import {
  PRICE_BREAKDOWN_SECTIONS,
  PriceBreakdownSectionProps,
} from '../entire-offer/entire-offer.types';
import { useContent } from '../content/offers-provider.content.provider';
import { useMemo } from 'react';
import { usePriceLabels } from '../hooks/usePriceLabels';
import { AtCheckoutInputProps } from './price-breakdown.types';
import {
  BREAKDOWN_ITEM_CONTENT_FIELD_BY_KEY,
  PRICE_SUMMARY_TEST_IDS,
} from './price-breakdown.constants';
import { getTestIdFromContentField } from '../utils';

const TEST_ID = PRICE_SUMMARY_TEST_IDS.DUE_TODAY;

export const useAtCheckout = ({
  financingPriceSummary,
  isLeaseOrLoan,
  priceSummary,
}: AtCheckoutInputProps): PriceBreakdownSectionProps => {
  const { translate } = useContent();
  const {
    supportsVAT,
    dueTodayBreakdownItemContentField,
    enableDueTodayVatLabel,
  } = useSettings();
  const { getVatLabel, totalLabel, totalLabelSources } = usePriceLabels();
  /** Due Today Section Price & VAT */

  // At Checkout Price Text for Lease and Loan (PCP/VL)
  const atCheckoutPriceTextForLeaseAndLoan =
    financingPriceSummary?.breakdown?.prePayment?.amount?.displayPrice
      ?.display ?? '';

  const atCheckoutPriceForLeaseAndLoan =
    financingPriceSummary?.breakdown?.prePayment?.amount?.displayPrice
      ?.amount ?? 0;

  // At Checkout Price Text for all sales models except Lease and Loan (PCP/VL)
  const atCheckoutPriceText =
    priceSummary?.aggregations?.total?.paymentPlan?.atCheckout?.displayPrice
      ?.display ?? '';

  const atCheckoutPrice =
    priceSummary?.aggregations?.total?.paymentPlan?.atCheckout?.displayPrice
      ?.amount ?? 0;

  const isPriceIncludingVAT = isLeaseOrLoan
    ? financingPriceSummary?.breakdown?.prePayment?.amount?.displayPrice
        ?.includingVat
    : priceSummary?.aggregations?.total?.paymentPlan?.atCheckout?.displayPrice
        ?.includingVat;

  const dueTodayPrice = isLeaseOrLoan
    ? atCheckoutPriceForLeaseAndLoan
    : atCheckoutPrice;

  const dueTodayPriceText = isLeaseOrLoan
    ? atCheckoutPriceTextForLeaseAndLoan
    : atCheckoutPriceText;

  const shouldShowSection = isLeaseOrLoan
    ? !!financingPriceSummary?.breakdown?.prePayment?.amount?.displayPrice
        ?.amount
    : !!priceSummary?.aggregations?.total?.paymentPlan?.atCheckout?.displayPrice
        ?.amount;

  //At Checkout VAT Price Text for Lease and Loan (PCP/VL)
  const atCheckoutVatTextForLeaseAndLoan =
    financingPriceSummary?.breakdown?.prePayment?.amount?.vatAmount?.display ??
    '';

  //At Checkout VAT Price Amount for Lease and Loan (PCP/VL)
  const atCheckoutVatAmountForLeaseAndLoan =
    financingPriceSummary?.breakdown?.prePayment?.amount?.vatAmount?.amount ??
    0;

  //At Checkout VAT Price Text for all sales models except Lease and Loan (PCP/VL)
  const atCheckoutVatText =
    priceSummary?.aggregations?.total?.paymentPlan?.atCheckout?.vatAmount
      ?.display ?? '';

  //At Checkout VAT Price Amount for all sales models except Lease and Loan (PCP/VL)
  const atCheckoutVatAmount =
    priceSummary?.aggregations?.total?.paymentPlan?.atCheckout?.vatAmount
      ?.amount ?? 0;

  const dueTodayVAT = isLeaseOrLoan
    ? atCheckoutVatTextForLeaseAndLoan
    : atCheckoutVatText;

  const dueTodayVatAmount = isLeaseOrLoan
    ? atCheckoutVatAmountForLeaseAndLoan
    : atCheckoutVatAmount;

  const shouldShowVAT = supportsVAT && dueTodayVatAmount > 0;

  //At Checkout Total Price With VAT Text for Lease and Loan (PCP/VL)
  const atCheckoutTotalPriceWithVatForLeaseAndLoan =
    financingPriceSummary?.breakdown?.prePayment?.amount?.priceInclTax
      ?.amount ?? 0;
  const atCheckoutTotalPriceTextWithVatForLeaseAndLoan =
    financingPriceSummary?.breakdown?.prePayment?.amount?.priceInclTax
      ?.display ?? '';

  //At Checkout Total Price With VAT Text for all sales models except Lease and Loan (PCP/VL)
  const atCheckoutTotalPriceTextWithVat =
    priceSummary?.aggregations?.total?.paymentPlan?.atCheckout?.priceInclTax
      ?.display ?? '';
  const atCheckoutTotalPriceWithVat =
    priceSummary?.aggregations?.total?.paymentPlan?.atCheckout?.priceInclTax
      ?.amount ?? 0;

  const dueTodayTotalPriceWithVAT = isLeaseOrLoan
    ? atCheckoutTotalPriceWithVatForLeaseAndLoan
    : atCheckoutTotalPriceWithVat;
  const dueTodayTotalPriceTextWithVAT = isLeaseOrLoan
    ? atCheckoutTotalPriceTextWithVatForLeaseAndLoan
    : atCheckoutTotalPriceTextWithVat;

  const vatLabel = getVatLabel(!!isPriceIncludingVAT);

  return useMemo(() => {
    const breakdownItemContentField =
      BREAKDOWN_ITEM_CONTENT_FIELD_BY_KEY[
        dueTodayBreakdownItemContentField ?? ''
      ];
    return {
      sectionType: PRICE_BREAKDOWN_SECTIONS.TODAY,
      shouldShowSection,
      price: dueTodayPrice,
      priceText: dueTodayPriceText,
      priceTextSources: ['data:dueTodayPriceText'],
      displayName: translate('PriceBreakdown.priceSummary.dueToday'),
      displayNameSources: ['sharedDict:PriceBreakdown.priceSummary.dueToday'],
      vatLabel: enableDueTodayVatLabel ? vatLabel : '',
      testId: TEST_ID,
      vatLabelSources: ['data:atCheckoutVatLabel'],
      priceBreakdownItems: [
        {
          displayName: breakdownItemContentField
            ? translate(breakdownItemContentField)
            : '',
          displayNameSources: [`sharedDict:${breakdownItemContentField}`],
          testId: `${TEST_ID}-${getTestIdFromContentField(
            dueTodayBreakdownItemContentField ?? '',
          )}`,
          vatLabel,
          vatLabelSources: ['data:atCheckoutVatLabel'],
          price: dueTodayPrice,
          priceText: dueTodayPriceText,
          priceTextSources: ['data:dueTodayPriceText'],
          shouldShowVatLabel: dueTodayVatAmount > 0,
        },
      ],
      totalBreakdownItem: {
        displayName: translate('PriceBreakdown.priceBreakdown.total'),
        displayNameSources: ['sharedDict:PriceBreakdown.priceBreakdown.total'],
        testId: `${TEST_ID}-total`,
        vatLabel: enableDueTodayVatLabel ? vatLabel : '',
        vatLabelSources: ['data:atCheckoutVatLabel'],
        price: dueTodayPrice,
        priceText: dueTodayPriceText,
        priceTextSources: ['data:dueTodayPriceText'],
        hasEmphasis: true,
      },
      totalAndVatSection: {
        testId: TEST_ID,
        items: [
          /** VAT title and amount */
          {
            shouldShowSection: shouldShowVAT,
            title: translate('PriceBreakdown.priceBreakdown.totalVat'),
            titleSources: ['sharedDict:PriceBreakdown.priceBreakdown.totalVat'],
            price: dueTodayVatAmount,
            priceText: dueTodayVAT,
            priceTextSources: ['data:dueTodayVAT'],
            testId: `${TEST_ID}-total-vat`,
          },
          /** Total title and amount */
          {
            title: totalLabel,
            titleSources: totalLabelSources,
            price: dueTodayTotalPriceWithVAT,
            priceText: dueTodayTotalPriceTextWithVAT,
            priceTextSources: ['data:dueTodayTotalPriceWithVAT'],
            testId: `${TEST_ID}-due-today-total-price-with-vat`,
          },
        ],
      },
    };
  }, [
    dueTodayBreakdownItemContentField,
    shouldShowSection,
    dueTodayPrice,
    dueTodayPriceText,
    translate,
    enableDueTodayVatLabel,
    vatLabel,
    dueTodayVatAmount,
    shouldShowVAT,
    dueTodayVAT,
    totalLabel,
    totalLabelSources,
    dueTodayTotalPriceWithVAT,
    dueTodayTotalPriceTextWithVAT,
  ]);
};
