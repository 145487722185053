import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { BASIC_OFFER_QUERY } from '../queries/fed-graph/basic-offer.query';
import {
  BasicOfferQuery,
  BasicOfferQueryVariables,
} from '../queries/fed-graph/basic-offer.query.gql-types';
import { OfferProviderType } from '../offer/offer.provider.types';
import { BUSINESS_MODELS } from '@vcc-package/offers-utils';

export const useOffersProviderBasicOffer = (props: OfferProviderType): any => {
  // Look up some very simple data for the offer (customerType and salesModel)
  const [data, setData] = useState<BasicOfferQuery>();
  const { isStockCar, offer, clientName } = props;
  const token = isStockCar ? offer?.offerToken : offer?.token;
  useQuery<BasicOfferQuery, BasicOfferQueryVariables>(BASIC_OFFER_QUERY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: {
      token: token ?? '',
    },
    context: {
      headers: {
        'x-client-name': clientName,
      },
    },
    skip: !!data || !token,
    onCompleted: (data) => setData(data),
  });

  return {
    ...props,
    ...(token ? data?.offerByToken?.offerKey : {}),
    isDirectSales:
      data?.offerByToken?.businessModel?.id === BUSINESS_MODELS.DIRECT,
    salesModelSelections: data?.offerByToken?.configuration?.selections
      .filter((selection) => selection.type === 'SalesModel')
      .map((selection) => selection.id),
  };
};
