import { useQuery } from '@apollo/client';
import { CUSTOMER_TYPE, getLocaleForFedGraph } from '@vcc-package/offers-utils';
import { useMemo } from 'react';
import { OFFER_LISTING_QUERY } from '../../queries/fed-graph/entire-offer-listing.query';
import {
  OfferListingQuery,
  OfferListingQueryVariables,
} from '../../queries/fed-graph/entire-offer-listing.query.gql-types';
import { useSettings } from '../../settings/offers-provider.settings.provider';
import { UseEntireListingOfferProps } from '../entire-offer.types';
import { getFinanceOutput } from '../entire-offer.utils';
import { usePriceLabels } from '../../hooks/usePriceLabels';
import { OfferSelectionDataFragment } from '../../queries/fed-graph/entire-offer.fragments.query.gql-types';
import { SHARED_PROVIDER_VARIANTS } from '../../offers.types';

export const useEntireListingOffer = ({
  clientName,
  contractLength: contractLengthNr,
  customerType,
  downPayment,
  locale,
  mileage: mileageNr,
  regionCode,
  salesModel,
  token,
  includeProviders,
}: UseEntireListingOfferProps) => {
  const labels = usePriceLabels();

  const { salesModelSortOrder, supportsVAT, permittedSalesModels } =
    useSettings();

  const graphQueryLocale = getLocaleForFedGraph(locale) ?? '';

  const {
    data: listingData,
    previousData: previousListingData,
    loading: isListingLoading,
  } = useQuery<OfferListingQuery, OfferListingQueryVariables>(
    OFFER_LISTING_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      variables: {
        locale: graphQueryLocale,
        market: regionCode,
      },
      // TODO: isFleetLoading causes the initial loading state to be false then turned into true. We need to come up with a solution for when to get fleet data.
      skip:
        !!token ||
        !graphQueryLocale ||
        !regionCode ||
        !includeProviders?.includes(SHARED_PROVIDER_VARIANTS.OFFER_LISTINGS),
      context: { headers: { 'x-client-name': clientName } },
    },
  );

  const offerListingData = listingData || previousListingData;

  const { configuration, carList } =
    offerListingData?.offersByMarket?.offers?.find(
      (o) =>
        o?.salesModel.id?.toLowerCase() === salesModel?.toLowerCase() &&
        o?.configuration?.offerKey?.customerType?.toLowerCase() ===
          customerType?.toLowerCase(),
    ) ?? {};

  const { offerKey, selections } = configuration ?? {};

  const contractLengthId = useDataFromSelection({
    selections: configuration?.selections,
    type: 'ContractLength',
    numericValue: contractLengthNr,
    fallback: offerKey?.contractLength,
  });

  const mileageId = useDataFromSelection({
    selections: configuration?.selections,
    type: 'Mileage',
    numericValue: mileageNr,
    fallback: offerKey?.mileage,
  });

  const finance = useMemo(
    () =>
      getFinanceOutput({
        availableDownPayments:
          carList?.first?.priceSummary?.availableDownpayments ?? [],
        selections,
        contractLengthId,
        mileageId,
        salesModel: offerKey?.salesModel ?? salesModel,
        customerType:
          customerType?.toUpperCase() ??
          offerKey?.customerType ??
          CUSTOMER_TYPE.b2c,
        salesModelSortOrder: salesModelSortOrder?.map(String),
        downPayment,
        token: undefined,
        labels,
        supportsVAT,
        permittedSalesModels: permittedSalesModels?.map(String),
      }),
    [
      carList?.first?.priceSummary?.availableDownpayments,
      selections,
      contractLengthId,
      mileageId,
      offerKey?.salesModel,
      offerKey?.customerType,
      salesModel,
      customerType,
      salesModelSortOrder,
      downPayment,
      labels,
      supportsVAT,
      permittedSalesModels,
    ],
  );

  return {
    isLoading: !offerListingData,
    isUpdating: isListingLoading,
    finance,
  };
};

const useDataFromSelection = ({
  selections = [],
  type,
  numericValue,
  fallback,
}: {
  selections: OfferSelectionDataFragment[] | undefined;
  type: string;
  numericValue: number | null | undefined;
  fallback: string | null | undefined;
}) =>
  useMemo(
    () =>
      selections
        ?.filter((s) => s?.type === type)
        ?.find(({ content }) =>
          content?.__typename === 'NumericOfferProperty'
            ? content?.value === numericValue
            : {},
        )?.identifier ?? fallback,
    [fallback, numericValue, selections, type],
  );
