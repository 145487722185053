// Finland

import { BREAKDOWN_ITEMS } from '../price-breakdown.constants';
import { MarketSettings } from './market-settings.types';

const {
  FINAL_PAYMENT,
  CONTRACT_LENGTH,
  DEPOSIT,
  PURCHASE_PRICE,
  PAYABLE_AMOUNT,
  PAF,
  AMOUNT_OF_CREDIT,
  APR,
  INTEREST_AMOUNT,
  FINANCED_AMOUNT,
  CREDIT_COST_AMOUNT,
  PROCESSING_FEE,
  ESTABLISHMENT_FEE,
} = BREAKDOWN_ITEMS;

export const supportedFinancialBreakdownItems: MarketSettings['supportedFinancialBreakdownItems'] =
  [
    FINAL_PAYMENT,
    CONTRACT_LENGTH,
    DEPOSIT,
    PURCHASE_PRICE,
    PAYABLE_AMOUNT,
    PAF,
    AMOUNT_OF_CREDIT,
    APR,
    INTEREST_AMOUNT,
    FINANCED_AMOUNT,
    CREDIT_COST_AMOUNT,
    PROCESSING_FEE,
    ESTABLISHMENT_FEE,
  ];
