'use client';
import React, { useEffect, useRef } from 'react';
import NextLink from 'next/link';
import { Track } from '@volvo-cars/tracking';
import { useVolvoCarsUrl, urlFromString } from '@vcc-www/volvo-cars-url';
import type { LinkProps, LinksProps } from './Links.view';
import { cssJoin } from '@volvo-cars/css/utils';
import styles from '../SubNavigation.module.css';

export const Link: React.FC<LinkProps & Pick<LinksProps, 'theme'>> = ({
  text,
  href,
  eventLabel,
  accessibilityLabel,
  sources,
  theme,
}) => {
  const [isInitialRender, setIsInitialRender] = React.useState(true);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const { contentPath: currentUrlPath } = useVolvoCarsUrl();

  const { contentPath: linkPath } = urlFromString(href);
  const isCurrent = currentUrlPath === linkPath;

  useEffect(() => {
    setIsInitialRender(false);
  }, []);

  useEffect(() => {
    if (isCurrent && linkRef.current && !isInitialRender) {
      linkRef.current.scrollIntoView({
        block: 'end',
        inline: 'center',
      });
    }
  }, [isCurrent, isInitialRender]);

  return (
    <Track eventLabel={eventLabel}>
      <NextLink
        href={href}
        className={cssJoin(
          'py-4 px-8 rounded whitespace-nowrap snap-center',
          styles.link,
          theme === 'light' && styles.lightLink,
        )}
        aria-label={accessibilityLabel}
        aria-current={isCurrent ? 'page' : false}
        ref={linkRef}
        data-sources={
          sources
            ? [sources?.text, sources?.href, sources?.accessibilityLabel]
            : undefined
        }
        scroll={false}
      >
        {text}
      </NextLink>
    </Track>
  );
};
