'use client';
import React, { createContext, useContext, useEffect } from 'react';
import { getEnv } from '@vcc-package/offers-utils';
import { useIsContentLoading } from '../content/offers-provider.content.provider';
import { useEntireOffer } from '../entire-offer/entire-offer.hook';
import { useIsFleetSessionLoading } from '../fleet-session/offers-provider.fleet-session.provider';
import { useSettingsUtils } from '../settings/offers-provider.settings.provider';
import { OffersContextType } from './offer.provider.types';
import { useBasicOffer } from '../basic-offer/offers-provider.basic-offer.provider';

export const OffersContext: React.Context<OffersContextType> =
  createContext<OffersContextType>({
    clientName: '',
    country: '',
    locale: '',
    regionCode: '',
    market: '',
    isStockCar: false,
    previewState: {
      overrideFlags: {},
      overrideSources: {},
      showGrid: false,
      showSources: false,
    },
  });

OffersContext.displayName = 'OffersContext';

export const OfferProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const basicOffer = useBasicOffer();
  const {
    clientName,
    clientType,
    country,
    dotcom,
    env,
    isDirectSales,
    isStockCar,
    locale,
    market,
    regionCode,
  } = basicOffer ?? {};
  const { deployEnv = 'prod', ...restOfPropsEnvs } = env ?? {};

  const { domain, isQAHost } = getEnv({
    deployEnv,
    clientName,
  });
  const entireOffer = useEntireOffer(basicOffer);
  const { car, isLoading, isUpdating, error, finance, updatedError } =
    entireOffer ?? {};

  const isContentLoading = useIsContentLoading();
  const isFleetLoading = useIsFleetSessionLoading();

  const offerValue: OffersContextType = {
    ...restOfPropsEnvs,
    ...clientType,
    ...dotcom,
    ...basicOffer,
    clientName: clientName ?? '',
    country: country ?? '',
    customerType:
      finance?.selectedOffer?.customerType?.value?.toLowerCase() ??
      basicOffer?.offer?.customerType?.toLowerCase() ??
      undefined,
    domainName: domain,
    entireOffer,
    error,
    locale: locale ?? '',
    market,
    model: car?.configuration?.modelSlug,
    isLoading: isContentLoading || isLoading || isFleetLoading,
    isDirectSales,
    isStockCar: isStockCar ?? false,
    isQAHost,
    isUpdating,
    regionCode: regionCode ?? '',
    salesModel:
      basicOffer?.offer?.salesModel?.toLowerCase() ??
      finance?.selectedOffer?.salesModel?.value?.toLowerCase() ??
      basicOffer?.salesModel?.toLowerCase() ??
      undefined,
    salesModelSelections: basicOffer?.offer?.salesModelSelections ?? [],
    updatedError,
  };

  // update the feature flag attributes on change
  const { customerType, salesModel } = offerValue;
  const { updateAttributes } = useSettingsUtils();
  useEffect(() => {
    if (customerType && salesModel) {
      updateAttributes({ customerType, salesModel });
    }
    if (car?.configuration?.modelSlug) {
      updateAttributes({ modelSlug: car?.configuration?.modelSlug });
    }
    if (car?.configuration?.modelYear) {
      updateAttributes({ modelYear: car?.configuration?.modelYear });
    }
  }, [
    customerType,
    salesModel,
    car?.configuration?.modelSlug,
    car?.configuration?.modelYear,
    updateAttributes,
  ]);

  return (
    <OffersContext.Provider value={offerValue}>
      {children}
    </OffersContext.Provider>
  );
};

export const useOffers = ({ skipCheck }: { skipCheck?: boolean } = {}) => {
  const offersContext = useContext<OffersContextType>(OffersContext);
  if (!skipCheck && !offersContext.clientName)
    throw new Error('useOffers must be inside a Provider with a value');
  return { ...offersContext };
};

export const useCurrentOffer = () => {
  const offersContext = useOffers();
  const selected = offersContext.entireOffer?.finance?.selectedOffer;
  return {
    contractLength: selected?.contractLength?.value ?? null,
    customerType: selected?.customerType?.value ?? null,
    downPayment: selected?.downPayment?.value ?? null,
    mileage: selected?.mileage?.value ?? null,
    salesModel: selected?.salesModel?.value ?? null,
    offerToken: selected?.offerToken ?? null,
    token: selected?.token ?? null,
    policy: offersContext.entireOffer?.policy,
  };
};
