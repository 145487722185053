import { useOverlay } from '@vcc-package/overlays/DEPRECATED';
import React, { useCallback } from 'react';
import { FinanceDetails } from '../finance-details';
import { ShowFinanceDetailsProps } from '../finance-details.types';

export const useFinanceDetails = () => {
  const { openOverlayPage } = useOverlay();

  const showFinanceDetails = useCallback(
    (props: ShowFinanceDetailsProps) => {
      openOverlayPage({
        title: props?.title,
        component: <FinanceDetails onViewChange={openOverlayPage} {...props} />,
        testId: props?.testId ?? 'finance-details',
      });
    },
    [openOverlayPage],
  );

  return { showFinanceDetails };
};
