'use client';
import { useFleetSession } from '../../fleet-session/offers-provider.fleet-session.provider';
import { BTO_CAR_QUERY } from '../../queries/fed-graph/entire-offer.bto.query';
import { STOCK_CAR_QUERY } from '../../queries/fed-graph/entire-offer.stock.query';
import { useEntireOfferCarConfigurationAdapter } from '../adapters/entire-offer.car-configuration.adapter';
import { useEntireOfferDeliveryAdapter } from '../adapters/entire-offer.car-delivery.adapter';
import { useEntireOfferCarEmissionsAdapter } from '../adapters/entire-offer.car-emissions.adapter';
import { useCarQuery } from '../query-hooks/entire-offer.car-query.hook';
import { useError } from './entire-offer.error.hook';
import { useOfferQuery } from '../query-hooks/entire-offer.offer-query.hook';
import {
  CarQueryType,
  CarQueryVariablesType,
  EntireOfferReturnType,
  UseEntireDataOfferProps,
} from '../entire-offer.types';
import { getStockCarQueryFilterVariables } from '../entire-offer.utils';
import { useEntireOfferLastStateChange } from '../adapters/entire-offer.last-state-change';
import { useRef } from 'react';

export const useEntireOfferData = ({
  clientName,
  discountIds,
  postcode,
  retailerId,
  downPayment,
  isDirectSales,
  isStockCar,
  locale,
  market,
  onFleetTokenUpdate,
  token,
  isPrivateStockCar,
  offerToken,
  partExchange,
  onError,
}: UseEntireDataOfferProps): EntireOfferReturnType => {
  const { isLoading: isFleetLoading } = useFleetSession() ?? {};

  const {
    carData,
    error: carError,
    formattedCarError,
    loading: isLoadingCar,
    networkStatus,
  } = useCarQuery<CarQueryType, CarQueryVariablesType>({
    query: isStockCar ? STOCK_CAR_QUERY : BTO_CAR_QUERY,
    locale,
    market,
    clientName,
    optionalVariables: {
      ...(isStockCar
        ? {
            filter: getStockCarQueryFilterVariables({
              isPrivateStockCar,
              token,
            }),
          }
        : { token: token ?? '' }),
    },
    // TODO: isFleetLoading causes the initial loading state to be false then turned into true. We need to come up with a solution for when to get fleet data.
    skip: !token || !locale || !market,
  });

  const tokenToUse = isStockCar ? offerToken ?? '' : token ?? '';
  const {
    offerData,
    loading: isLoadingOffer,
    error: offerError,
    formattedOfferError,
    finance,
    additionals,
  } = useOfferQuery({
    clientName,
    discountIds,
    postcode,
    retailerId,
    downPayment,
    isDirectSales,
    isStockCar,
    locale,
    onFleetTokenUpdate,
    partExchange,
    skip: !tokenToUse || !locale,
    token: tokenToUse,
    vehicleId: isStockCar ? token ?? '' : '',
    market,
  });

  const lastStateChange = useEntireOfferLastStateChange(offerData);
  const originalTokenRef = useRef<string>(token ?? '');
  const { error, updatedError } = useError({
    networkStatus,
    carError,
    offerError,
    token,
    carData,
    isLoading: isLoadingCar,
    isStockCar,
    market,
    isFleetLoading,
    clientName,
    onError,
    formattedCarError,
    formattedOfferError,
  });

  const { stockCars, carByToken } = carData ?? {};
  const [firstHit] = stockCars?.hits ?? [];

  const specification = isStockCar
    ? firstHit?.vehicle?.specification
    : carByToken;

  return {
    finance,
    error,
    updatedError,
    additionals,
    isLoading: isLoadingCar || isFleetLoading || false,
    isUpdating: isLoadingOffer,
    policy: offerData?.carByToken?.policy,
    car: {
      delivery: useEntireOfferDeliveryAdapter({ carData, isStockCar }),
      emissions: useEntireOfferCarEmissionsAdapter({ carData, isStockCar }),
      configuration: useEntireOfferCarConfigurationAdapter({
        carData,
        offerData,
        isStockCar,
        market,
      }),
      tracking: {
        ...specification?.carKey,
        productClass: String(specification?.productLine?.key),
        currencyCode: String(
          offerData?.offerByToken?.priceSummary?.carPriceSummary?.breakDown
            ?.baseCar?.price?.currency,
        ),
        modelFamily: String(specification?.modelFamily?.key),
        carDrivelineType:
          specification?.engine?.content?.engineType?.value?.toLowerCase(),
        color: specification?.color?.content?.displayName?.value?.toLowerCase(),
      },
    },
    lastStateChange,
    originalToken: originalTokenRef.current,
  };
};
