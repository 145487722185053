import { useQuery } from '@apollo/client';
import { useOffers } from '@vcc-package/offers-provider';
import { FinanceDetailsWithDefaultDataQueryProps } from '../finance-details.types';
import { FINANCE_DETAILS_DATA_QUERY } from '../queries/finance-details-data.query';
import {
  FinanceDetailsDataQuery,
  FinanceDetailsDataQueryVariables,
} from '../queries/finance-details-data.query.gql-types';

export const useFinanceDetailsWithDefaultDataQuery = ({
  token,
}: FinanceDetailsWithDefaultDataQueryProps) => {
  const { locale } = useOffers();

  const { data, loading, error } = useQuery<
    FinanceDetailsDataQuery,
    FinanceDetailsDataQueryVariables
  >(FINANCE_DETAILS_DATA_QUERY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: {
      locale,
      token: token ?? '',
    },
    skip: !token,
  });

  error && console.error(error);

  return {
    data,
    loading,
  };
};
