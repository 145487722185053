import type { SessionSharedData } from '@vcc-www/auth-client/session-service/types';
import { FLEET_SESSION_TYPE, FleetSessionType } from './get-session-type.types';

export const getSessionType = (
  fleetSessionData: SessionSharedData | null | undefined,
): FleetSessionType => {
  if (fleetSessionData?.fleetCompanyId) {
    return FLEET_SESSION_TYPE.FLEET_ACCOUNT;
  }

  if (fleetSessionData?.fleetPersona === 'LEAD') {
    return FLEET_SESSION_TYPE.FLEET_PRICING_PREVIEW;
  }

  if (fleetSessionData?.fleetPersona === 'PENDING') {
    return FLEET_SESSION_TYPE.BUSINESS_PRICING;
  }

  return null;
};
