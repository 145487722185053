import { useFinanceOptionToFinanceDetails } from '@vcc-www/api/finance-options/utils';
import { useOverlay } from '@vcc-package/overlays/DEPRECATED';
import { useMemo } from 'react';
import {
  FinanceDetailsProps,
  FinanceDetailsWithoutTokenProps,
} from '../finance-details.types';
import { getFinanceOption } from '../finance-details.utils';
import { useFinanceDetailsWithCustomData } from './finance-details.custom.hook';
import { useFinanceDetailsWithoutTokenQuery } from './finance-details.without-token-query.hook';
import { useContent } from '@vcc-package/offers-provider';

export const useFinanceDetailsWithoutToken = ({
  customerType,
  salesModel,
  model,
  onViewChange,
}: FinanceDetailsWithoutTokenProps): FinanceDetailsProps => {
  const { translate } = useContent();
  const { openOverlayPage } = useOverlay();

  const { data, loading } = useFinanceDetailsWithoutTokenQuery({
    customerType,
  });

  const { financeOption } = useMemo(
    () =>
      getFinanceOption({
        data,
        salesModel: salesModel ?? '',
      }),
    [data, salesModel],
  );

  const financeDetails = useFinanceOptionToFinanceDetails({
    displayName: financeOption?.displayName ?? '',
    description: financeOption?.description ?? '',
    image: financeOption?.image ?? '',
    disclaimer: financeOption?.disclaimer ?? '',
    howItWorks: financeOption?.howItWorks ?? [],
    services:
      (model
        ? financeOption?.services?.filter((service) =>
            service.applicableCarModels.includes(model),
          )
        : financeOption?.services) ?? [],
    translate,
  });
  const financeDetailsWithCustomData = useFinanceDetailsWithCustomData({
    financeDetails,
    onViewChange: onViewChange ?? openOverlayPage,
    translate,
  });

  return { ...financeDetailsWithCustomData, loading };
};
