'use client';
import React, { useContext, createContext } from 'react';
import { type OverlayContextType, type OverlayProviderType } from '.';
import { useOverlayProvider } from './overlay-provider.hook';
import { OverlayProviderView } from './overlay-provider.view';

const initialContext: OverlayContextType = {
  openOverlayPage: () => {},
  resetAndCloseOverlay: () => {},
};

const OverlayContext = createContext<OverlayContextType>(initialContext);

const OverlayProvider: React.FC<OverlayProviderType> = ({ children }) => {
  const {
    showBackButton,
    currentPage,
    currentPageContent,
    goBack,
    properties,
    fromL,
    dialogRef,
  } = useOverlayProvider();

  return (
    <OverlayContext.Provider value={properties}>
      <OverlayProviderView
        showBackButton={showBackButton}
        currentPage={currentPage}
        currentPageContent={currentPageContent}
        goBack={goBack}
        resetAndCloseOverlay={properties?.resetAndCloseOverlay}
        fromL={fromL}
        dialogRef={dialogRef}
      >
        {children}
      </OverlayProviderView>
    </OverlayContext.Provider>
  );
};

const WrappedOverlayProvider: React.FC<OverlayProviderType> = ({
  children,
}) => <OverlayProvider>{children}</OverlayProvider>;

export { WrappedOverlayProvider as OverlayProvider };

export const useOverlay = () => {
  const overlayContext = useContext(OverlayContext);
  if (overlayContext === undefined) {
    throw new Error('useOverlay must be inside a Provider');
  }
  return useContext(OverlayContext);
};
