// Switzerland

import { MarketSettings } from './market-settings.types';
import { BREAKDOWN_ITEMS } from '../price-breakdown.constants';
const {
  CONTRACT_LENGTH,
  MILEAGE,
  DEPOSIT,
  DISCOUNT,
  EXCESS_MILEAGE_RATE,
  PURCHASE_PRICE,
  APR,
  PAF,
} = BREAKDOWN_ITEMS;

export const supportedFinancialBreakdownItems: MarketSettings['supportedFinancialBreakdownItems'] =
  [
    CONTRACT_LENGTH,
    MILEAGE,
    DEPOSIT,
    DISCOUNT,
    EXCESS_MILEAGE_RATE,
    PURCHASE_PRICE,
    PAF,
    APR,
  ];

export const roundingStrategy: MarketSettings['roundingStrategy'] =
  'alwaysRound';
