import { useEntireListingOffer } from './hooks/entire-offer.listing.hook';
import {
  EntireOfferReturnType,
  UseEntireOfferProps,
} from './entire-offer.types';
import { useEntireOfferData } from './hooks/entire-offer.data.hook';

export const useEntireOffer = ({
  isPrivateStockCar,
  offerToken,
  onFleetTokenUpdate,
  salesModel,
  ...defaultOfferProps
}: UseEntireOfferProps): EntireOfferReturnType => {
  const entireListingOffer = useEntireListingOffer({
    ...defaultOfferProps,
    salesModel,
  });

  const entireOffer = useEntireOfferData({
    ...defaultOfferProps,
    onFleetTokenUpdate,
    offerToken,
    isPrivateStockCar,
  });

  return !defaultOfferProps?.token ? entireListingOffer : entireOffer;
};
