import React from 'react';
import { cssJoin } from '@volvo-cars/css/utils';
import { IconButton } from '@volvo-cars/react-icons';
import { type OverlayPageViewProps } from './overlay.types';
import styles from './overlay-provider.view.module.css';

export const OverlayProviderView = ({
  currentPage,
  currentPageContent,
  showBackButton,
  goBack,
  resetAndCloseOverlay,
  dialogRef,
  children,
}: OverlayPageViewProps) => {
  return (
    <>
      {currentPage && (
        <dialog
          className="dialog container-sm p-0 flex flex-col bg-primary"
          data-bleed="until-md"
          ref={dialogRef}
          data-testid={currentPage?.testId}
        >
          <div
            className={cssJoin(
              'grid items-center border-b py-16 px-8',
              styles.header,
            )}
          >
            <div>
              {showBackButton && (
                <IconButton
                  icon="chevron-back"
                  className="ml-4"
                  aria-label="Back"
                  bleed
                  onClick={goBack}
                  variant="clear"
                />
              )}
            </div>

            {currentPage?.title ? (
              <h2
                className="flex font-medium"
                data-testid={`${currentPage?.testId}-title`}
              >
                {currentPage?.title}
              </h2>
            ) : (
              <div />
            )}

            <div className="flex justify-end">
              <IconButton
                icon="x"
                className="mr-4"
                aria-label="Close"
                bleed
                onClick={resetAndCloseOverlay}
                variant="clear"
              />
            </div>
          </div>

          <div className={cssJoin('stack-text', styles.body)}>
            {currentPageContent}
          </div>
        </dialog>
      )}
      {children}
    </>
  );
};
