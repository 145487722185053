// Fed Graph requires a different locale format for some markets
export const getLocaleForFedGraph = (locale?: string) => {
  if (!locale) return 'en-TL';

  const localeToFedGraphLocaleMap: { [key: string]: string } = {
    'sr-Latn-RS': 'sr-RS', // Serbia
    'cnr-ME': 'sr-ME', // Montenegro
    'en-001': 'en-TL', // International
  };

  if (localeToFedGraphLocaleMap[locale])
    return localeToFedGraphLocaleMap[locale];

  return locale;
};
