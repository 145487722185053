import { SettingsFlagData } from '@vcc-package/offers-provider';
import { SYSTEM, SYSTEM_CONFIG } from './preview.constants';
import {
  CloseToolbarProps,
  GetInitialPreviewStateProps,
  PreviewStateInterface,
  SaveSettingsProps,
  SaveSourceProps,
  SearchParamsType,
  ShowContentSourcesOnHoverProps,
  ToggleShowContentSourcesProps,
  ToggleShowGridProps,
} from './preview.types';
import isEqual from 'lodash/isEqual';

export const getInitialPreviewState = ({
  deployEnv,
  searchParams,
}: GetInitialPreviewStateProps): PreviewStateInterface => {
  return deployEnv === 'prod'
    ? {
        overrideFlags: {},
        overrideSources: {},
        showGrid: false,
        showSources: false,
      }
    : getPreviewStateFromNextRouterQuery(Object.fromEntries(searchParams));
};

export const getPreviewStateFromNextRouterQuery = (
  searchParams: SearchParamsType,
  deployEnv?: string,
): PreviewStateInterface => {
  const { sources, flags, showSources, showGrid, cdSource, cceSource } =
    searchParams || {};
  const overrideSources = getSourcesFromQueryParam(String(sources ?? ''), {
    cdSource: String(cdSource ?? ''),
    cceSource: String(cceSource ?? ''),
  });
  const overrideFlags = getFlagsFromQueryParam(String(flags ?? ''));

  return {
    overrideSources,
    overrideFlags,
    showGrid: String(showGrid ?? '') === 'true',
    showSources:
      String(showSources ?? (deployEnv === 'qa' ? 'true' : '') ?? '') ===
      'true',
  };
};

export const getSourcesFromQueryParam = (
  sources: string,
  legacySupport?: { cdSource?: string; cceSource?: string },
): PreviewStateInterface['overrideSources'] => {
  const systemConfigArray = Object.keys(SYSTEM_CONFIG);
  const overrideSources = sources
    ? sources
        ?.split('.')
        .map((systemServerPair) => {
          const [system, server] = systemServerPair.split('-');
          const systemId =
            systemConfigArray?.find(
              (systemConfigId) =>
                systemConfigId === system ||
                SYSTEM_CONFIG[systemConfigId].shortId === system,
            ) ?? '';
          return { [systemId]: server ?? true };
        })
        .reduce((prev, curr) => ({ ...prev, ...curr }), {})
    : {};

  if (legacySupport?.cdSource) {
    overrideSources[SYSTEM.CONTENT_SOURCE] = legacySupport?.cdSource;
  }

  if (legacySupport?.cceSource) {
    overrideSources[SYSTEM.FEDERATED_GRAPH] = legacySupport?.cceSource?.replace(
      /staging/gi,
      'qa',
    );
  }

  return overrideSources as PreviewStateInterface['overrideSources'];
};

export const getFlagsFromQueryParam = (flags: string) => {
  return !flags
    ? {}
    : (String(flags ?? '')
        ?.split('.')
        .map((flag) => {
          const [name, value] = flag.split('-');
          return {
            [name as keyof SettingsFlagData]: ['true', 'false'].includes(value)
              ? value === 'true'
              : value,
          };
        })
        ?.reduce(
          (prev, curr) => ({ ...prev, ...curr }),
          {},
        ) as Partial<SettingsFlagData>);
};

export const formatSourcesForQueryParam = (sources: any) => {
  return Object.keys(sources ?? {})
    ?.map(
      (setting) =>
        `${SYSTEM_CONFIG[setting]?.shortId}-${
          sources?.[setting as keyof typeof sources]
        }`,
    )
    .join('.');
};

export const formatFlagsForQueryParam = (
  overrideFlags: Partial<SettingsFlagData>,
) => {
  return Object.keys(overrideFlags ?? {})
    ?.map(
      (flag) => `${flag}-${overrideFlags?.[flag as keyof SettingsFlagData]}`,
    )
    .join('.');
};

export const closeToolbar = ({
  toolbarOpen,
  toggleToolbarOpen,
}: CloseToolbarProps) => {
  toolbarOpen && toggleToolbarOpen();
};

export const saveSettings = ({
  onUpdatePreviewState,
  overrideSettings,
}: SaveSettingsProps) => {
  onUpdatePreviewState({
    flags: formatFlagsForQueryParam(overrideSettings),
  });
};

export const showContentSourcesOnHover = ({
  e,
  previewState,
  contentSources,
  setContentSources,
  setElementBoundingRect,
}: ShowContentSourcesOnHoverProps) => {
  if (!previewState?.showContentSources) return;

  const elem = e.target as HTMLElement;
  const sources = elem?.dataset.sources?.split(',');
  const sourcesParent = elem?.parentElement?.dataset.sources?.split(',');

  if (!sources?.length && !sourcesParent?.length && contentSources.length) {
    setContentSources([]);
  } else if (
    (sources?.length && !isEqual(sources, contentSources)) ||
    (sourcesParent?.length && !isEqual(sourcesParent, contentSources))
  ) {
    setContentSources(sources ?? sourcesParent ?? []);
  }
  setElementBoundingRect(elem?.getBoundingClientRect());
};

export const getChatButtonElement = () =>
  !!document
    .getElementById('sitenav:state:chat')
    ?.innerText?.includes('"chatIconVisibility":"visible"');

export const toggleShowContentSources = ({
  onUpdatePreviewState,
  previewState,
}: ToggleShowContentSourcesProps) => {
  onUpdatePreviewState({
    showSources: String(!previewState?.showContentSources),
  });
};

export const saveSources = ({
  overrideSources,
  onUpdatePreviewState,
}: SaveSourceProps) => {
  onUpdatePreviewState({
    sources: formatSourcesForQueryParam(overrideSources),
  });
};

export const toggleShowGrid = ({
  onUpdatePreviewState,
  previewState,
}: ToggleShowGridProps) => {
  onUpdatePreviewState({
    showGrid: String(!previewState?.showGrid),
  });
};
