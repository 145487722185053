import gql from 'graphql-tag';

export const PRICE_FRAGMENT = gql`
  fragment PriceData on PriceAmount {
    amount
    display
    selector
    source
    includingVat
    taxAmount
  }
`;

export const OFFER_CONFIGURATION_SELECTIONS_FRAGMENT = gql`
  fragment OfferSelectionData on OfferSelection {
    identifier: id
    type
    appliedChange {
      token {
        short
      }
      selectionsAdded {
        id
        type
      }
      selectionsRemoved {
        id
        type
      }
    }
    content(locale: $locale) {
      ... on ProductContent {
        displayName {
          value
          label
        }
      }
      ... on AccessoryContent {
        displayName {
          value
          label
        }
      }
      ... on ServiceContent {
        displayName {
          value
          label
        }
      }
      ... on SalesModelContent {
        displayName {
          value
        }
        description {
          value
        }
        disclaimer {
          value
        }
        shortDescription {
          value
        }
        image {
          url
        }
        thumbnail {
          url
        }
      }
      ... on NumericOfferProperty {
        value
        formatted
      }
    }
  }
`;

export const OFFER_CONFIGURATION_SELECTIONS_WITH_PRICE_SUMMARY_FRAGMENT = gql`
  fragment OfferSelectionWithPriceSummaryData on OfferSelection {
    ...OfferSelectionData
    priceSummary(priceConfigInput: $priceConfigInputV2) {
      price {
        displayPrice {
          ...PriceData
        }
      }
    }
  }
  ${OFFER_CONFIGURATION_SELECTIONS_FRAGMENT}
`;

export const PRICE_SUMMARY_FRAGMENT = gql`
  fragment PriceSummaryFields on CarPriceSummary {
    dueToday {
      ...PriceData
      includingVat
    }
    downpaymentAdjustment {
      ...PriceData
    }
    all {
      vatAmount {
        ...PriceData
      }
      dueTodayVatAmount {
        ...PriceData
      }
      msrpPriceExclTaxInclVat {
        ...PriceData
      }
      subscriptionPriceInclTaxExclVat {
        ...PriceData
      }
      subscriptionPriceInclTax {
        ...PriceData
      }
      priceInclTax {
        ...PriceData
      }
    }
    priceWithoutTax {
      ...PriceData
    }
    transactionBaseTaxes {
      id
      taxId
      pretaxWithTaxes {
        ...PriceData
      }
      pretaxWithTaxesExclVat {
        ...PriceData
      }
    }
    totalPrice {
      ...PriceData
      includingVat
    }
    breakDown {
      baseCar {
        price {
          ...PriceData
          currency
        }
        code
        type
      }
      car {
        afterCheckout {
          ...PriceData
        }
        afterCheckoutWithoutTax {
          ...PriceData
        }
      }
      color {
        price {
          ...PriceData
          currency
        }
        code
        type
      }
      options {
        price {
          ...PriceData
          currency
        }
        code
        type
      }
      packages {
        price {
          ...PriceData
          currency
        }
        code
        type
      }
      upholstery {
        price {
          ...PriceData
          currency
        }
        code
        type
      }
      all {
        code
        price {
          ...PriceData
        }
        type
      }
    }
    taxes {
      acquisition {
        taxId
        name
        price {
          ...PriceData
        }
        vatPosition
      }
      informational {
        taxId
        name
        price {
          ...PriceData
        }
        tags
      }
      ownership {
        taxId
        name
        price {
          ...PriceData
        }
      }
    }
  }
  ${PRICE_FRAGMENT}
`;

// TODO: This is not final, more data will be added here. Clean up when we know exactly what we need
export const FINANCE_PRICING_FRAGMENT = gql`
  fragment PercentageRate on PercentageRate {
    display
    percentage
    rate
  }

  fragment FinancePricing on FinancingPriceSummary {
    breakdown {
      perInstallmentPrice {
        ...PriceData
      }
      firstMonthPayment {
        ...PriceData
      }
      preFinancingPrice {
        displayPrice {
          ...PriceData
        }
      }
      creditAmount {
        ...PriceData
      }
      finalPaymentAmount {
        ...PriceData
      }
      totalPayableAmount {
        ...PriceData
      }
      interestAmount {
        ...PriceData
      }
      interestRates {
        rate
        type
      }
      interestRateSteps {
        type
        rate {
          ...PercentageRate
        }
        minimumDownpaymentRate {
          ...PercentageRate
        }
        maximumDownpaymentRate {
          ...PercentageRate
        }
        minimumDownpayment {
          ...PriceData
        }
        maximumDownpayment {
          ...PriceData
        }
      }
      adjustedPreFinancingAmount {
        displayPrice {
          ...PriceData
        }
      }
      financeDiscountContribution {
        displayPrice {
          ...PriceData
        }
      }
      financedAmount {
        ...PriceData
      }
      totalDeposit {
        displayPrice {
          ...PriceData
        }
        vatAmount {
          ...PriceData
        }
      }
      fees {
        type
        amount {
          ...PriceData
        }
      }
      prePayment {
        amount {
          displayPrice {
            ...PriceData
            currency
            taxAmount
          }
          vatAmount {
            ...PriceData
          }
          priceInclTax {
            ...PriceData
          }
        }
      }
      downpayment {
        amount {
          vatAmount {
            ...PriceData
          }
          displayPrice {
            ...PriceData
          }
          priceInclTax {
            ...PriceData
          }
        }
      }
      creditCostAmount {
        ...PriceData
      }
      excessMileageRate
    }
    realTimeBreakdown {
      referenceId
    }
  }
  ${PRICE_FRAGMENT}
`;

export const SALES_LEVEL_DATA_FRAGMENT = gql`
  fragment SalesLevelData on SalesLevelContent {
    key
    displayName {
      value
    }
    entityType {
      formatted
    }
  }
`;

export const DISCOUNTS = gql`
  fragment Discounts on Discounts {
    discountAmount {
      displayPrice {
        ...PriceData
      }
      vatAmount {
        ...PriceData
      }
    }
    # TODO: Is this needed? displayPrice gives us correct value already.
    priceBeforeDiscount {
      priceExclTax {
        ...PriceData
      }
      displayPrice {
        ...PriceData
      }
      vatAmount {
        ...PriceData
      }
    }
    nominalDiscountRate {
      display
      percentage
    }
  }
  ${PRICE_FRAGMENT}
`;

export const APPLIED_DISCOUNTS_FRAGMENT = gql`
  fragment AppliedDiscounts on AvailableDiscount {
    discountAmount {
      displayPrice {
        ...PriceData
      }
    }
    name
    description
    expirationDate
    id
    applicableFor
    startTerm
    endTerm
    exclusions
  }
  ${PRICE_FRAGMENT}
`;

export const EMISSIONS_AND_SAFETY_DATA_FRAGMENT = gql`
  fragment TechnicalData on TechnicalData {
    all {
      key
      legacyKey
      label
      formatted
    }
  }

  fragment EnergyClassification on EfficiencyClassification {
    efficiencyClass
    efficiencyRanges
    errorMessage {
      errorCode
      errorEfficiencyClass
    }
  }

  fragment SafetyRating on SafetyRating {
    safetyRating
    modelCode
    safetySystem {
      id
      name
      value
      available
    }
  }

  fragment TyreLabels on TyreLabel {
    url
    metadata {
      position
      winter
      eprelUrl
    }
  }
`;

export const ENERGY_EFFICIENCY_SETTINGS_FRAGMENT = gql`
  fragment EnergyEfficiencySettings on ENERGY_EFFICIENCY_SETTINGS {
    defaultCO2
    energyEfficiencyKeys {
      name
      values
    }
  }
`;

export const SALES_MODEL_SETTINGS_FRAGMENT = gql`
  fragment SalesModelSettings on SALES_MODEL_SETTING {
    salesModel
    default
    policy
    roundingStrategy
    customerTypes
    customerTypePriceInputs {
      settings {
        name
        value
      }
      customerType
    }
    hidePrices
    clients
  }
`;

// Accessories and Services
export const ACCESSORIES_AND_SERVICES_FRAGMENT = gql`
  fragment AccessoryContent on AccessoryContent {
    displayName {
      value
    }
    # TODO: Replace image whith thumbnail when it is available
    thumbnail {
      url
    }
    image {
      url
    }
  }

  fragment ProductContent on ProductContent {
    displayName {
      value
    }
    # TODO: Replace image whith thumbnail when it is available
    thumbnail {
      url
    }
    image {
      url
    }
  }

  fragment PackageContent on PackageContent {
    displayName {
      value
    }
    # TODO: Replace image whith thumbnail when it is available
    thumbnail {
      url
    }
    image {
      url
    }
  }

  fragment ServiceContent on ServiceContent {
    displayName {
      value
    }
    # TODO: Replace image whith thumbnail when it is available
    thumbnail {
      url
    }
    image {
      url
    }
  }

  fragment Price on ItemPriceSummary {
    price {
      displayPrice {
        ...PriceData
      }
      vatAmount {
        ...PriceData
      }
    }
  }

  fragment AccessoriesAndServices on Products {
    accessories {
      type
      optional
      tags
      id
      content(locale: $locale) {
        ...AccessoryContent
      }
      priceSummary(priceConfigInput: $priceConfigInputV2) {
        ...Price
      }
    }
    packages {
      id
      optional
      tags
      items {
        content(locale: $locale) {
          ...ProductContent
          ...AccessoryContent
          ...ServiceContent
        }
      }
      content(locale: $locale) {
        ...PackageContent
      }
      priceSummary(priceConfigInput: $priceConfigInputV2) {
        ...Price
      }
    }
    services {
      type
      optional
      tags
      id
      content(locale: $locale) {
        ...ServiceContent
      }
      priceSummary(priceConfigInput: $priceConfigInputV2) {
        ...Price
      }
    }
  }
`;
