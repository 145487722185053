import { PaymentPlanAggregation } from '@vcc-www/federated-graph-types';

export const PRICE_BREAKDOWN_SECTION = {
  PAY_NOW: 'payNow',
  PAY_LATER: 'payLater',
  PAY_MONTHLY: 'payMonthly',
} as const;

export type PriceBreakdownSectionType =
  (typeof PRICE_BREAKDOWN_SECTION)[keyof typeof PRICE_BREAKDOWN_SECTION];

export const BREAKDOWN_VARIANT_TYPES = {
  MICRO: 'micro',
  EMPHASIS: 'emphasis',
} as const;

export type BreakdownItemVariantType =
  (typeof BREAKDOWN_VARIANT_TYPES)[keyof typeof BREAKDOWN_VARIANT_TYPES];

export type BreakdownItemVariantTypes = Array<BreakdownItemVariantType>;

export const RATES = {
  APR: 'apr',
  PAF: 'paf',
  INTEREST_RATE: 'interestRate',
} as const;

export type RatesType = (typeof RATES)[keyof typeof RATES];

export const BREAKDOWN_TYPES = {
  ADJUSTED_PRE_FINANCING_AMOUNT: 'adjustedPreFinancingAmount',
  APR: 'apr',
  CREDIT: 'credit',
  DEPOSIT: 'deposit',
  DISCOUNT: 'discount',
  EXCESS_MILEAGE_RATE: 'excessMileageRate',
  FINAL_PAYMENT: 'finalPayment',
  FIXED_RATE_OF_INTEREST: 'fixedRateOfInterest',
  PAYABLE_AMOUNT: 'payableAmount',
  PURCHASE_PRICE: 'purchasePrice',
} as const;

export type BreakdownType =
  (typeof BREAKDOWN_TYPES)[keyof typeof BREAKDOWN_TYPES];

export const PAYMENT_POSITIONS: Record<string, keyof PaymentPlanAggregation> = {
  AFTER_CHECKOUT: 'afterCheckout',
  AT_CHECKOUT: 'atCheckout',
  RECURRING: 'recurring',
} as const;

export type PaymentPositionsType =
  (typeof PAYMENT_POSITIONS)[keyof typeof PAYMENT_POSITIONS];

export const BREAKDOWN_ITEMS = {
  FINAL_PAYMENT: 'finalPayment',
  CONTRACT_LENGTH: 'contractLength',
  MILEAGE: 'mileage',
  DEPOSIT: 'deposit',
  DISCOUNT: 'discount',
  PURCHASE_PRICE: 'purchasePrice',
  PAYABLE_AMOUNT: 'payableAmount',
  EXCESS_MILEAGE_RATE: 'excessMileageRate',
  PAF: 'paf',
  AMOUNT_OF_CREDIT: 'amountOfCredit',
  APR: 'apr',
  INTEREST_AMOUNT: 'interestAmount',
  FINANCED_AMOUNT: 'financedAmount',
  CREDIT_COST_AMOUNT: 'creditCostAmount',
  PROCESSING_FEE: 'processingFee',
  ESTABLISHMENT_FEE: 'establishmentFee',
};

export type BreakdownItemsType =
  (typeof BREAKDOWN_ITEMS)[keyof typeof BREAKDOWN_ITEMS];

export const FLEET_SESSION = {
  BUSINESS_PRICING: 'BUSINESS_PRICING',
  FLEET_ACCOUNT: 'FLEET_ACCOUNT',
  FLEET_PRICING_PREVIEW: 'FLEET_PRICING_PREVIEW',
  FLEET_COMPANY_CAR_DRIVER: 'FLEET_COMPANY_CAR_DRIVER',
  FLEET_PENDING_ACCOUNT: 'FLEET_PENDING_ACCOUNT',
  FLEET_APPROVED_ACCOUNT: 'FLEET_APPROVED_ACCOUNT',
} as const;
