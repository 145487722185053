export const BUSINESS_MODELS = {
  WHOLESALE: 'WHOLESALE',
  DIRECT: 'DIRECT',
} as const;

export const SALES_MODELS = {
  CASH: 'cash',
  DEFAULT: 'default',
  FIXED: 'sub_fixed',
  FLEX: 'sub',
  LEASE: 'lease',
  LOAN: 'loan',
  WHOLESALE: 'wholesale',
} as const;

export const SALES_MODELS_PURCHASE = {
  CASH: 'cash',
  DEFAULT: 'default',
} as const;

export const SALES_MODELS_MONTHLY = {
  FIXED: 'sub_fixed',
  FLEX: 'sub',
  LEASE: 'lease',
  LOAN: 'loan',
} as const;

export type BusinessModels =
  (typeof BUSINESS_MODELS)[keyof typeof BUSINESS_MODELS];
export type SalesModels = (typeof SALES_MODELS)[keyof typeof SALES_MODELS];
export type SalesModelsPurchase =
  (typeof SALES_MODELS_PURCHASE)[keyof typeof SALES_MODELS_PURCHASE];
export type SalesModelsMonthly =
  (typeof SALES_MODELS_MONTHLY)[keyof typeof SALES_MODELS_MONTHLY];
