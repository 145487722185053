import { LogProps, LoggerProps } from './log.utils.types';

// Log function
const logger = ({
  service,
  message,
  clientName,
  severityGrade,
  correlationId,
  input,
}: LoggerProps) => {
  const logMessage = `[${clientName}] -> [${service}] ${
    correlationId ? `-> [correlationId: ${correlationId}]` : ''
  }: ${message}`;
  console[severityGrade](logMessage);
  if (input) {
    console[severityGrade](JSON.stringify(input));
  }
  return;
};

/***
 * Unified logging for offer-selector
 * @param service - name of the service
 * @param message - message to be logged
 * @param correlationId - correlationId to be logged
 * @param client - name of the service
 * @param input - input data to be logged
 * @returns void
 */
export class log {
  static error = (errorProps: LogProps) => {
    logger({ ...errorProps, severityGrade: 'error' });
  };

  static warn = (warnProps: LogProps) => {
    logger({ ...warnProps, severityGrade: 'warn' });
  };

  static info = (infoProps: LogProps) => {
    logger({ ...infoProps, severityGrade: 'info' });
  };

  static debug = (infoProps: LogProps) => {
    logger({ ...infoProps, severityGrade: 'debug' });
  };
}
