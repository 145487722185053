import { useQuery } from '@apollo/client';
import { FINANCE_OPTIONS_QUERY } from '@vcc-www/api/finance-options/useFinanceOptions';
import {
  FinanceOptionsQuery,
  FinanceOptionsQueryVariables,
} from '@vcc-www/gql-types/FinanceOptionsQuery';
import { useOffers } from '@vcc-package/offers-provider';
import { FinanceDetailsWithoutTokenQueryProps } from '../finance-details.types';

export const useFinanceDetailsWithoutTokenQuery = ({
  customerType,
}: FinanceDetailsWithoutTokenQueryProps) => {
  const { locale, regionCode } = useOffers();

  const { data, loading, error } = useQuery<
    FinanceOptionsQuery,
    FinanceOptionsQueryVariables
  >(FINANCE_OPTIONS_QUERY, {
    variables: {
      market: regionCode ?? '',
      locale,
      customerType: customerType?.toLocaleUpperCase() ?? '',
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  error && console.error(error);

  return {
    data,
    loading,
  };
};
