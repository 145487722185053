import React, { HTMLAttributes } from 'react';
import styles from '../discovery-card.module.css';
import { cssMerge } from '@volvo-cars/css/utils';
import { ResponsiveImageProps } from '@vcc-package/media';
import Card from '../parts';
import { ResponsiveImagesContent } from '@vcc-package/media/content-management';

type CardProps = React.PropsWithChildren & HTMLAttributes<HTMLDivElement>;
type CardPropsWithMedia = CardProps & {
  responsiveImages: ResponsiveImageProps['images'];
};
type HorizontalCardProps = CardPropsWithMedia & {
  layout?: 'reversed' | 'default';
  variant?: 'horizontal' | undefined;
};
type StackedCardProps = CardPropsWithMedia & {
  variant: 'stacked';
};

const DiscoveryCard = ({
  children,
  className,
  ...rest
}: HorizontalCardProps | StackedCardProps | CardProps) => {
  const variant = 'variant' in rest ? rest.variant : undefined;
  const images = 'responsiveImages' in rest ? rest.responsiveImages : undefined;
  const horizontalAspectRatios: Partial<ResponsiveImagesContent> = {
    smAspectRatio: '4:3',
    mdAspectRatio: '1:1',
    lgAspectRatio: '4:3',
  };
  const layout = 'layout' in rest ? rest.layout : undefined;

  return (
    <div
      {...rest}
      className={cssMerge(
        'w-full bg-secondary',
        styles.discovery_card,
        variant ? styles[variant] : styles.no_media,
        className,
        variant === 'horizontal' && layout === 'reversed' && styles.reversed,
      )}
    >
      {variant === 'stacked' && images && (
        <Card.Image responsiveImages={{ smAspectRatio: '16:9', ...images }} />
      )}
      {variant === 'horizontal' && images && (
        <Card.Image
          responsiveImages={{ ...horizontalAspectRatios, ...images }}
        />
      )}
      {children}
    </div>
  );
};

export default DiscoveryCard;
