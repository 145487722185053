export const GA4_ANALYTICS_COOKIE = '_ga';

export const CUSTOMER_TYPE = {
  B2B: 'B2B',
  B2C: 'B2C',
} as const;

export const FALLBACKS = {
  locale: 'en-TL',
  market: 'intl',
  regionCode: 'US',
} as const;

// TODO: Better to have all errors in one place? E.g. in offers-provider
export const ERRORS = {
  CAR_NOT_FOUND: 'Car not found',
  NETWORK_ERROR: 'Network error',
  OFFER_ERROR: 'Offer error',
  FINANCIAL_CALCULATION_ERROR: 'Financial calculation error',
} as const;

export const CONTACT_BY_NAME = {
  CarSelector: 'Car Selector team',
  CarConfigurator: 'Car Configurator team',
  OfferSelector: 'Offer Selector team',
} as const;

export const CONTACT_BY_SERVICE_NAME = {
  'car-configuration-dgs': 'Rules Engine team',
  'garage-service': 'Rules Engine team',
  'pricing-dgs': 'GPE team',
  'transfer-service': 'Transact team',
  'environmental-dgs': 'REDA team',
  'product-catalogue': 'Product Catalogue team',
  'product-enrichment-dgs': 'PRISM team',
  'stock-car-dgs': 'CLE team',
  'visualization-dgs': 'CAS Images team',
} as const;

export const CONTACT_BY_CLIENT_ID = {
  PrismService: 'POP team',
  OptimizationFacade: 'POP team',
} as const;

export const CONTACT_BY_OPERATION_NAME = {
  StockCarsQuery: 'CLE team',
  CarsByMarketQuery: 'POP team',
  ClientConfigurationQuery: 'POP team',
  PreConfiguredCarsByPolicy: 'POP team',
  CarByToken: 'POP team',
  OfferTokenData: 'POP team',
  PipelineCarsPriceQuery: 'Price engine team',
  SingleCarOfferPrice: 'Price engine team',
  GpeService: 'Price engine team',
  StockCarById: 'CLE team',
  StockCarsFilters: 'CLE team',
  StockCarsMetadata: 'CLE team',
  CarsByPolicyQuery: 'POP team',
} as const;

export const ERROR_CONTACT_TEAMS = {
  ...CONTACT_BY_NAME,
  ...CONTACT_BY_SERVICE_NAME,
  ...CONTACT_BY_CLIENT_ID,
  ...CONTACT_BY_OPERATION_NAME,
};
