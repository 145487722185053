export const INTEREST_RATES = {
  APR: 'APR',
  PAF: 'PAF',
} as const;

export const NUMBER_OF_DECIMALS_FOR_APR = 2;

export const PRICE_SUMMARY_TEST_IDS = {
  DUE_BEFORE_DELIVERY: 'payment-summary-due-before-delivery',
  DUE_TODAY: 'payment-summary-due-today',
  DUE_MONTHLY: 'payment-summary-due-monthly',
  FINANCE_DETAILS: 'payment-summary-finance-details',
} as const;

export const BREAKDOWN_ITEM_CONTENT_FIELD_BY_KEY: { [key: string]: string } = {
  refundableDeposit: 'PriceBreakdown.priceBreakdown.refundableDeposit',
  partialDeposit: 'PriceBreakdown.priceBreakdown.partialDeposit',
  firstMonthlyPayment: 'PriceBreakdown.priceSummary.firstMonthlyPayment',
};
