import { Action } from '@vcc-www/federated-graph-types';
import type {
  GetIsMultiOptionsAvailableProps,
  GetPackageFromProductsProps,
  GetSelectedServiceProps,
  GetTagsFromPackageSelectionProps,
  GetTokenContainsIncludedServiceProps,
  GetTrackingDataProps,
  GetProductsTitleLabelsProps,
  GetPackageDataProps,
  GetTokenWithoutSelectedPackageProps,
  HasSortOrderProps,
  SortItemsBySortOrderProps,
} from './products.types';
import { getPackages } from './data/get-packages/get-packages';
import { GetPackagesTypes } from './data/get-packages/get-packages.types';

/**
 * Type guard function to check if the 'content' object has a 'sortOrder' property.
 *
 * This function is necessary because TypeScript is not aware of the 'sortOrder' property
 * in the 'content' object due to the GraphQL fragment '... on ServiceContent'
 * in the query. Without this check, TypeScript throws errors when trying to access
 * 'content.sortOrder'.
 *
 * @param content - The object to check for a 'sortOrder' property
 * @returns A boolean indicating whether 'content' can be treated as an object with a 'sortOrder'
 */

const hasSortOrder = (
  content: HasSortOrderProps,
): content is { sortOrder?: number | null } => {
  return content ? 'sortOrder' in content : false;
};

export function sortItemsBySortOrder({ items }: SortItemsBySortOrderProps) {
  const itemsCopy = [...items];
  return itemsCopy.sort((a, b) => {
    let sortOrderA = 0,
      sortOrderB = 0;

    if (hasSortOrder(a.content)) {
      sortOrderA = a.content.sortOrder ?? 0;
    }
    if (hasSortOrder(b.content)) {
      sortOrderB = b.content.sortOrder ?? 0;
    }

    return sortOrderB - sortOrderA;
  });
}

export const getTokenContainsIncludedService = ({
  offerByToken,
  tagsToKeep,
}: GetTokenContainsIncludedServiceProps) => {
  const { packages } = offerByToken?.products ?? {};

  if ((packages?.length ?? 0) <= 0) {
    return {
      containsIncludedService: false,
    };
  }

  const selectedPackage = packages?.find(({ tags }) => {
    const shouldKeep = tags
      .map((t) => tagsToKeep?.includes(t))
      ?.filter(Boolean)?.length;
    return shouldKeep;
  });

  if (!selectedPackage) {
    return {
      containsIncludedService: false,
    };
  }
  return {
    containsIncludedService: !selectedPackage?.optional,
  };
};

export const getTokenWithoutSelectedPackage = (
  selectedPackage: GetTokenWithoutSelectedPackageProps,
) => {
  const tokenWithoutSelectedServicePlan =
    selectedPackage?.appliedChange?.token?.short ?? '';

  return tokenWithoutSelectedServicePlan;
};

export const fetchPackages = async ({
  token,
  clientName,
  deployEnv,
}: GetPackagesTypes) => {
  return await getPackages({ token, clientName, deployEnv });
};

export const getServicesFromPackageSelections = ({
  offerByToken,
  tagsToKeep = [],
}: GetTagsFromPackageSelectionProps) => {
  const { packageSelections } = offerByToken?.configuration ?? {};

  if (!packageSelections || packageSelections?.length <= 0) {
    return [];
  }

  if (tagsToKeep?.length <= 0) {
    return packageSelections;
  }

  const servicesFromPackageSelections = packageSelections?.filter(
    ({ tags, content }) => {
      const shouldKeep = !!tags
        .map((t) => tagsToKeep?.includes(t))
        ?.filter(Boolean)?.length;
      return shouldKeep && !!content;
    },
  );

  return servicesFromPackageSelections;
};

export const getPackageFromProducts = ({
  offerByToken,
  idToKeep,
  tagsToKeep,
}: GetPackageFromProductsProps) => {
  const { packages } = offerByToken?.products ?? {};

  if (!packages || packages?.length <= 0) {
    return null;
  }

  const packageFromProducts = packages?.find(({ id, tags }) => {
    const shouldKeep =
      id === idToKeep ||
      tags.map((t) => tagsToKeep?.includes(t))?.filter(Boolean)?.length;
    return shouldKeep;
  });
  return packageFromProducts;
};

export const getSelectedService = ({
  servicesFromPackageSelections,
}: GetSelectedServiceProps) => {
  const selectedService = servicesFromPackageSelections?.find(
    ({ action }) => action === Action.REMOVE,
  );

  return selectedService;
};

export const getTrackingData = ({
  eventLabel,
  items,
  content,
  id,
}: GetTrackingDataProps) => {
  const getItems = items
    ?.map(({ type, content }) => [`${type}`, `${content?.displayName?.value}`])
    ?.flat()
    ?.join(' - ');

  const trackingData = {
    eventCategory: `add-ons | ${getItems}`,
    eventAction: 'button|click',
    eventLabel: `${eventLabel} | ${content?.displayName?.value} | ${id}`,
  };

  return trackingData;
};

export const getIsMultiOptionsAvailable = ({
  servicesFromPackageSelections,
}: GetIsMultiOptionsAvailableProps) =>
  (servicesFromPackageSelections?.length ?? 0) > 1;

export const getProductsTitleLabels = ({
  containsIncludedService,
  translate,
}: GetProductsTitleLabelsProps) => {
  return containsIncludedService
    ? {
        type: translate('AddOn.includedServices.type'),
        typeSources: ['sharedDict:AddOn.includedServices.type'],
        title: translate('AddOn.availableUpgrades.title'),
        titleSources: ['sharedDict:AddOn.availableUpgrades.title'],
      }
    : {
        type: translate('AddOn.availableUpgrades.type'),
        typeSources: ['sharedDict:AddOn.availableUpgrades.type'],
        title: translate('AddOn.includedServices.title'),
        titleSources: ['sharedDict:AddOn.includedServices.title'],
      };
};

export function getPackageData({
  selectedPackage,
  packageFromProduct,
}: GetPackageDataProps) {
  const { content, appliedChange, priceSummary, action, id } =
    selectedPackage ?? {};

  const description =
    content?.description?.value ??
    packageFromProduct?.content?.description?.value;

  const displayName =
    content?.displayName?.value ??
    packageFromProduct?.content?.displayName?.value;

  const thumbnail =
    content?.image?.url ?? packageFromProduct?.content?.images?.[0]?.url;

  const price =
    (action && action !== Action.REMOVE
      ? priceSummary?.price?.displayPrice?.display
      : packageFromProduct?.priceSummary?.price?.displayPrice?.display) ??
    undefined;

  const token = appliedChange?.token?.short;

  const optional =
    action === Action.REMOVE || (packageFromProduct?.optional ?? true);

  const { items = [] } = selectedPackage ?? packageFromProduct ?? {};

  return {
    action,
    appliedChange,
    description,
    displayName,
    id,
    items,
    optional,
    price,
    thumbnail,
    token,
  };
}
