import { FinanceDetailsView } from './views/finance-details.view';
import React from 'react';
import { FinanceDetailsWithoutTokenProps } from './finance-details.types';
import { useFinanceDetailsWithoutToken } from './hooks/finance-details.without-token.hook';

export const FinanceDetailsWithoutToken = ({
  onViewChange,
  customerType,
  salesModel,
  model,
  styles,
  testId,
}: FinanceDetailsWithoutTokenProps) => {
  const financeDetailsProps = useFinanceDetailsWithoutToken({
    salesModel,
    customerType,
    model,
    onViewChange,
  });
  return (
    <FinanceDetailsView
      testId={testId}
      styles={styles}
      {...financeDetailsProps}
    />
  );
};
