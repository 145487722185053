import { OfferQuery } from '../../../queries/fed-graph/entire-offer.query.gql-types';

export const getLastChange = ({
  fromState,
  toState,
}: {
  fromState: OfferQuery;
  toState: OfferQuery;
}) => {
  const token = toState?.offerByToken?.configuration?.token?.short;
  const previousToken =
    fromState?.offerByToken?.configuration?.token?.short ?? '';
  const lastAppliedChange =
    fromState?.offerByToken?.configuration?.selections?.find(
      (selection) => selection.appliedChange.token?.short === token,
    )?.appliedChange;
  return {
    added:
      lastAppliedChange?.selectionsAdded?.map(({ id: selectionId, type }) => {
        const matchingPackage = toState?.offerByToken?.products?.packages?.find(
          ({ id }) => id === selectionId,
        );
        return {
          id: selectionId,
          type,
          optional: matchingPackage?.optional,
          displayName: matchingPackage?.content?.displayName?.value ?? '',
        };
      }) ?? [],
    removed:
      lastAppliedChange?.selectionsRemoved?.map(({ id: selectionId, type }) => {
        const matchingPackage =
          fromState?.offerByToken?.products?.packages?.find(
            ({ id }) => id === selectionId,
          );
        return {
          id: selectionId,
          type,
          optional: matchingPackage?.optional,
          displayName: matchingPackage?.content?.displayName?.value ?? '',
        };
      }) ?? [],
    previousToken,
    previousSalesModel: fromState?.offerByToken?.salesModel?.identifier ?? '',
    salesModel: toState?.offerByToken?.salesModel?.identifier ?? '',
  };
};
