import { useMemo } from 'react';
import { UseEntireOfferDeliveryAdapterProps } from '../entire-offer.types';

export const useEntireOfferDeliveryAdapter = ({
  carData,
  isStockCar,
}: UseEntireOfferDeliveryAdapterProps) => {
  const delivery = useMemo(() => {
    if (isStockCar) {
      const [firstHit] = carData?.stockCars?.hits ?? [];
      const { order } = firstHit?.vehicle ?? {};
      return `${order?.estimatedCustomerDeliveryLeadTime ?? ''} ${
        order?.estimatedCustomerDeliveryLeadTimeUnitLocalized ?? ''
      }`;
    }
    return carData?.carByToken?.btoDeliveryLeadTimeToMarket?.formatted ?? '';
  }, [
    carData?.carByToken?.btoDeliveryLeadTimeToMarket?.formatted,
    carData?.stockCars?.hits,
    isStockCar,
  ]);

  return delivery;
};
