import React from 'react';
import { FinanceDetailsWithCustomDataProps } from './finance-details.types';
import { useFinanceDetailsWithCustomData } from './hooks/finance-details.custom.hook';
import { FinanceDetailsView } from './views/finance-details.view';

export const FinanceDetailsWithCustomData = ({
  onViewChange,
  financeDetails,
  styles,
  testId,
  translate,
}: FinanceDetailsWithCustomDataProps) => {
  const financeDetailsProps = useFinanceDetailsWithCustomData({
    financeDetails,
    onViewChange,
    styles,
    translate,
  });
  return (
    <FinanceDetailsView
      testId={testId}
      styles={styles}
      {...financeDetailsProps}
    />
  );
};
