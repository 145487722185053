'use client';
import React, { useEffect, useState } from 'react';
import { TrackingProvider } from '@volvo-cars/tracking';
import { useBreakpoints } from '@vcc-www/hooks';
import { Disclaimer } from '@vcc-package/text';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { type SalesModelId } from '@vcc-www/api/finance-options';
import { OverlayProvider as FinanceDetailsProvider } from '@vcc-package/overlays/DEPRECATED';
import { useQueries } from 'src/hooks/useQueries';
import { Spacing } from 'src/components/layout/Spacing';
import ServicesGrid from './src/finopt-comparison.view.services-grid';
import LinksGrid from './src/finopt-comparison.view.links-grid';
import SelectWrapper from './src/finopt-comparison.view.select-grid';
import AccordionSummary from './src/components/AccordionSummary';
import AccordionDetails from './src/components/AccordionDetails';
import { mergeOptions, mergeServices } from './src/finopt-comparison.utils';
import PaymentGrid from './src/finopt-comparison.view.payment-grid';
import HighlightsGrid from './src/finopt-comparison.view.highlights-grid';
import { type Option } from './src/finopt-comparison.types';

export interface FinanceOptionsComparisonPropsV2 {
  type: 'financeOptionsComparison' | 'financeOptionsComparisonV2';
  title?: string;
  electricLegend?: string;
  options: Option[];
  isExpanded?: boolean;
  firstOptionDefault?: string;
  secondOptionDefault?: string;
  thirdOptionDefault?: string;
  highlightsIsExpanded?: boolean;
  paymentIsExpanded?: boolean;
  servicesIsExpanded?: boolean;
  disclaimer?: string;
  highlightsLabel: string;
  paymentLabel: string;
}

export const FinanceOptionsComparisonV2 = ({
  title,
  electricLegend,
  options,
  highlightsIsExpanded,
  paymentIsExpanded,
  servicesIsExpanded,
  disclaimer,
  highlightsLabel,
  paymentLabel,
}: FinanceOptionsComparisonPropsV2) => {
  const { financeOptions } = useQueries();
  const mergedOptions = mergeOptions(financeOptions, options);
  // eslint-disable-next-line vcc-www/use-breakpoints
  const { untilL } = useBreakpoints();
  const translate = useSharedComponentsTranslate();

  const [firstSalesModel, setFirstSalesModel] = useState<SalesModelId | ''>(
    mergedOptions[0]?.salesModel ?? '',
  );
  const [secondSalesModel, setSecondSalesModel] = useState<SalesModelId | ''>(
    mergedOptions[1]?.salesModel ?? '',
  );
  const [thirdSalesModel, setThirdSalesModel] = useState<SalesModelId | ''>(
    mergedOptions[2]?.salesModel ?? '',
  );

  const optionsShown = mergedOptions.filter(({ salesModel }) =>
    [firstSalesModel, secondSalesModel, thirdSalesModel].includes(salesModel),
  );

  const mergedServices = mergeServices(optionsShown);

  // Removes the third option value and makes it available in the dropdown for
  // first and second options when switching to tablet and mobile
  useEffect(() => {
    if (mergedOptions.length >= 3) {
      if (untilL) {
        setThirdSalesModel('');
      } else {
        if (!thirdSalesModel) {
          setThirdSalesModel(
            mergedOptions.filter(
              ({ salesModel }) =>
                ![firstSalesModel, secondSalesModel].includes(salesModel),
            )[0]?.salesModel,
          );
        }
      }
    }
  }, [
    untilL,
    mergedOptions,
    firstSalesModel,
    secondSalesModel,
    thirdSalesModel,
  ]);

  if (mergedOptions.length < 2) {
    console.error(
      `Finance Options Comparison: Not enough Finance Options to compare. Available options: ${mergedOptions.length}`,
    );
    return null;
  }

  return (
    <TrackingProvider eventCategory="finance options comparison">
      <Spacing>
        <FinanceDetailsProvider>
          <div className="container" data-bleed="until-md">
            <div
              data-testid="finance-options-comparison"
              className="flex-col bg-secondary py-m px-24 lg:px-64"
            >
              {title && (
                <h2
                  data-testid="finance-options-comparison:title"
                  className="heading-2 mb-48 md:text-center"
                >
                  {title}
                </h2>
              )}

              <div className="flex mb-8">
                <SelectWrapper
                  salesModel={firstSalesModel}
                  setSelectState={setFirstSalesModel}
                  options={mergedOptions}
                  filterSelectArray={[secondSalesModel, thirdSalesModel]}
                />
                <SelectWrapper
                  salesModel={secondSalesModel}
                  setSelectState={setSecondSalesModel}
                  options={mergedOptions}
                  filterSelectArray={[firstSalesModel, thirdSalesModel]}
                />
                <SelectWrapper
                  salesModel={thirdSalesModel}
                  setSelectState={setThirdSalesModel}
                  options={mergedOptions}
                  filterSelectArray={[firstSalesModel, secondSalesModel]}
                />
              </div>

              <AccordionDetails
                label={highlightsLabel}
                defaultOpen={highlightsIsExpanded}
              >
                <AccordionSummary>{highlightsLabel}</AccordionSummary>
                <div>
                  <HighlightsGrid
                    options={mergedOptions}
                    firstSalesModel={firstSalesModel}
                    secondSalesModel={secondSalesModel}
                    thirdSalesModel={thirdSalesModel}
                  />
                </div>
              </AccordionDetails>

              <AccordionDetails
                label={paymentLabel}
                defaultOpen={paymentIsExpanded}
              >
                <AccordionSummary>{paymentLabel}</AccordionSummary>
                <div>
                  <PaymentGrid
                    options={mergedOptions}
                    firstSalesModel={firstSalesModel}
                    secondSalesModel={secondSalesModel}
                    thirdSalesModel={thirdSalesModel}
                  />
                </div>
              </AccordionDetails>

              <AccordionDetails
                label={translate('FinanceDetails.includedServices.title')}
                defaultOpen={servicesIsExpanded}
              >
                <AccordionSummary>
                  {translate('FinanceDetails.includedServices.title')}
                </AccordionSummary>
                <div>
                  <ServicesGrid
                    firstSalesModel={firstSalesModel}
                    secondSalesModel={secondSalesModel}
                    thirdSalesModel={thirdSalesModel}
                    services={mergedServices}
                    electricLegend={electricLegend}
                  />
                </div>
              </AccordionDetails>

              <LinksGrid
                options={mergedOptions}
                firstSalesModel={firstSalesModel}
                secondSalesModel={secondSalesModel}
                thirdSalesModel={thirdSalesModel}
              />
            </div>
            {disclaimer && (
              <div className="mt-32 px-24">
                <Disclaimer
                  data-testid="finance-options-comparison:disclaimer"
                  text={disclaimer}
                />
              </div>
            )}
          </div>
        </FinanceDetailsProvider>
      </Spacing>
    </TrackingProvider>
  );
};
