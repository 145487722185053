import gql from 'graphql-tag';
import { OFFER_CONFIGURATION_SELECTIONS_FRAGMENT } from './entire-offer.fragments.query';
export const OFFER_LISTING_QUERY = gql`
  query OfferListing($locale: String!, $market: String!) {
    offersByMarket(market: $market) {
      offers {
        salesModel {
          id
          content(locale: $locale) {
            displayName {
              label
              value
            }
          }
        }
        configuration {
          offerKey
          selections {
            ...OfferSelectionData
          }
        }
        carList {
          first {
            priceSummary {
              availableDownpayments {
                amount
                display
                selector
                source
                includingVat
                taxAmount
              }
            }
          }
        }
      }
    }
  }

  ${OFFER_CONFIGURATION_SELECTIONS_FRAGMENT}
`;
