'use client';
import React, { useEffect, useRef } from 'react';
import {
  CarPriceBreakdown,
  CleDistributionChannelTypeValue,
  PriceAmount,
} from '@vcc-www/federated-graph-types';
import { CUSTOMER_TYPE, ERROR_CONTACT_TEAMS } from '../offers.constants';
import { OffersContextType } from '../offer/offer.provider.types';
import { ErrorContactTeamsKeys, OfferProviderError } from '../offers.types';
import { EVENT_LABELS } from '../tracker/offers-tracking.types';
import {
  DownPayment,
  EntireOfferReturnType,
  EntireOfferSelectionType,
  EntireOfferSelectionTypeItemNumberValue,
  EntireOfferSelectionTypeItemStringValue,
  FormatApolloErrorProps,
  GetFinanceOutputProps,
  GetFormattedTaxesProps,
  GetSelectionByTypeProps,
  GetStockCarQueryFilterVariablesProps,
  IsCustomerTypeSupportedSalesModelProps,
  PRICE_BREAKDOWN_SECTIONS,
  Price,
  PriceBreakdownSections,
  Tax,
  UsePreviousType,
} from './entire-offer.types';

const CUSTOMER_TYPE_SELECTION = 'CustomerType';
const SALES_MODEL_SELECTION = 'SalesModel';
const MILEAGE_SELECTION = 'Mileage';
const CONTRACT_LENGTH_SELECTION = 'ContractLength';

export const isCustomerTypeSupportedSalesModel = ({
  selectedCustomerType,
  selectionsAdded,
  permittedSalesModels,
  selectedSalesModel,
}: IsCustomerTypeSupportedSalesModelProps) => {
  if (!selectedCustomerType) return false;

  const filteredAddedSelections =
    selectionsAdded
      ?.filter(
        (selection) =>
          selection.type === SALES_MODEL_SELECTION ||
          (selection.type === CUSTOMER_TYPE_SELECTION &&
            selection.id !== selectedCustomerType),
      )
      .filter((selection) => permittedSalesModels?.includes(selection.id)) ??
    [];

  const filteredSelectedSalesModel =
    permittedSalesModels?.includes(selectedSalesModel);

  return !!filteredAddedSelections.length || !!filteredSelectedSalesModel;
};

export const getSelectionsByType = ({
  selections,
  selectedCustomerType,
  selectedSalesModel,
  selectedContractLength,
  selectedContractLengthId,
  selectedMileage,
  selectedMileageId,
  labels,
  supportsVAT,
  permittedSalesModels,
}: GetSelectionByTypeProps): EntireOfferSelectionType | undefined => {
  // Add single selected items where selections are empty
  // - needed for Wholesale sales model that doesn't have 'selections'
  if (!selections?.length) {
    return {
      ...(selectedCustomerType
        ? {
            CustomerType: [
              {
                id: selectedCustomerType,
                title: selectedCustomerType,
                titleSources: ['data:selectedCustomerType'],
                value: selectedCustomerType,
              },
            ],
          }
        : {}),
      ...(selectedSalesModel
        ? {
            SalesModel: [
              {
                id: selectedSalesModel,
                title: selectedSalesModel,
                titleSources: ['data:selectedSalesModel'],
                value: selectedSalesModel,
              },
            ],
          }
        : {}),
    };
  }

  return selections?.reduce((prev: EntireOfferSelectionType, curr) => {
    const isValidSalesModel = isCustomerTypeSupportedSalesModel({
      selectedCustomerType,
      selectionsAdded: curr?.appliedChange?.selectionsAdded,
      selectedSalesModel: curr.identifier,
      permittedSalesModels,
    });

    return {
      ...prev,
      [String(curr.type)]: ((prev as any)[curr.type ?? ''] || []).concat({
        title:
          {
            CustomerType:
              curr?.identifier === 'B2B' ? labels?.business : labels?.personal,
            SalesModel: isValidSalesModel
              ? (curr.content as any)?.displayName?.value
              : '',
            ContractLength: (curr.content as any)?.formatted,
            Mileage: (curr.content as any)?.formatted,
          }[curr.type] ?? '',
        titleSources:
          {
            CustomerType:
              curr?.identifier === 'B2B'
                ? labels?.sources?.business
                : labels?.sources?.personal,
            SalesModel: isValidSalesModel ? ['data:displayName.value'] : [],
            ContractLength: ['data:content.formatted'],
            Mileage: ['data:content.formatted'],
          }[curr.type] ?? [],
        children:
          curr.type === SALES_MODEL_SELECTION ? (
            <span data-sources="data:shortDescription.value">
              {(curr.content as any)?.shortDescription?.value}
            </span>
          ) : (
            ''
          ),
        disclaimer: (curr?.content as any)?.disclaimer?.value,
        value: [CONTRACT_LENGTH_SELECTION, MILEAGE_SELECTION].includes(
          curr.type,
        )
          ? (curr.content as any)?.value
          : curr?.identifier,
        id: curr?.identifier,
        token: curr?.appliedChange?.token?.short,
        image: (curr?.content as any)?.image?.url,
        thumbnail: (curr?.content as any)?.thumbnail?.url,
        text: (curr?.content as any)?.formatted,
        textSources: ['data:content.formatted'],
        textLegend: '',
        priceAmount:
          (curr as any)?.priceSummary?.price?.displayPrice?.amount ?? '',
        secondaryInfo:
          curr.type === SALES_MODEL_SELECTION || curr.type === MILEAGE_SELECTION
            ? (curr as any)?.priceSummary?.price?.displayPrice?.display ?? ''
            : '',
        secondaryInfoSources:
          curr.type === SALES_MODEL_SELECTION || curr.type === MILEAGE_SELECTION
            ? ['data:priceSummary?.price?.displayPrice?.display']
            : '',
        vatLabel:
          curr.type === SALES_MODEL_SELECTION
            ? !supportsVAT
              ? labels?.exclTaxesAndFees
              : typeof (curr as any)?.priceSummary?.price?.displayPrice
                    ?.includingVat === 'boolean'
                ? (curr as any)?.priceSummary?.price?.displayPrice?.includingVat
                  ? labels?.inclVat
                  : labels?.exclVat
                : ''
            : curr.type === MILEAGE_SELECTION
              ? (curr as any)?.priceSummary?.price?.displayPrice?.includingVat
                ? labels?.inclVat
                : labels?.exclVat
              : '',
        vatLabelSources:
          curr.type === SALES_MODEL_SELECTION
            ? !supportsVAT
              ? labels?.sources?.exclTaxesAndFees
              : typeof (curr as any)?.priceSummary?.price?.displayPrice
                    ?.includingVat === 'boolean'
                ? (curr as any)?.priceSummary?.price?.displayPrice?.includingVat
                  ? labels?.sources?.inclVat
                  : labels?.sources?.exclVat
                : []
            : curr.type === MILEAGE_SELECTION
              ? (curr as any)?.priceSummary?.price?.displayPrice?.includingVat
                ? labels?.sources?.inclVat
                : labels?.sources?.exclVat
              : [],
        selectionsAdded: curr?.appliedChange?.selectionsAdded?.map(
          ({ id }) => id,
        ),
        selectionsRemoved: curr?.appliedChange?.selectionsRemoved?.map(
          ({ id }) => id,
        ),
        isActive:
          curr.type === CUSTOMER_TYPE_SELECTION
            ? selectedCustomerType === curr.identifier
            : curr.type === SALES_MODEL_SELECTION && selectedSalesModel
              ? selectedSalesModel === curr.identifier
              : curr.type === CONTRACT_LENGTH_SELECTION
                ? selectedContractLengthId
                  ? selectedContractLengthId === curr.identifier
                  : selectedContractLength
                    ? selectedContractLength === (curr.content as any)?.value
                    : false
                : curr.type === MILEAGE_SELECTION // TODO: similar logic to ContractLength above - merge these
                  ? selectedMileageId
                    ? selectedMileageId === curr.identifier
                    : selectedMileage
                      ? selectedMileage === (curr.content as any)?.value
                      : !prev?.[curr.type]?.length
                        ? true
                        : false
                  : false,
      }),
    };
  }, {});
};

export const getStockCarQueryFilterVariables = ({
  isPrivateStockCar,
  token,
}: GetStockCarQueryFilterVariablesProps) => ({
  excludeDuplicates: true,
  includeUnavailable: !!isPrivateStockCar,
  filter: {
    value: {
      id: {
        value: [
          {
            value: token,
          },
        ],
      },
      distributionChannelType: {
        value: [
          {
            value: CleDistributionChannelTypeValue.SubscriptionCar,
          },
        ],
      },
    },
  },
});

export const getSelectionsForTypeList = (
  typeList:
    | (
        | (
            | EntireOfferSelectionTypeItemStringValue
            | EntireOfferSelectionTypeItemNumberValue
          )[]
        | undefined
      )
    | undefined,
  fieldName: string,
):
  | (
      | EntireOfferSelectionTypeItemStringValue
      | EntireOfferSelectionTypeItemNumberValue
    )[]
  | undefined => {
  if (typeList?.length === 1) {
    const output = [{ ...typeList?.[0], isActive: true }];
    return ['customerType', 'salesModel'].includes(fieldName)
      ? (output as EntireOfferSelectionTypeItemStringValue[] | undefined)
      : (output as EntireOfferSelectionTypeItemNumberValue[] | undefined);
  }

  if (
    (typeList?.length ?? 0) > 1 &&
    !typeList?.find((typeListItem) => typeListItem.isActive)
  ) {
    const [firstItem, ...rest] = typeList ?? [];
    const output = [{ ...firstItem, isActive: true }, ...rest];
    return ['customerType', 'salesModel'].includes(fieldName)
      ? (output as EntireOfferSelectionTypeItemStringValue[] | undefined)
      : (output as EntireOfferSelectionTypeItemNumberValue[] | undefined);
  }

  return typeList ?? [];
};

export const getSelectedItem = (
  items:
    | (
        | EntireOfferSelectionTypeItemStringValue
        | EntireOfferSelectionTypeItemNumberValue
      )[]
    | undefined,
  selectedItem:
    | OffersContextType['customerType']
    | OffersContextType['salesModel']
    | OffersContextType['contractLength']
    | OffersContextType['mileage']
    | OffersContextType['downPayment'],
) => {
  return items?.length
    ? items?.find(
        (item: any) => item.id === selectedItem || item.value === selectedItem,
      ) ?? items?.[0]
    : selectedItem
      ? {
          id: selectedItem,
          title: selectedItem,
          titleSources: [`data:selectedItem`],
          value: selectedItem,
        }
      : {};
};

export const getSortedCustomerTypes = (
  customerTypes: EntireOfferSelectionTypeItemStringValue[],
) => {
  return customerTypes?.sort((a: any) => (a.id === 'B2C' ? -1 : 1));
};

export const getFinanceOutput = ({
  availableDownPayments,
  selections,
  salesModelSortOrder,
  customerType: selectedCustomerType,
  salesModel: selectedSalesModel,
  contractLength: selectedContractLength,
  mileage: selectedMileage,
  contractLengthId: selectedContractLengthId,
  mileageId: selectedMileageId,
  downPayment: desiredDownPayment,
  token,
  labels,
  supportsVAT,
  priceSummary,
  financingPriceSummary,
  partExchange,
  showSingleContractLength,
  permittedSalesModels,
}: GetFinanceOutputProps): NonNullable<EntireOfferReturnType['finance']> => {
  const selectionsByType = getSelectionsByType({
    selections,
    selectedCustomerType,
    selectedSalesModel,
    selectedContractLength,
    selectedContractLengthId,
    selectedMileage,
    selectedMileageId,
    labels,
    supportsVAT,
    permittedSalesModels,
  });
  const customerTypesForDisplay = selectionsByType?.CustomerType ?? [];
  const sortedCustomerTypes = getSortedCustomerTypes(customerTypesForDisplay);
  const salesModels = selectionsByType?.SalesModel?.filter(
    (salesModel) => !!salesModel?.title,
  )?.sort(
    (a, b) =>
      (salesModelSortOrder?.indexOf(a.id ?? '') ?? 0) -
      (salesModelSortOrder?.indexOf(b.id ?? '') ?? 0),
  );

  const contractLengths = selectionsByType?.ContractLength;
  const mileages = selectionsByType?.Mileage;
  const customerType = getSelectedItem(
    sortedCustomerTypes,
    selectedCustomerType?.toLocaleUpperCase(),
  );

  const normalisedSelectedSalesModel = selectedSalesModel?.toLocaleUpperCase();
  const salesModel = getSelectedItem(salesModels, normalisedSelectedSalesModel);
  const hasSingleContractLengthOption = (contractLengths?.length ?? 0) < 2;
  const contractLength =
    !showSingleContractLength && hasSingleContractLengthOption
      ? undefined
      : getSelectedItem(
          contractLengths,
          selectedContractLengthId
            ? selectedContractLengthId
            : selectedContractLength,
        );

  const mileageFromSelections = getSelectedItem(
    mileages,
    selectedMileageId ? selectedMileageId : selectedMileage,
  );

  const mileageWithCorrectPrice = {
    ...mileageFromSelections,
    secondaryInfo: priceSummary?.mileagePrice?.displayPrice?.display ?? '',
    priceAmount: priceSummary?.mileagePrice?.displayPrice?.amount ?? '',
  };

  const {
    display: offerDrawerPrice,
    source: offerDrawerPriceSource,
    amount: offerDrawerPriceValue,
  } = financingPriceSummary?.breakdown?.perInstallmentPrice ??
  priceSummary?.price?.displayPrice ??
  selections?.find(
    (selection) => selection?.appliedChange?.token?.short === token,
  )?.priceSummary?.price?.displayPrice ??
  {};

  const offerDrawerPriceSources = offerDrawerPriceSource
    ? [`data:${offerDrawerPriceSource}`]
    : [];

  const downPaymentAmountObj = financingPriceSummary?.breakdown
    ? financingPriceSummary?.breakdown?.downpayment?.amount
    : priceSummary?.payments?.find(
        (payment) => payment.paymentType === 'downpayment',
      )?.amount;
  const selectedDownPayment = getSelectedDownPayment(
    availableDownPayments,
    desiredDownPayment,
  );
  const downPaymentAmount =
    selectedCustomerType === CUSTOMER_TYPE.B2B
      ? downPaymentAmountObj?.priceInclVat?.amount
      : downPaymentAmountObj?.displayPrice?.amount;
  const downPaymentExclVATAmount =
    downPaymentAmountObj?.displayPrice?.display ?? '';

  const downPayments = getDownPayments({
    availableDownPayments,
    selectedDownPayment: downPaymentAmount ?? selectedDownPayment,
    priceSummary,
    financingPriceSummary,
  });

  const downPayment = getSelectedItem(
    downPayments,
    downPaymentAmount ?? selectedDownPayment,
  );

  return {
    selectedOffer: {
      customerType: customerType as EntireOfferSelectionTypeItemStringValue,
      salesModel: salesModel as EntireOfferSelectionTypeItemStringValue,
      contractLength: contractLength as EntireOfferSelectionTypeItemNumberValue,
      mileage:
        mileageWithCorrectPrice as EntireOfferSelectionTypeItemNumberValue,
      downPayment: downPayment as EntireOfferSelectionTypeItemNumberValue,
      partExchange: {
        value: Boolean(partExchange),
      },
      token,
    },
    customerTypes: getSelectionsForTypeList(
      sortedCustomerTypes,
      'customerType',
    ) as EntireOfferSelectionTypeItemStringValue[],
    salesModels: getSelectionsForTypeList(
      salesModels,
      'salesModel',
    ) as EntireOfferSelectionTypeItemStringValue[],
    contractLengths: getSelectionsForTypeList(
      contractLengths,
      'contractLength',
    ) as EntireOfferSelectionTypeItemNumberValue[],
    mileages: getSelectionsForTypeList(
      mileages,
      'mileage',
    ) as EntireOfferSelectionTypeItemNumberValue[],
    downPayments: getSelectionsForTypeList(
      downPayments,
      'downPayment',
    ) as EntireOfferSelectionTypeItemNumberValue[],
    offerDrawerPrice,
    offerDrawerPriceValue: offerDrawerPriceValue ?? 0,
    downPaymentExclVATAmount,
    offerDrawerPriceSources: offerDrawerPriceSources ?? [],
  };
};

const getSelectedDownPayment = (
  availableDownPayments: DownPayment[],
  desiredDownPayment: number | null | undefined,
) => {
  const maxDownPaymentIndex = (availableDownPayments?.length ?? 0) - 1;
  const maxDownPaymentAmount =
    availableDownPayments?.[maxDownPaymentIndex]?.amount ?? 0;
  return (desiredDownPayment ?? 0) > maxDownPaymentAmount
    ? maxDownPaymentAmount
    : desiredDownPayment;
};

export const getDownPayments = ({
  availableDownPayments,
  selectedDownPayment,
  priceSummary,
  financingPriceSummary,
}: {
  availableDownPayments: DownPayment[];
  selectedDownPayment: number | null | undefined;
  priceSummary: GetFinanceOutputProps['priceSummary'];
  financingPriceSummary: GetFinanceOutputProps['financingPriceSummary'];
}): EntireOfferSelectionTypeItemNumberValue[] | undefined => {
  const downPayments =
    availableDownPayments?.map(
      (downPayment): EntireOfferSelectionTypeItemNumberValue => ({
        id: `downPayment-${downPayment.amount}`,
        title: downPayment.display,
        titleSources: ['data:downPayment.display'],
        text: downPayment.display,
        textSources: ['data:downPayment.display'],
        value: downPayment.amount,
        isActive: downPayment.amount === selectedDownPayment,
      }),
    ) ?? [];
  if (downPayments.findIndex(({ isActive }) => isActive) < 0) {
    const lowerBound = downPayments?.[0]?.value ?? 0;
    const upperBound =
      downPayments?.[downPayments?.length - 1]?.value ?? Infinity;
    if (
      (selectedDownPayment ?? 0) > lowerBound &&
      (selectedDownPayment ?? 0) < upperBound
    ) {
      const newDownPaymentIndex = downPayments.findIndex(
        (downPayment) => (downPayment.value ?? 0) > (selectedDownPayment ?? 0),
      );
      const downPaymentText = financingPriceSummary?.breakdown?.downpayment
        ? financingPriceSummary?.breakdown?.downpayment?.amount?.displayPrice
            ?.display ?? ''
        : priceSummary?.payments?.find(
            (payment) => payment.paymentType === 'downpayment',
          )?.amount?.priceInclVat?.display;
      const newDownPayment = {
        ...downPayments?.[newDownPaymentIndex],
        id: `downPayment-${selectedDownPayment}`,
        title: downPaymentText,
        text: downPaymentText,
        value: selectedDownPayment,
        isActive: true,
      };

      downPayments.splice(newDownPaymentIndex, 0, newDownPayment);
    }
  }
  return downPayments;
};

const filterOutPricesWithNoIncrease = (
  priceBreakDown: CarPriceBreakdown['all'],
) => {
  if (!priceBreakDown) {
    return null;
  }
  return priceBreakDown?.filter(
    // Filter out prices with no price increase
    (priceObject) => !!priceObject?.price?.amount,
  );
};

export const getDisplayPriceByCode = (
  priceBreakDown: CarPriceBreakdown['all'],
  code?: string,
) => {
  const prices = filterOutPricesWithNoIncrease(priceBreakDown);
  const price =
    prices?.find((priceObject) => priceObject?.code === code)?.price ?? null;

  return price
    ? {
        price: price?.amount,
        priceText: price?.display ?? '',
        priceTextSources: [`data:price[${code}].display`],
      }
    : null;
};

export const kebabToCamelCase = (kebabCaseString: string) => {
  return kebabCaseString?.replace(/(-[a-z])/g, (char) =>
    char?.replace('-', '')?.toUpperCase(),
  );
};

export const getTransactionBaseTaxes = (
  transactionBaseTaxes: any,
  isPriceExcludingVAT: boolean,
): Array<Tax> =>
  transactionBaseTaxes
    ?.filter((tax: any) => (tax.pretaxWithTaxes?.amount ?? 0) !== 0)
    .filter((tax: any) => tax.id !== 'vat')
    .map((tax: any) => {
      const price = isPriceExcludingVAT
        ? tax?.pretaxWithTaxesExclVat
        : tax?.pretaxWithTaxes;
      return {
        price: { amount: price?.amount ?? 0, display: price?.display ?? '' },
        name: tax?.id ?? '',
        taxId: String(tax?.taxId ?? ''),
      };
    })
    .sort(
      (a: any, b: any) => (b?.price?.amount || 0) - (a?.price?.amount || 0),
    ) ?? [];

export const getAcquisitionalTaxes = (acquisitionTaxes: any): Array<Tax> =>
  acquisitionTaxes
    ?.filter((tax: any) => (tax.price?.amount ?? 0) !== 0)
    ?.filter((tax: any) => tax?.vatPosition?.toLowerCase() !== 'vat')
    .map((tax: any) => {
      return {
        price: {
          amount: tax?.price?.amount ?? 0,
          display: tax?.price?.display ?? '',
        },
        priceText: tax?.price?.display ?? '',
        name: tax?.name ?? '',
        taxId: String(tax?.taxId ?? ''),
      };
    })
    .sort(
      (a: any, b: any) => (b?.price?.amount || 0) - (a?.price?.amount || 0),
    ) ?? [];

export const getTotalPriceWithTax = (priceSummary: any) => {
  const priceInclTax = priceSummary?.all?.priceInclTax;
  return {
    price: priceInclTax?.amount ?? 0,
    priceText: priceInclTax?.display ?? '',
  };
};

export const getIncentiveTaxes = (informationalTaxes: any): Array<Tax> => {
  return (informationalTaxes || [])
    ?.filter(
      (tax: any) =>
        (tax?.price?.amount ?? 0) !== 0 && tax.tags?.includes('Incentive'),
    )
    ?.map((incentive: any) => {
      return {
        price: {
          amount: incentive?.price?.amount ?? 0,
          display: incentive?.price?.display ?? '',
        },
        name: incentive?.name ?? '',
      };
    });
};

export const getOwnershipTaxes = (ownershipTaxes: any): Array<Tax> => {
  return (ownershipTaxes || [])
    ?.filter((tax: any) => (tax?.price?.amount ?? 0) !== 0)
    ?.map((ownership: any) => {
      return {
        price: {
          amount: ownership?.price?.amount ?? 0,
          display: ownership?.price?.display ?? '',
        },
        name: ownership?.name ?? '',
      };
    });
};

export const getPrice = (
  price: Pick<PriceAmount, 'amount' | 'display'>,
): Price => ({
  amount: price?.amount ?? 0,
  display: price?.display ?? '',
});

export const getFormattedTaxes = ({
  taxes,
  testId,
}: GetFormattedTaxesProps) => {
  return taxes?.map((tax) => ({
    displayName: tax?.name,
    displayNameSources: ['data:tax.name'],
    price: tax?.price?.amount,
    priceText: tax?.price?.display,
    priceTextSources: ['data:tax.price.display'],
    testId: `${testId}-tax-${tax?.taxId}`,
  }));
};

export const mapSectionTypeToEventLabel = (
  sectionType: PriceBreakdownSections,
) => {
  switch (sectionType) {
    case PRICE_BREAKDOWN_SECTIONS.BEFORE_DELIVERY:
      return EVENT_LABELS.DUE_BEFORE_DELIVERY;
    case PRICE_BREAKDOWN_SECTIONS.MONTHLY:
      return EVENT_LABELS.DUE_MONTHLY;
    default:
      return EVENT_LABELS.DUE_TODAY;
  }
};

export const usePrevious: UsePreviousType = (value) => {
  const ref = useRef<typeof value>();
  useEffect(() => {
    if (typeof value !== 'undefined') {
      ref.current = value;
    }
  });
  return ref.current;
};

export const formatApolloError = ({
  apolloError,
  variables,
  query,
}: FormatApolloErrorProps): OfferProviderError[] => {
  const [firstDef] = (query?.definitions as any) ?? [];
  const queryName = firstDef?.name?.value ?? '';

  let errors = apolloError?.graphQLErrors?.map(
    ({ extensions, message, path = [] }): OfferProviderError => {
      const { clientId, code, response, request } = extensions ?? {};

      return {
        clientId,
        code,
        message,
        paths: [...path],
        statusCode: response?.status,
        url: request?.url,
        body: request?.body,
        pageUrl: window?.location?.href,
        variables,
        queryName,
        contact: ERROR_CONTACT_TEAMS[clientId as ErrorContactTeamsKeys],
      };
    },
  );

  if (!errors?.length) {
    const { name, result, response, statusCode } =
      (apolloError?.networkError as any) ?? {};
    const url = response?.url ?? '';

    errors = result?.errors?.map((error: any) => ({
      message: error?.message,
      clientId: name,
      code: error?.extensions?.code,
      paths: [error?.extensions?.name],
      statusCode,
      url,
      pageUrl: window?.location?.href,
      body: {},
      variables,
      queryName,
      contact: ERROR_CONTACT_TEAMS.OfferSelector, // TODO: change when we have more information from bad tokens
    }));
  }
  return errors;
};

export const getCarExpandedDisplayNameAndModelYear = (
  carDisplayName: string,
  modelYear: string,
) => {
  return `${carDisplayName} (${modelYear})`;
};
