import { getCookie } from '@vcc-package/storage/cookies';
import { getOfferSelectorAPIEndpointHeaders } from '../utils';
import { SYSTEM } from '@vcc-package/preview/src/preview.constants';
import {
  DeployEnv,
  LOG_SERVICES,
  getDistributionChannel,
  log,
} from '@vcc-package/offers-utils';
import type {
  BenefitInKindProps,
  FleetProviderContextType,
  FleetSessionAttributesToUpdateType,
  FleetSessionFetchInputType,
  GetBenefitInKindProps,
  GetBenefitInKindTitleProps,
  GetCceEnvironmentProps,
  GetDeployEnvProps,
  GetFleetSessionAttributesToUpdateProps,
  GetFleetSessionFetchInputProps,
  GetGpeSourceProps,
  UpdateFleetSessionProps,
} from './offers-provider.fleet-session.types';
import {
  GPE_ENVIRONMENT_PROD,
  type CCEEnvironment,
  type GPEEnvironment,
  GPE_ENVIRONMENT_STAGING,
} from '@vcc-package/benefit-in-kind';
import type { Session } from '@vcc-www/auth-client/session-service/types';

export const initialFleetSessionData: Session = {
  sessionId: '',
  createdAt: 0,
  expiresAt: 0,
  modifiedAt: 0,
  auth: [],
  sharedData: {},
  privateData: {},
};

export const getFleetSessionFetchInput = ({
  clientName,
  regionCode,
  enableEditConfiguration,
}: GetFleetSessionFetchInputProps): FleetSessionFetchInputType => ({
  clientName,
  regionCode,
  enableEditConfiguration,
  // sessionId is for testing purposes only, the real cookie value is read server-side
  // as part of /api/order/session and will only be populated here when the cookie has
  // been set manually for testing purposes (the real cookie is HttpOnly so can't be
  // read client-side)
  ...(getCookie('vcc-session')
    ? {
        sessionId: getCookie('vcc-session') ?? '',
      }
    : {}),
});

export const updateFleetSession = async ({
  setIsLoading,
  domain,
  clientName,
  fleetSessionFetchInput,
  deployEnv,
  testwwwHeader,
  qawwwHeader,
  setFleetSessionData,
  setIsLoaded,
}: UpdateFleetSessionProps) => {
  setIsLoading(true);
  const endpoint = `${domain}/api/order/session`;

  try {
    const fleetSessionData = await fetch(endpoint, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(fleetSessionFetchInput),
      headers: getOfferSelectorAPIEndpointHeaders({
        deployEnv,
        testwwwHeader,
        qawwwHeader,
        clientName,
      }),
    });

    if (fleetSessionData.ok) {
      const session = await fleetSessionData.json();
      setFleetSessionData(session);
    }
  } catch (error) {
    log.error({
      service: LOG_SERVICES.SHARED_OFFERS_PROVIDER_UPDATE_FLEET_SESSION,
      message: (error as Error).message,
      clientName,
    });
  } finally {
    setIsLoading(false);
    setIsLoaded(true);
  }
};

export const getDeployEnv = ({
  previewState,
  deployEnv,
}: GetDeployEnvProps): DeployEnv => {
  return (String(
    previewState?.overrideSources?.[SYSTEM.SESSION_SERVICE] ?? '',
  ) || deployEnv) as DeployEnv;
};

export const getFleetSessionAttributesToUpdate = ({
  fleetSessionType,
  fleetPersona,
  isFleetKeyAccount,
}: GetFleetSessionAttributesToUpdateProps): FleetSessionAttributesToUpdateType => ({
  sessionType: fleetSessionType,
  fleetRole: fleetPersona || '',
  fleetIsKeyAccount: isFleetKeyAccount,
});

export const getDefaultFleetSessionValues = (): FleetProviderContextType => {
  return {
    session: initialFleetSessionData,
    isLoading: false,
    onUpdateFleetSession: (): Promise<void> => {
      throw new Error('Function not implemented.');
    },
    benefitInKind: {
      enableBenefitInKind: false,
      benefitInKindProps: {
        regionCode: '',
        locale: '',
        token: '',
        salesModelName: '',
        handleOpen: () => {},
      },
      benefitInKindTitle: '',
    },
  };
};

const getGpeSource = ({ isQAHost }: GetGpeSourceProps): GPEEnvironment => {
  return isQAHost ? GPE_ENVIRONMENT_STAGING : GPE_ENVIRONMENT_PROD;
};

const getCceEnvironment = ({
  isQAHost,
}: GetCceEnvironmentProps): CCEEnvironment => {
  return isQAHost ? 'staging' : 'prod';
};

export const getBenefitInKindProps = ({
  isQAHost,
  token,
  regionCode,
  locale,
  salesModel,
  isDirectSales,
  isStockCar = false,
  openBenefitInKindModal,
  enableBenefitInKind,
}: GetBenefitInKindProps): BenefitInKindProps => {
  //TODO: remove false when URAX got a fix for QA
  const gpeSource = getGpeSource({ isQAHost: false });
  const cceEnvironment = getCceEnvironment({ isQAHost });

  return {
    cceEnvironment,
    distributionChannelType: getDistributionChannel({
      isDirectSales,
      isStockCar,
    }),
    gpeSource,
    handleOpen: openBenefitInKindModal ?? (() => {}),
    isStockCar,
    locale: locale ?? '',
    regionCode,
    salesModelName: salesModel?.toLowerCase() ?? '',
    skip: !enableBenefitInKind,
    token: enableBenefitInKind && token ? token : '',
  };
};

export const getBenefitInKindTitle = ({
  translate,
}: GetBenefitInKindTitleProps) => {
  return translate('BenefitInKind.benefitInKind.header') || '';
};
