import React from 'react';
import styles from './PromotionDisplay.module.css';
import { cssJoin } from '@volvo-cars/css/utils';
import { ResponsiveImage } from '@vcc-package/media';
import type promotionContent from '../../content-management/content-types/PromotionContent.props';
import {
  Track,
  TrackingProvider,
  type GenericValue,
  type TrackProps,
} from '@volvo-cars/tracking';

interface Props extends promotionContent {
  className?: string;
  children?: React.ReactNode;
  trackingOptions?: {
    eventCategory?: GenericValue;
    cta: TrackProps; // Extend cta to match TrackProps type
  };
}

export const PromotionDisplay = ({
  title,
  body,
  cta,
  responsiveImages,
  theme = 'dark',
  flipped = false,
  className,
  children,
  trackingOptions,
}: Props) => {
  const eventCategory = trackingOptions?.eventCategory || 'Promotion Display';

  const ctaTrackingProps: TrackProps = {
    eventLabel: 'Promotion Display CTA',
    eventAction: 'button|click',
    ...trackingOptions?.cta,
  };

  return (
    <TrackingProvider eventCategory={eventCategory}>
      <div
        className={cssJoin(
          'relative aspect-4/5 md:aspect-16/9 lg:aspect-21/9',
          className,
        )}
        data-color-mode={theme}
      >
        <ResponsiveImage
          className="w-full aspect-4/5 md:aspect-16/9 lg:aspect-21/9"
          images={responsiveImages}
          alt={responsiveImages.sm.alt}
        />
        <div
          className={cssJoin(
            'absolute top-0 start-0 h-full w-full',
            flipped && 'md:flex-row md:justify-end',
          )}
        >
          <div
            className={cssJoin(
              'h-full w-full flex-col justify-between md:justify-center md:max-w-1/2 lg:px-48',
              flipped ? 'md:pr-32' : 'md:pl-32',
              styles['md:gradient'],
              flipped
                ? styles['md:gradient-right']
                : styles['md:gradient-left'],
            )}
          >
            <div className={cssJoin('lg:px-48', styles['gradient'])}>
              <div className="stack-text px-24 py-64 md:px-0 md:py-0">
                <h2>{title}</h2>
                {body && <p>{body}</p>}
                {children}
              </div>
            </div>
            <div className="pb-24 md:pb-0 px-24 md:px-0 lg:px-48">
              <Track {...ctaTrackingProps}>
                <a
                  className="button-filled w-full md:w-fit md:mt-24"
                  href={cta.href}
                >
                  {cta.text}
                </a>
              </Track>
            </div>
          </div>
        </div>
      </div>
    </TrackingProvider>
  );
};
