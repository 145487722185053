import gql from 'graphql-tag';

const FINANCE_DETAILS_SERVICES = gql`
  fragment FinanceDetailsServices on Products {
    sortedServices(locale: $locale) {
      content(locale: $locale) {
        displayName {
          value
        }
        shortDescription {
          value
        }
        description {
          value
        }
        image {
          label
          url
        }
        thumbnail {
          label
          url
        }
        disclaimer {
          value
        }
      }
    }
  }
`;

const FINANCE_DETAILS_PACKAGES = gql`
  fragment FinanceDetailsPackages on Products {
    packages {
      optional
      content(locale: $locale) {
        displayName {
          value
        }
        shortDescription {
          value
        }
        description {
          value
        }
        image {
          label
          url
        }
        thumbnail {
          label
          url
        }
      }
      items {
        content(locale: $locale) {
          displayName {
            value
          }
          shortDescription {
            value
          }
          description {
            value
          }
          image {
            label
            url
          }
          thumbnail {
            label
            url
          }
          ... on ServiceContent {
            sortOrder
            sellingPoints {
              title {
                value
              }
            }
          }
        }
      }
    }
  }
`;

const FINANCE_DETAILS_SALES_MODEL_CONTENT = gql`
  fragment FinanceDetailsSalesModelContent on SalesModel {
    content(locale: $locale) {
      displayName {
        value
      }
      description {
        value
      }
      disclaimer {
        value
      }
      image {
        url
      }
      sellingPoints {
        value
      }
      howItWorks {
        title {
          value
        }
        shortDescription {
          value
        }
        image {
          url
        }
        video {
          url
        }
      }
      thumbnail {
        url
      }
    }
  }
`;

export const FINANCE_DETAILS_DATA_QUERY = gql`
  query FinanceDetailsData($token: String!, $locale: String!) {
    offerByToken(token: $token) {
      salesModel {
        ...FinanceDetailsSalesModelContent
      }
      products {
        ...FinanceDetailsServices
        ...FinanceDetailsPackages
      }
    }
  }
  ${FINANCE_DETAILS_SERVICES}
  ${FINANCE_DETAILS_PACKAGES}
  ${FINANCE_DETAILS_SALES_MODEL_CONTENT}
`;
