import React from 'react';
import { TextInput } from 'vcc-ui';
import { TitleWithSubTitle } from './components/TitleWithSubTitle';
import { usePartExchange, usePartExchangeTool } from './part-exchange.hook';
import { isValidNumber, isValidRegNumber } from './part-exchange.utils';
import { useTranslate } from './part-exchange.dictionaries-provider';
import { ErrorView } from './components/ErrorView';

export const CarInputView: React.FC<{
  carInputError?: boolean;
}> = ({ carInputError }) => {
  const { setRegNumber, registrationNumber, mileage, setMileage, error } =
    usePartExchange();
  const { saveItemToLocalStorage } = usePartExchangeTool();

  const translate = useTranslate();

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'mileage') {
      setMileage(value);
      saveItemToLocalStorage('mileage', value);
    } else if (name === 'regNumber') {
      setRegNumber(value);
      saveItemToLocalStorage('regNumber', value);
    }
  };

  if (error) {
    return <ErrorView title={error.title} body={error.body} />;
  }

  return (
    <div data-testid="part-exchange-tool:car-input-view">
      <TitleWithSubTitle
        title={translate('financeOptions.partExchangeTool.carInputTitle')}
        subTitle={translate('financeOptions.partExchangeTool.carInputSubTitle')}
        body={translate('financeOptions.partExchangeTool.carInputBody')}
      />
      <div>
        <InputTitle>
          {translate('financeOptions.partExchangeTool.carInputRegNr')}
        </InputTitle>
        <TextInput
          data-testid="part-exchange-tool:car-input-view-reg-number-input"
          label={translate(
            'financeOptions.partExchangeTool.carInputRegNrLabel',
          )}
          value={registrationNumber}
          isValid={
            registrationNumber.match(isValidRegNumber) !== null ||
            registrationNumber === ''
          }
          errorMessage={
            carInputError
              ? `${translate(
                  'financeOptions.partExchangeTool.carInputRegNrErrorMsg',
                )}`
              : ''
          }
          name="regNumber"
          onChange={handleInput}
        />
      </div>
      <div className="mt-24">
        <InputTitle>
          {translate('financeOptions.partExchangeTool.carInputMileage')}
        </InputTitle>
        <TextInput
          data-testid="part-exchange-tool:car-input-view-mileage-input"
          label={translate(
            'financeOptions.partExchangeTool.carInputMileageLabel',
          )}
          value={mileage}
          isValid={isValidNumber(mileage) || mileage === '' ? true : false}
          errorMessage={
            isValidNumber(mileage) || mileage === ''
              ? ''
              : `${translate(
                  'financeOptions.partExchangeTool.carInputMileageErrorMsg',
                )}`
          }
          name="mileage"
          onChange={handleInput}
        />
      </div>
    </div>
  );
};

const InputTitle: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <p
    data-testid="part-exchange-tool:car-input-view-input-title"
    className="font-16 font-medium mb-8"
  >
    {props.children}
  </p>
);
