import { CleDistributionChannelTypeValue } from '@vcc-www/federated-graph-types';
import { GetDistributionChannelProps } from './distribution-channel.types';

export const getDistributionChannel = ({
  isDirectSales,
  isStockCar,
}: GetDistributionChannelProps): CleDistributionChannelTypeValue =>
  !isDirectSales && isStockCar
    ? CleDistributionChannelTypeValue.StockCar
    : CleDistributionChannelTypeValue.SubscriptionCar;
