import { useCallback, useEffect, useMemo, useState } from 'react';
import { createTranslate } from '@vcc-www/utils/stringTemplate';
import {
  ContentContextType,
  ContentInputProps,
} from './offers-provider.content.types';
import { isValidLocale, getEnv } from '@vcc-package/offers-utils';
import {
  getContentCache,
  getContentEndpoint,
  getContentEndpointV2,
  getCtasBySourceId,
  getDisclaimers,
  getDisclaimersBySourceId,
  getFetchInput,
  getOrderedDisclaimers,
  getProdSystemDefaults,
  getQueryParams,
  getSettingBySourceId,
  getTermsProxyExperienceDisclaimers,
  updateContent,
} from './offers-provider.content.utils';
import { useSettings } from '../settings/offers-provider.settings.provider';
import { getOfferSelectorAPIEndpointHeaders } from '../utils';

export const useOffersProviderContent = ({
  clientName,
  initialContent,
  locale,
  market,
  deployEnv,
  qawwwHeader,
  testwwwHeader,
  previewState,
  salesModel,
  customerType,
}: ContentInputProps): ContentContextType => {
  const {
    enableDisclaimerMain,
    enableDisclaimerIndicativePrice,
    enableV2ApiEndpoints,
    enableDisclaimerContentApi,
  } = useSettings();
  const [loading, setLoading] =
    useState<ContentContextType['loading']>(!initialContent);
  const [content, setContent] =
    useState<ContentInputProps['initialContent']>(initialContent);
  const { overrideSources } = previewState ?? {};
  const { domain } = getEnv({ deployEnv, clientName });
  const prodSystemDefaults = getProdSystemDefaults({ deployEnv });
  const contentcache = getContentCache({
    overrideSources,
    prodSystemDefaults,
  });

  const fetchInput = useMemo(
    () =>
      getFetchInput({
        clientName,
        contentcache,
        locale,
        overrideSources,
        market,
        salesModel,
        customerType,
        enableDisclaimerContentApi,
      }),
    [
      clientName,
      contentcache,
      customerType,
      enableDisclaimerContentApi,
      locale,
      market,
      overrideSources,
      salesModel,
    ],
  );

  const endpoint = getContentEndpoint({ domain });
  const queryParams = getQueryParams({
    cache: fetchInput?.emptyCache,
    overrideSources,
  });

  const endpointV2 = getContentEndpointV2({ domain, queryParams });
  // Ensure that the locale is in the correct format (e.g. en-GB)
  const localeIsValid = isValidLocale(locale);

  if (!localeIsValid) {
    throw new Error(
      `Invalid locale: ${locale}. The locale must be in the format en-GB`,
    );
  }

  const headers = useMemo(
    () =>
      getOfferSelectorAPIEndpointHeaders({
        deployEnv,
        testwwwHeader,
        qawwwHeader,
        clientName,
      }),
    [clientName, deployEnv, qawwwHeader, testwwwHeader],
  );

  const onUpdateContent = useCallback(
    () =>
      updateContent({
        enableV2ApiEndpoints,
        endpointV2,
        headers,
        clientName,
        endpoint,
        fetchInput,
        setContent,
        setLoading,
      }),
    [
      clientName,
      enableV2ApiEndpoints,
      endpoint,
      endpointV2,
      fetchInput,
      headers,
    ],
  );

  useEffect(() => {
    if (!content && localeIsValid) {
      onUpdateContent();
    }
  }, [content, localeIsValid, onUpdateContent]);

  const ctasBySourceId = getCtasBySourceId(content?.translations);

  const disclaimersBySourceId = getDisclaimersBySourceId(content?.translations);

  const SETTING_BY_SOURCE_ID = useMemo(
    () =>
      getSettingBySourceId({
        enableDisclaimerMain,
        enableDisclaimerIndicativePrice,
      }),
    [enableDisclaimerMain, enableDisclaimerIndicativePrice],
  );

  const orderedDisclaimers = useMemo(
    () =>
      getOrderedDisclaimers({ SETTING_BY_SOURCE_ID, disclaimersBySourceId }),
    [SETTING_BY_SOURCE_ID, disclaimersBySourceId],
  );

  const termsProxyExperienceDisclaimers = useMemo(
    () =>
      getTermsProxyExperienceDisclaimers({
        translations: content?.translations,
        salesModel,
      }),
    [content, salesModel],
  );

  const disclaimers = getDisclaimers(
    orderedDisclaimers,
    termsProxyExperienceDisclaimers,
  );

  const translate = createTranslate({
    settings: { lang: market },
    translations: {
      ...content?.sharedTranslations,
      ...content?.translations,
    },
  });

  return useMemo(
    () => ({
      loading,
      ctasBySourceId,
      disclaimersBySourceId,
      disclaimers,
      sharedTranslations: content?.sharedTranslations,
      translations: content?.translations,
      translate,
    }),
    [
      content?.sharedTranslations,
      content?.translations,
      ctasBySourceId,
      disclaimers,
      disclaimersBySourceId,
      loading,
      translate,
    ],
  );
};
