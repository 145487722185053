import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { getLocaleForFedGraph } from '@vcc-package/offers-utils';
import { useFleetSession } from '../../fleet-session/offers-provider.fleet-session.provider';
import { OFFER_QUERY } from '../../queries/fed-graph/entire-offer.query';
import {
  OfferQuery,
  OfferQueryVariables,
} from '../../queries/fed-graph/entire-offer.query.gql-types';
import { useSettings } from '../../settings/offers-provider.settings.provider';
import { UseOfferQueryProps } from '../entire-offer.types';
import { formatApolloError, getFinanceOutput } from '../entire-offer.utils';
import { usePriceLabels } from '../../hooks/usePriceLabels';
import { useFleetTokenUpdate } from './entire-offer.fleet-token-update.hook';
import { useEntireOfferCarAdditionals } from '../adapters/entire-offer.car-additionals.adapter';
import { usePriceBreakdownAdapter } from '../../price-breakdown/price-breakdown';

export const useOfferQuery = ({
  clientName,
  discountIds,
  postcode,
  retailerId,
  downPayment,
  isDirectSales,
  isStockCar,
  locale,
  onFleetTokenUpdate,
  partExchange,
  skip,
  token,
  vehicleId,
  market,
}: UseOfferQueryProps) => {
  const {
    globalEnableDefaultRoundingStrategy,
    defaultRoundingStrategy,
    salesModelSortOrder,
    supportsVAT,
    showSingleContractLength,
    marketChecks,
    settingOverriddenMaxDownPayment,
    permittedSalesModels,
  } = useSettings();
  const labels = usePriceLabels();
  const { session } = useFleetSession();

  const graphQueryLocale = getLocaleForFedGraph(locale) ?? '';

  const variables = useMemo(
    () => ({
      token: token ?? '',
      locale: graphQueryLocale,
      priceConfigInputV2: {
        ...(discountIds?.length && { discountIds }),
        ...(postcode && { taxParameterInput: { postcode } }),
        ...(retailerId && { retailerId }),
        discountPlanId: session?.sharedData?.fleetDiscountPlanId,
        downpayment: downPayment,
        locale: graphQueryLocale,
        useAutomaticDownpaymentAdjustment: true,
        ...(globalEnableDefaultRoundingStrategy
          ? { roundingStrategy: defaultRoundingStrategy }
          : {}),
      },
      isStockCar,
    }),
    [
      token,
      graphQueryLocale,
      discountIds,
      postcode,
      retailerId,
      session?.sharedData?.fleetDiscountPlanId,
      downPayment,
      globalEnableDefaultRoundingStrategy,
      defaultRoundingStrategy,
      isStockCar,
    ],
  );

  const { data, previousData, loading, error } = useQuery<
    OfferQuery,
    OfferQueryVariables
  >(OFFER_QUERY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables,
    skip,
    context: {
      headers: {
        'x-car-config-enable-addons': true,
        'x-client-name': clientName,
      },
    },
  });
  const offerData = data || previousData;

  const { configuration, offerKey, priceSummary, financingPriceSummary } =
    offerData?.offerByToken ?? {};
  const {
    salesModel: offerSalesModel,
    customerType,
    contractLength: contractLengthId,
    mileage: mileageId,
  } = offerKey || {};

  useFleetTokenUpdate({
    offerData,
    offerSalesModel,
    onFleetTokenUpdate,
    isStockCar,
    vehicleId,
  });

  const downPaymentSelection = useMemo(
    () =>
      downPayment ??
      (financingPriceSummary?.breakdown
        ? financingPriceSummary?.breakdown?.downpayment?.amount?.priceInclTax
            ?.amount
        : priceSummary?.payments?.find(
            (payment) => payment.paymentType === 'downpayment',
          )?.amount?.priceInclVat?.amount),
    [downPayment, priceSummary?.payments, financingPriceSummary?.breakdown],
  );

  const additionals = useEntireOfferCarAdditionals({ offerData });

  const overriddenMaxDownPayment =
    market &&
    marketChecks &&
    marketChecks[market.toUpperCase()]?.overriddenMaxDownPayment;

  const finance = getFinanceOutput({
    selections: configuration?.selections,
    customerType,
    salesModel: offerSalesModel,
    salesModelSortOrder: salesModelSortOrder?.map(String),
    contractLengthId,
    mileageId,
    downPayment: downPaymentSelection,
    availableDownPayments:
      settingOverriddenMaxDownPayment && overriddenMaxDownPayment
        ? // US temporary fix, will remove when price engine fix ready
          priceSummary?.carPriceSummary?.availableDownPayments?.filter(
            (availableDownPayment) => {
              if (overriddenMaxDownPayment > 0) {
                return (
                  typeof availableDownPayment?.amount === 'number' &&
                  availableDownPayment?.amount <= overriddenMaxDownPayment
                );
              }
              return true;
            },
          )
        : priceSummary?.carPriceSummary?.availableDownPayments,
    token: configuration?.token?.short,
    labels,
    supportsVAT,
    priceSummary,
    financingPriceSummary,
    partExchange,
    showSingleContractLength,
    permittedSalesModels: permittedSalesModels?.map(String),
  });

  const priceBreakdown = usePriceBreakdownAdapter({
    additionals,
    contractLength: finance?.selectedOffer.contractLength?.text,
    customerType: finance?.selectedOffer.customerType,
    downPayment: finance?.selectedOffer.downPayment,
    financingPriceSummary,
    isDirectSales,
    mileage: finance?.selectedOffer.mileage?.text,
    priceSummary,
    salesModel: offerSalesModel,
  });

  return useMemo(
    () => ({
      additionals,
      error,
      formattedOfferError: error
        ? formatApolloError({
            apolloError: error,
            variables,
            query: OFFER_QUERY,
          })
        : null,
      finance: {
        ...finance,
        priceBreakdown,
      },
      loading,
      offerData,
    }),
    [
      additionals,
      error,
      finance,
      loading,
      offerData,
      priceBreakdown,
      variables,
    ],
  );
};
