import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import {
  FinanceOptionsQuery,
  FinanceOptionsQueryVariables,
} from '@vcc-www/gql-types/FinanceOptionsQuery';
import { flattenFinanceOptions } from './utils';

// Run 'yarn codegen' in 'shared/gql-types' after changing this query
export const FINANCE_OPTIONS_QUERY = gql`
  query FinanceOptionsQuery(
    $market: String!
    $locale: String!
    $customerType: String!
  ) {
    offersByMarket(market: $market) {
      customerTypes: groupBy(groupType: CUSTOMER_TYPE, filter: $customerType) {
        offers {
          salesModel {
            id
            content(locale: $locale) {
              displayName {
                value
              }
              shortDescription {
                value
              }
              description {
                value
              }
              image {
                url
              }
              thumbnail {
                url
              }
              howItWorks {
                title {
                  value
                }
                shortDescription {
                  value
                }
                image {
                  url
                }
                video {
                  url
                }
              }
              disclaimer {
                value
              }
              endOfContract {
                value
              }
              contractTerm {
                value
              }
              annualMileage {
                value
              }
              paymentMethod {
                value
              }
              prepayment {
                value
              }
              excessFees {
                value
              }
              settlementOption {
                value
              }
            }
          }
        }
      }
    }
    carsByMarket(market: $market) {
      cars {
        engine {
          content(locale: $locale) {
            engineType {
              value
            }
          }
        }
        model(locale: $locale) {
          key
        }
        offerList(market: $market) {
          groupBy(groupType: CUSTOMER_TYPE, filter: $customerType) {
            offers {
              baseOffer
              products {
                sortedServices(locale: $locale) {
                  id
                  content(locale: $locale) {
                    ...FinanceOptionsService
                  }
                }
                packages {
                  id
                  content(locale: $locale) {
                    displayName {
                      value
                    }
                    shortDescription {
                      value
                    }
                    description {
                      value
                    }
                    image {
                      url
                    }
                    thumbnail {
                      url
                    }
                  }
                  items {
                    id
                    content(locale: $locale) {
                      ...FinanceOptionsService
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment FinanceOptionsService on ServiceContent {
    displayName {
      value
    }
    shortDescription {
      value
    }
    description {
      value
    }
    image {
      url
    }
    thumbnail {
      url
    }
    disclaimer {
      value
    }
    sortOrder
    sellingPoints {
      title {
        value
      }
    }
  }
`;

export const useFinanceOptions = (
  market: string,
  locale: string,
  customerType: 'B2C' | 'B2B',
) => {
  const { data, loading, error } = useQuery<
    FinanceOptionsQuery,
    FinanceOptionsQueryVariables
  >(FINANCE_OPTIONS_QUERY, {
    variables: { market, locale, customerType },
    context: { headers: { 'x-car-config-enable-addons': true } },
  });

  const flattenedData = flattenFinanceOptions(data);

  return { data: flattenedData, loading, error };
};
