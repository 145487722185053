import { ApolloError, DocumentNode, NetworkStatus } from '@apollo/client';
import { SalesModel } from '@vcc-www/feature-flags/src/flags.types';
import {
  FinancingPriceSummary,
  OfferPriceSummary,
} from '@vcc-www/federated-graph-types';
import { OrderError } from '@vcc-package/offers-utils';
import {
  PriceBreakdownItemProps,
  TotalAndVATSectionProps,
} from '@vcc-package/price-breakdown';
import { OffersContextType } from '../offer/offer.provider.types';
import { useFinanceDetails } from '../price-breakdown/price-breakdown.finance-details.hook';
import {
  BtoCarQuery,
  BtoCarQueryVariables,
} from '../queries/fed-graph/entire-offer.bto.query.gql-types';
import {
  OfferSelectionDataFragment,
  OfferSelectionWithPriceSummaryDataFragment,
} from '../queries/fed-graph/entire-offer.fragments.query.gql-types';
import { OfferQuery } from '../queries/fed-graph/entire-offer.query.gql-types';
import {
  StockCarQuery,
  StockCarQueryVariables,
} from '../queries/fed-graph/entire-offer.stock.query.gql-types';
import { OfferProviderError, OffersProviderOnErrorType } from '../offers.types';

type strNullUnd = string | null | undefined;

type Tyre = {
  imageUrl: string;
  linkUrl: string;
  position: string;
};

export type UseEntireOfferProps = Pick<
  OffersContextType,
  | 'clientName'
  | 'contractLength'
  | 'customerType'
  | 'discountIds'
  | 'postcode'
  | 'retailerId'
  | 'downPayment'
  | 'isDirectSales'
  | 'isPrivateStockCar'
  | 'isStockCar'
  | 'locale'
  | 'market'
  | 'mileage'
  | 'offerToken'
  | 'onFleetTokenUpdate'
  | 'regionCode'
  | 'salesModel'
  | 'token'
  | 'partExchange'
  | 'includeProviders'
  | 'onError'
>;

type UseEntireBaseOfferProps = Pick<
  UseEntireOfferProps,
  | 'clientName'
  | 'locale'
  | 'token'
  | 'discountIds'
  | 'postcode'
  | 'retailerId'
  | 'downPayment'
  | 'customerType'
  | 'contractLength'
  | 'isDirectSales'
  | 'partExchange'
>;

export type UseEntireListingOfferProps = UseEntireBaseOfferProps &
  Pick<
    UseEntireOfferProps,
    'regionCode' | 'mileage' | 'salesModel' | 'includeProviders' | 'onError'
  >;

export type UseEntireDataOfferProps = UseEntireBaseOfferProps &
  Pick<
    UseEntireOfferProps,
    | 'market'
    | 'onFleetTokenUpdate'
    | 'offerToken'
    | 'isPrivateStockCar'
    | 'isStockCar'
    | 'onError'
  >;

// TODO: Fix types
export type EntireOfferCarReturnType = {
  tracking: BtoCarQuery['carByToken']['carKey'] & {
    currencyCode: string;
    productClass: string;
    modelFamily: string;
  };
  configuration: {
    userFriendlyCode?: strNullUnd;
    displayName: string;
    displayNameExpanded: string;
    displayNameExpandedAndModelYear: string;
    carTypeCode: string;
    modelKey: string;
    modelSlug: string;
    modelYear: string;
    duplicatesIdentifier?: strNullUnd;
    imageUrl: string;
    baseCar: {
      displayNames: {
        edition: string | null | undefined;
        level: string | null | undefined;
        powertrain: string | null | undefined;
        seats: string | null | undefined;
        theme: string | null | undefined;
      };
      price?: string | null | undefined;
    };
    color: EntireOfferConfigurationItem;
    interior: EntireOfferConfigurationItem;
    engineType: strNullUnd;
    wheels: EntireOfferConfigurationItem[];
    options: EntireOfferConfigurationItem[];
    userSelectedOptions?: EntireOfferConfigurationItem[];
    packages: EntireOfferConfigurationItem[];
    userSelectedPackages?: EntireOfferConfigurationItem[];
    totalPrice: strNullUnd;
    pno34: string | null | undefined;
  };
  delivery: string;
  emissions: {
    hasData: boolean;
    wltpData: any;
    nedcData: any;
    safetyRating: number;
    modelCode: strNullUnd;
    efficiencyClass: number;
    grade: Grades;
    grades: Grades[];
    errorMessage: string;
    standardTyres: Tyre[];
    winterTyres: Tyre[];
  };
};

type FinanceDetails = ReturnType<typeof useFinanceDetails>;

export type EntireOfferFinanceSelectedOfferType = {
  customerType: EntireOfferSelectionTypeItemStringValue | undefined;
  salesModel: EntireOfferSelectionTypeItemStringValue | undefined;
  contractLength: EntireOfferSelectionTypeItemNumberValue | undefined;
  mileage: EntireOfferSelectionTypeItemNumberValue | undefined;
  downPayment: EntireOfferSelectionTypeItemNumberValue | undefined;
  partExchange: {
    value: boolean;
  };
  token?: strNullUnd;
  offerToken?: strNullUnd;
};

export type EntireOfferFinanceReturnType = {
  selectedOffer: EntireOfferFinanceSelectedOfferType;
  customerTypes: EntireOfferSelectionTypeItemStringValue[] | undefined;
  salesModels: EntireOfferSelectionTypeItemStringValue[] | undefined;
  contractLengths: EntireOfferSelectionTypeItemNumberValue[] | undefined;
  mileages: EntireOfferSelectionTypeItemNumberValue[] | undefined;
  downPayments: EntireOfferSelectionTypeItemNumberValue[] | undefined;
  offerDrawerPrice: strNullUnd;
  offerDrawerPriceValue?: number;
  downPaymentExclVATAmount: strNullUnd;
  offerDrawerPriceSources: string[];
  priceBreakdown?: PriceBreakdown;
};

export type AprData = {
  aprDiscountMinimumDownPaymentAmount: number | null | undefined;
  aprDiscountMinimumDownPaymentDisplay: strNullUnd;
  aprDiscountRateDisplay: strNullUnd;
  isDiscountAppliedApr: boolean;
  shouldShowAprDiscountOffer: boolean;
  standardRateDisplay: strNullUnd;
};

export type Additional = {
  displayName: string;
  displayNameSources: string[];
  id: string;
  price: number;
  priceText: string;
  vatAmount: number;
  priceTextSources: string[];
  tags: string[] | null | undefined;
  images: Array<{ url: string; alt: string }>; // TODO: Convert to ImageType when we have that
  imagesSources: string[];
  isUserAdded: boolean;
};

type Additionals = {
  followsCustomer: Array<Additional> | undefined;
  followsCar: Array<Additional> | undefined;
};

export type EntireOfferAdditionalsReturnType = {
  accessories?: Additionals;
  packages?: Additionals;
  services?: Array<Additional> | undefined;
};

export type EntireOfferReturnType = {
  isLoading: boolean;
  isUpdating: boolean;
  car?: EntireOfferCarReturnType;
  finance?: EntireOfferFinanceReturnType;
  policy?: string | null | undefined;
  additionals?: EntireOfferAdditionalsReturnType;
  error?: OrderError | null | undefined;
  lastStateChange?: EntireOfferLastStateChange;
  originalToken?: string;
  updatedError?: OffersProviderOnErrorType;
};

export type AddedRemovedItem = {
  id: string;
  type: string;
  optional: boolean | undefined;
  displayName: string;
};

export type EntireOfferLastStateChange = {
  added: AddedRemovedItem[];
  removed: AddedRemovedItem[];
  previousToken: string;
};

export type EntireOfferSelectionTypeItem = {
  title: strNullUnd;
  titleSources: string[];
  children?: strNullUnd | JSX.Element;
  disclaimer?: strNullUnd;
  id: strNullUnd;
  image?: strNullUnd;
  thumbnail?: strNullUnd;
  isActive?: boolean;
  token?: strNullUnd;
  secondaryInfo?: strNullUnd | JSX.Element;
  secondaryInfoLegend?: strNullUnd;
  secondaryInfoSources?: string[];
  vatLabel?: strNullUnd;
  vatLabelSources?: string[];
  selectionsAdded?: string[];
  selectionsRemoved?: string[];
  text?: strNullUnd;
  textSources?: string[];
  textLegend?: strNullUnd;
  textLegendSources?: string[];
  priceAmount?: number;
};

export type EntireOfferSelectionTypeItemStringValue =
  EntireOfferSelectionTypeItem & {
    value: string | null | undefined;
  };

export type EntireOfferSelectionTypeItemNumberValue =
  EntireOfferSelectionTypeItem & {
    value: number | null | undefined;
  };

export type EntireOfferSelectionType = {
  CustomerType?: EntireOfferSelectionTypeItemStringValue[];
  SalesModel?: EntireOfferSelectionTypeItemStringValue[];
  ContractLength?: EntireOfferSelectionTypeItemNumberValue[];
  Mileage?: EntireOfferSelectionTypeItemNumberValue[];
  DownPayment?: EntireOfferSelectionTypeItemNumberValue[];
};

export type EntireOfferConfigurationItem =
  | {
      displayName?: string | null | undefined;
      displayNameSources?: string[];
      price?: number | null | undefined;
      priceText?: string | null | undefined;
      hidePrefix?: boolean;
      priceTextSources?: string[];
      components?: string[] | undefined;
      code?: string;
      image?: string | null;
      nonInteractive?: boolean;
      testId?: string;
      id?: string;
    }
  | undefined;

/**Type PriceBreakdownSectionProps used in hook */
export type PriceBreakdownSectionProps = {
  shouldHideLink?: boolean;
  sectionType: PriceBreakdownSections;
  isPDFMode?: boolean;
  price: number;
  priceText: string;
  priceTextSources: string[];
  displayName: string;
  displayNameSources: string[];
  disclaimerText?: string;
  vatLabel?: string;
  vatLabelSources?: string[];
  shouldShowSection: boolean;
  priceBreakdownItems: PriceBreakdownItemProps[];
  totalBreakdownItem: PriceBreakdownItemProps;
  totalAndVatSection: TotalAndVATSectionProps;
};

export const PRICE_BREAKDOWN_SECTIONS = {
  TODAY: 'dueToday',
  MONTHLY: 'dueMonthly',
  BEFORE_DELIVERY: 'dueBeforeDelivery',
} as const;

export type PriceBreakdownSections =
  (typeof PRICE_BREAKDOWN_SECTIONS)[keyof typeof PRICE_BREAKDOWN_SECTIONS];

export type DownPayment = {
  __typeName: string;
  amount: number;
  display: string;
};

export type Grades =
  | 'zero'
  | 'a+++'
  | 'a++'
  | 'a+'
  | 'a'
  | 'b'
  | 'c'
  | 'd'
  | 'e'
  | 'f'
  | 'g';

export const TyreLabelVariant = {
  WINTER: 'winter',
  SUMMER: 'summer',
} as const;

export const ENERGY_EFFICIENT_TYPE = {
  WLTP: 'WLTP',
  NEDC: 'NEDC',
} as const;

export type EnergyEfficiencyType =
  (typeof ENERGY_EFFICIENT_TYPE)[keyof typeof ENERGY_EFFICIENT_TYPE];

export const SALES_MODELS = {
  CASH: 'CASH',
  DEFAULT: 'DEFAULT',
  FIXED: 'SUB_FIXED',
  FLEX: 'SUB',
  LEASE: 'LEASE',
  LOAN: 'LOAN',
  WHOLESALE: 'WHOLESALE', // TODO: Remove wholesale once data is in place.
} as const;

export type SalesModelType = (typeof SALES_MODELS)[keyof typeof SALES_MODELS];

export type Price = {
  amount: number;
  display: string;
};

export type Tax = {
  name: string;
  price: Price;
  taxId?: string;
};

type SUN = string | null | undefined;

type PriceBreakdown = {
  totalPrice: any;
  breakdownSections: PriceBreakdownSectionProps[];
  taxAndBonus: {
    title: string;
    description?: string | undefined;
    items: PriceBreakdownItemProps[] | undefined;
  };
  checksum: strNullUnd;
  isDiscountApplied: boolean;
  financeDetails: FinanceDetails;
  aprData: AprData;
};

export type GetFinanceOutputProps = Pick<
  OffersContextType,
  | 'customerType'
  | 'salesModel'
  | 'downPayment'
  | 'contractLength'
  | 'mileage'
  | 'partExchange'
> & {
  availableDownPayments: any;
  selections: OfferSelectionWithPriceSummaryDataFragment[] | undefined;
  permittedSalesModels: string[] | undefined;
  salesModelSortOrder: string[] | undefined;
  token?: SUN;
  contractLengthId?: SUN;
  mileageId?: SUN;
  labels?: Labels;
  supportsVAT?: boolean | undefined;
  priceSummary?: Omit<OfferPriceSummary, 'carPriceSummary'> | null;
  financingPriceSummary?: FinancingPriceSummary | null;
  showSingleContractLength?: boolean;
};

export type IsCustomerTypeSupportedSalesModelProps = {
  selectedCustomerType: string | null | undefined;
  selectionsAdded: Array<{ id: string; type: string }> | undefined;
  permittedSalesModels?: string[] | undefined;
  selectedSalesModel: string;
};

export type GetSelectionByTypeProps = {
  selections: OfferSelectionDataFragment[] | undefined;
  selectedCustomerType: string | null | undefined;
  selectedSalesModel: string | null | undefined;
  selectedContractLength?: number | null | undefined;
  selectedContractLengthId?: string | null | undefined;
  selectedMileage?: number | null | undefined;
  selectedMileageId?: string | null | undefined;
  labels?: Labels;
  supportsVAT?: boolean | undefined;
  permittedSalesModels?: string[] | undefined;
};

export type Labels = {
  inclVat: SUN;
  exclVat: SUN;
  exclTaxesAndFees: SUN;
  business: SUN;
  personal: SUN;
  sources: {
    inclVat: string[];
    exclVat: string[];
    exclTaxesAndFees: string[];
    business: string[];
    personal: string[];
  };
};

export type UseOfferQueryProps = Pick<
  UseEntireOfferProps,
  | 'token'
  | 'locale'
  | 'discountIds'
  | 'postcode'
  | 'retailerId'
  | 'downPayment'
  | 'clientName'
  | 'onFleetTokenUpdate'
  | 'partExchange'
  | 'isStockCar'
  | 'market'
> & {
  isDirectSales?: boolean;
  vehicleId?: string;
  skip?: boolean;
};

export type UseFleetTokenUpdateProps = Pick<
  UseEntireOfferProps,
  'onFleetTokenUpdate' | 'isStockCar'
> & {
  vehicleId?: string;
  offerSalesModel?: SalesModel;
  offerData?: OfferQuery | undefined;
};

export type UseCarQueryProps = Pick<
  UseEntireOfferProps,
  'locale' | 'downPayment' | 'clientName' | 'market'
> & {
  query: DocumentNode;
  skip?: boolean;
  optionalVariables?: {
    [key: string]: any;
  };
};

export type UseErrorProps = Pick<
  UseEntireOfferProps,
  'token' | 'downPayment' | 'clientName' | 'market' | 'isStockCar' | 'onError'
> & {
  networkStatus: NetworkStatus;
  isLoading: boolean;
  isStockCar: boolean;
  isFleetLoading?: boolean;
  carData?: CarQueryType;
  carError?: ApolloError;
  offerError?: ApolloError;
  formattedCarError?: OfferProviderError[] | null;
  formattedOfferError?: OfferProviderError[] | null;
};

export type UseEntireOfferDeliveryAdapterProps = Pick<
  UseEntireOfferProps,
  'isStockCar'
> & {
  carData: CarQueryType | undefined;
};

export type UseEntireOfferCarEmissionsAdapterProps = Pick<
  UseEntireOfferProps,
  'isStockCar'
> & {
  carData: CarQueryType | undefined;
};

export type UseEntireOfferCarConfigurationAdapterProps = Pick<
  UseEntireOfferProps,
  'isStockCar'
> & {
  carData: CarQueryType | undefined;
  offerData: OfferQuery | undefined;
  market: strNullUnd;
};

export type CarQueryType = BtoCarQuery & StockCarQuery;
export type CarQueryVariablesType = BtoCarQueryVariables &
  StockCarQueryVariables;

export type GetStockCarQueryFilterVariablesProps = Pick<
  UseEntireOfferProps,
  'isPrivateStockCar' | 'token'
>;

export type UsePreviousType = <T>(value?: T) => T | undefined;

export type GetFormattedTaxesProps = {
  taxes: Array<Tax>;
  testId?: string;
};

export type FormatApolloErrorProps = {
  apolloError: ApolloError;
  variables: Record<string, unknown>;
  query: DocumentNode;
};
