import { TrackingData } from '@volvo-cars/tracking';
import { EntireOfferReturnType } from '../entire-offer/entire-offer.types';
import { OffersContextType } from '../offer/offer.provider.types';
import { SettingsFlagData } from '../settings/offers-provider.settings.types';

export type eventCategoryFinanceType =
  | 'customerTypes'
  | 'salesModels'
  | 'contractLengths'
  | 'mileages'
  | 'downPayments';

export const EVENT_CATEGORY_TYPES = {
  CAMPAIGN: 'campaign',
  CART: 'cart',
  CONFIGURATION: 'configuration',
  CONTRACT_LENGTH: 'contractLength',
  CUSTOMER_TYPE: 'customerType',
  DOWN_PAYMENT: 'downPayment',
  DUE_BEFORE_DELIVERY: 'due at signing',
  DUE_MONTHLY: 'due every month ',
  FLEET: 'fleet',
  MILEAGE: 'mileage',
  LINK: 'link',
  OFFER_DRAWER: 'offer drawer',
  ORDER: 'order',
  PART_EXCHANGE: 'part-exchange',
  PART_EXCHANGE_TOOL: 'part exchange',
  PDF: 'pdf',
  PRICE: 'price',
  PRIMARY_CTA: 'primary-cta',
  REQUEST_DELIVERY_DATE: 'request delivery date',
  SALES_MODEL: 'salesModel',
  SECONDARY_CTA: 'secondary-cta',
  VALIDATION_ERROR: 'validation error | payment error',
} as const;

export type eventCategoryType =
  (typeof EVENT_CATEGORY_TYPES)[keyof typeof EVENT_CATEGORY_TYPES];

export const EVENT_LABELS = {
  B2C: 'private',
  B2B: 'business',
  FLEET: 'fleet',
  SUB: 'subscription',
  SUB_FIXED: 'subscription fixed',
  CASH: 'cash',
  DEFAULT: 'cash',
  FIXED: 'lease',
  LOAN: 'loan',
  CONTRACT_24: 24,
  CONTRACT_36: 36,
  CONTRACT_48: 48,
  DOWNLOAD: 'download pdf',
  COPY: 'copy link',
  BEGIN: 'begin order',
  BIK: 'benefit in kind',
  TBI: 'tax,bonus and incentives',
  TOTAL_COST_OF_OWNERSHIP: 'total cost of ownership',
  FINANCE_OPTIONS: 'finance options',
  CAR_OPTIONS: 'car options',
  LEARN_MORE: 'learn more',
  DUE_TODAY: 'due today',
  DUE_MONTHLY: 'due monthly',
  DUE_BEFORE_DELIVERY: 'due before delivery',
} as const;

type EventLabels = (typeof EVENT_LABELS)[keyof typeof EVENT_LABELS];

export const TRACKING_SPECIFIC_OFFER_TYPES = {
  FLEX: 'flex',
  CASH: 'cash',
  LOAN: 'loan',
  FIX: 'fix',
  LEASE: 'lease',
} as const;

export const EVENT_TYPES = {
  CONFIG_FINISH: 'config_finish',
  ADD_TO_CART: 'add_to_cart',
  DOM_LOAD: 'DOM load',
  INTERACTION: 'interaction',
  CUSTOM_EVENT: 'custom_event',
  PURCHASE: 'purchase',
} as const;

type EventTypes = (typeof EVENT_TYPES)[keyof typeof EVENT_TYPES];

export type TrackDataType = {
  action?: any;
  category?: eventCategoryType;
  label?: string | EventLabels;
  index?: number;
  event?: EventTypes;
  isDrawerMode?: boolean;
};

type NumNull = number | null | undefined;

type TrackingDataType = {
  trackingData: Record<string, any>;
};

export type GetOfferTrackingFinanceProps = Pick<
  TrackDataType,
  'action' | 'label' | 'isDrawerMode'
> &
  Pick<SettingsFlagData, 'enableEditConfiguration'> & {
    category?: eventCategoryType;
    index: number;
    finance: EntireOfferReturnType['finance'] | undefined;
    isFleetSession: boolean | undefined;
  };

export type GetOfferTrackingCTADataProps = Required<
  Pick<TrackingData, 'saleType' | 'offerType' | 'customerType'>
> &
  Pick<TrackDataType, 'action' | 'label'> & {
    downPayment: NumNull;
    contractLength: NumNull;
    mileage: NumNull;
  };

export type GetOfferTrackingCarDataProps = TrackingDataType &
  Required<Pick<TrackingData, 'saleType'>> & {
    car: EntireOfferReturnType['car'] | undefined;
    isStockCar: boolean | undefined;
    price: string; // TODO: We probably would like to move this inside finance tracking
    token: string;
  };

export type GetOfferTrackingDefaultDataProps = TrackingDataType &
  Pick<OffersContextType, 'entireOffer' | 'regionCode' | 'partExchange'> &
  Pick<SettingsFlagData, 'enableEditConfiguration' | 'enableTwoPartDeposit'> & {
    downPayment: NumNull;
  };

export type GetConvertToCamelCaseProps = {
  str: string;
  divider?: string;
};
