import { SystemConfigType, SystemDefaultsType } from './preview.types';

export const SERVER: Record<string, string> = {
  PROD: 'prod',
  QA: 'qa',
  TEST: 'test',
  DEV: 'dev',
};

export const SYSTEM: Record<string, string> = {
  FEDERATED_GRAPH: 'fedgraph',
  SITECORE: 'sitecore',
  CONTENT_MANAGER: 'contentmanager',
  CONTENT_SOURCE: 'contentsource',
  CONTENT_CACHE: 'contentcache',
  LAUNCH_DARKLY: 'launchdarkly',
  SESSION_SERVICE: 'sessionservice',
  CHECKOUT: 'checkout',
  CAR_CONFIG_API: 'ccapi',
};

export const CONTENT_SOURCE: Record<string, string> = {
  PREVIEW: 'preview',
  LIVE: 'live',
};

export const CONTENT_CACHE: Record<string, string> = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
};

export const SYSTEM_CONFIG: SystemConfigType = {
  [SYSTEM.FEDERATED_GRAPH]: {
    id: SYSTEM.FEDERATED_GRAPH,
    shortId: 'fg',
    name: 'Federated Graph',
    options: [SERVER.PROD, SERVER.QA],
  },
  [SYSTEM.SITECORE]: {
    id: SYSTEM.SITECORE,
    shortId: 'sc',
    name: 'Sitecore',
    options: [SERVER.PROD, SERVER.QA],
  },
  [SYSTEM.CONTENT_MANAGER]: {
    id: SYSTEM.CONTENT_MANAGER,
    shortId: 'cm',
    name: 'Content Manager',
    options: [SERVER.PROD],
  },
  [SYSTEM.CONTENT_SOURCE]: {
    id: SYSTEM.CONTENT_SOURCE,
    shortId: 'cs',
    name: 'Content Source',
    options: [CONTENT_SOURCE.LIVE, CONTENT_SOURCE.PREVIEW],
  },
  [SYSTEM.CONTENT_CACHE]: {
    id: SYSTEM.CONTENT_CACHE,
    shortId: 'cc',
    name: 'Content Cache',
    options: [CONTENT_CACHE.ENABLED, CONTENT_CACHE.DISABLED],
  },
  [SYSTEM.SESSION_SERVICE]: {
    id: SYSTEM.SESSION_SERVICE,
    shortId: 'ss',
    name: 'Session Service',
    options: [SERVER.PROD, SERVER.QA],
  },
  [SYSTEM.CHECKOUT]: {
    id: SYSTEM.CHECKOUT,
    shortId: 'ch',
    name: 'Checkout',
    options: [SERVER.PROD, SERVER.QA],
  },
  [SYSTEM.LAUNCH_DARKLY]: {
    id: SYSTEM.LAUNCH_DARKLY,
    shortId: 'ld',
    name: 'Launch Darkly',
    options: [SERVER.PROD, SERVER.QA, SERVER.TEST, SERVER.DEV],
    isWorkInProgress: true,
  },
  [SYSTEM.CAR_CONFIG_API]: {
    id: SYSTEM.CAR_CONFIG_API,
    shortId: 'ca',
    name: 'Car Config API',
    options: [SERVER.PROD, SERVER.QA],
    isWorkInProgress: true,
  },
};

export const SYSTEM_DEFAULTS: (
  deployEnv: keyof typeof SERVER,
) => SystemDefaultsType = (deployEnv) => ({
  [SERVER.PROD]: {
    [SYSTEM.FEDERATED_GRAPH]: SERVER.PROD,
    [SYSTEM.SITECORE]: SERVER.PROD,
    [SYSTEM.CONTENT_MANAGER]: SERVER.PROD,
    [SYSTEM.CONTENT_SOURCE]: CONTENT_SOURCE.LIVE,
    [SYSTEM.CONTENT_CACHE]: CONTENT_CACHE.ENABLED,
    [SYSTEM.LAUNCH_DARKLY]: deployEnv,
    [SYSTEM.SESSION_SERVICE]: SERVER.PROD,
    [SYSTEM.CHECKOUT]: SERVER.PROD,
    [SYSTEM.CAR_CONFIG_API]: SERVER.PROD,
  },
  [SERVER.QA]: {
    [SYSTEM.FEDERATED_GRAPH]: SERVER.QA,
    [SYSTEM.SITECORE]: SERVER.QA,
    [SYSTEM.CONTENT_MANAGER]: SERVER.PROD,
    [SYSTEM.CONTENT_SOURCE]: CONTENT_SOURCE.LIVE,
    [SYSTEM.CONTENT_CACHE]: CONTENT_CACHE.ENABLED,
    [SYSTEM.LAUNCH_DARKLY]: deployEnv,
    [SYSTEM.SESSION_SERVICE]: SERVER.QA,
    [SYSTEM.CHECKOUT]: SERVER.QA,
    [SYSTEM.CAR_CONFIG_API]: SERVER.QA,
  },
});
