'use client';
import React from 'react';
import { Spacing } from '../../layout/Spacing';
import {
  PromotionsTextImage,
  type PromotionsTextImageProps,
  type PromotionCard,
} from '@vcc-package/discovery-utils';
import { TrackingProvider } from '@volvo-cars/tracking';
import { useQueries } from 'src/hooks/useQueries';
import { findCarAndFormatDisclaimer } from 'src/utils/queries';
import { type EngineType } from 'src/types/queries';

interface DotcomPromotionsCard extends PromotionCard {
  model?: string;
  engineType?: EngineType;
}
export interface DotcomPromotionsTextImageProps
  extends Omit<PromotionsTextImageProps, 'cards'> {
  type: 'dotcomPromotionsTextImage';
  model?: string;
  engineType?: EngineType;
  cards?: DotcomPromotionsCard[];
  withoutSpacing?: boolean;
}

export const DotcomPromotionsTextImage_DEPRECATED: React.FC<
  React.PropsWithChildren<DotcomPromotionsTextImageProps>
> = ({ cards, disclaimer, model, engineType, withoutSpacing, ...props }) => {
  const { graphCars } = useQueries();

  let formattedDisclaimer = disclaimer;
  if (model && engineType && disclaimer) {
    formattedDisclaimer = findCarAndFormatDisclaimer({
      disclaimer,
      graphCars,
      model,
      engineType,
    });
  }

  const SpacingWrapper = withoutSpacing ? FlexWrapper : Spacing;

  return (
    <SpacingWrapper>
      <div className="container mb-24">
        <TrackingProvider eventCategory="dotcom promotions text image">
          <PromotionsTextImage
            {...props}
            disclaimer={formattedDisclaimer}
            cards={cards}
          />
        </TrackingProvider>
      </div>
    </SpacingWrapper>
  );
};

const FlexWrapper = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex-row">{children}</div>;
};
