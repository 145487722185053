import React from 'react';
import {
  SalesModelId,
  flattenFinanceOptions,
} from '@vcc-www/api/finance-options';
import {
  GetCustomServiceDescriptionsProps,
  GetDisclaimersProps,
  GetFinanceOptionProps,
  GetFinancialOptionDescriptionsProps,
  GetHowItWorksProps,
  GetSalesModelImageUrlProps,
  GetServiceDescriptionsProps,
  GetServicesAndPackagesProps,
  MapCustomServiceItemProps,
  MapCustomServiceProps,
  ServiceAdapterProps,
} from './finance-details.types';
import { ServiceDetailsView } from './views/finance-details.view.service-details';
import { sortItemsBySortOrder } from '@vcc-package/products/src/products.utils';

export const serviceAdapter = ({
  service,
  onViewChange,
  translate,
  items,
  styles,
}: ServiceAdapterProps) => {
  return {
    ...service,
    onClick: () => {
      onViewChange({
        title: translate('FinanceDetails.overlay.servicesTitle'),
        component: (
          <ServiceDetailsView
            title={service?.displayName}
            image={service?.image}
            imageAltText={service?.imageAltText ?? service.displayName}
            description={service?.description}
            disclaimer={service?.disclaimer}
            items={items}
            includedServicesTitle={translate(
              'FinanceDetails.includedServices.title',
            )}
            includedServicesSources={[
              'sharedDict:FinanceDetails.includedServices.title',
            ]}
            styles={styles}
          />
        ),
      });
    },
  };
};

export const getCustomServiceDescriptions = ({
  financeDetails,
  onViewChange,
  translate,
  styles,
}: GetCustomServiceDescriptionsProps) =>
  financeDetails?.serviceDescriptions?.map(({ services, title }) => ({
    title,
    services: mapCustomService({ services, onViewChange, translate, styles }),
  }));

const mapCustomService = ({
  services,
  onViewChange,
  translate,
  styles,
}: MapCustomServiceProps) =>
  services?.map((service) => {
    const items = {
      services: mapCustomServiceItem({
        service,
        onViewChange,
        translate,
        styles,
      }),
    };
    return serviceAdapter({
      service,
      onViewChange,
      translate,
      items,
      styles,
    });
  });

const mapCustomServiceItem = ({
  service,
  onViewChange,
  translate,
  styles,
}: MapCustomServiceItemProps) =>
  service?.items?.map((service) =>
    serviceAdapter({ service, onViewChange, translate, styles }),
  ) ?? [];

export const getFinanceOption = ({
  data,
  salesModel,
}: GetFinanceOptionProps) => {
  const flattenedData = flattenFinanceOptions(data) ?? [];

  const financeOption =
    flattenedData[salesModel?.toLocaleUpperCase() as SalesModelId];

  return { financeOption };
};

export const getServicesAndPackages = ({
  sortedServices = [],
  packages = [],
}: GetServicesAndPackagesProps) => [
  ...sortedServices,
  ...(packages?.filter((pack) => !pack.optional) ?? []),
];

export const getFinancialOptionDescriptions = ({
  content,
}: GetFinancialOptionDescriptionsProps) => ({
  displayName: content?.displayName?.value ?? '',
  description: content?.description?.value ?? '',
  disclaimer: content?.disclaimer?.value ?? '',
});

export const getDisclaimers = ({
  content,
  sortedServices,
  translate,
}: GetDisclaimersProps) => {
  const salesModelDisclaimer = content?.disclaimer?.value ?? '';
  const servicesDisclaimers =
    sortedServices?.map(({ content }) => content?.disclaimer?.value) ?? [];

  const disclaimers = [salesModelDisclaimer, ...servicesDisclaimers].filter(
    Boolean,
  );

  return {
    title: translate('FinanceDetails.disclaimers.title'),
    disclaimers,
  };
};

export const getHowItWorks = ({ content, translate }: GetHowItWorksProps) => ({
  title: translate('FinanceDetails.howItWorks.title'),
  keyPoints:
    content?.howItWorks?.map(({ image, video, title, shortDescription }) => ({
      image: image?.url ?? '',
      videoUrl: video?.url ?? '',
      title: title?.value ?? '',
      shortDescription: shortDescription?.value ?? '',
    })) ?? [],
});

export const getParsedService = (service: any) => {
  const { content } = service ?? {};

  const sellingPoints =
    content?.sellingPoints?.map(({ title }: any) => title?.value) ?? [];

  return {
    displayName: content?.displayName?.value ?? '',
    shortDescription: content?.shortDescription?.value ?? '',
    description: content?.description?.value ?? '',
    image: content?.image?.url ?? '',
    imageAltText: content?.image?.label ?? '',
    thumbnail: content?.thumbnail?.url ?? '',
    thumbnailAltText: content?.thumbnail?.label ?? '',
    sellingPoints,
    sortOrder: content?.sortOrder ?? 0,
    ariaLabel:
      `${content?.description?.value ?? ''} ${sellingPoints?.join(', ')}`.trim(),
  };
};

export const getServiceDescriptions = ({
  translate,
  servicesAndPackages,
  onViewChange,
  styles,
}: GetServiceDescriptionsProps) => [
  {
    title: translate('FinanceDetails.includedServices.title'),
    services:
      servicesAndPackages
        ?.filter((service) => !!service.content)
        ?.map((service: any) => {
          /* For packages we have items */
          const filteredServiceItems = service?.items?.filter(
            (service: any) => !!service.content,
          );

          const sortedServiceItems = sortItemsBySortOrder({
            items: filteredServiceItems ?? [],
          });

          const transformedServiceItems =
            sortedServiceItems?.map((service: any) =>
              serviceAdapter({
                service: getParsedService(service),
                onViewChange,
                translate,
                styles,
              }),
            ) ?? [];

          const items = {
            services: transformedServiceItems,
          };
          return serviceAdapter({
            service: getParsedService(service),
            onViewChange,
            translate,
            items,
            styles,
          });
        }) ?? [],
  },
];

export const getSalesModelImageUrl = ({
  content,
}: GetSalesModelImageUrlProps) => content?.image?.url ?? '';
