// United Kingdom

import { CUSTOMER_TYPE, SALES_MODELS } from '@vcc-package/offers-utils';
import type { MarketSettingsAsFunctions } from './market-settings.types';
import { depositDictionaryItem as globalDepositDictionaryItem } from './global';
import { MarketSettings } from './market-settings.types';
import { BREAKDOWN_ITEMS } from '../price-breakdown.constants';
const {
  CONTRACT_LENGTH,
  MILEAGE,
  AMOUNT_OF_CREDIT,
  DEPOSIT,
  DISCOUNT,
  EXCESS_MILEAGE_RATE,
  FINAL_PAYMENT,
  PAYABLE_AMOUNT,
  PURCHASE_PRICE,
  APR,
  PAF,
} = BREAKDOWN_ITEMS;

export const allowDepositInAfterCheckoutBreakdown: MarketSettingsAsFunctions['allowDepositInAfterCheckoutBreakdown'] =
  ({ customerType, salesModel }) => {
    return (
      salesModel?.toLocaleLowerCase() === SALES_MODELS.CASH &&
      customerType === CUSTOMER_TYPE.b2c
    );
  };
export const depositDictionaryItem: MarketSettingsAsFunctions['depositDictionaryItem'] =
  ({ salesModel }) => {
    return [SALES_MODELS.CASH, SALES_MODELS.LEASE, SALES_MODELS.LOAN].includes(
      salesModel?.toLocaleLowerCase() as any,
    )
      ? 'PriceBreakdown.priceBreakdown.partialDeposit'
      : globalDepositDictionaryItem({ salesModel });
  };
export const enableTwoPartDeposit: MarketSettingsAsFunctions['enableTwoPartDeposit'] =
  ({ salesModel }) =>
    salesModel?.toLocaleLowerCase() === SALES_MODELS.LOAN ||
    salesModel?.toLocaleLowerCase() === SALES_MODELS.LEASE;

export const enableDepositInPayMonthlyBreakdown: MarketSettingsAsFunctions['enableDepositInPayMonthlyBreakdown'] =
  ({ salesModel }) =>
    salesModel?.toLocaleLowerCase() === SALES_MODELS.LOAN ||
    salesModel?.toLocaleLowerCase() === SALES_MODELS.LEASE;

export const enableDiscountInFinancingDetails: MarketSettingsAsFunctions['enableDiscountInFinancingDetails'] =
  ({ salesModel }) =>
    salesModel?.toLocaleLowerCase() === SALES_MODELS.LOAN ||
    salesModel?.toLocaleLowerCase() === SALES_MODELS.LEASE;

export const enableDiscountInPayLater: MarketSettings['enableDiscountInPayLater'] =
  true;

export const supportedFinancialBreakdownItems: MarketSettings['supportedFinancialBreakdownItems'] =
  [
    FINAL_PAYMENT,
    CONTRACT_LENGTH,
    DEPOSIT,
    MILEAGE,
    DISCOUNT,
    PURCHASE_PRICE,
    PAYABLE_AMOUNT,
    EXCESS_MILEAGE_RATE,
    PAF,
    AMOUNT_OF_CREDIT,
    APR,
  ];

export const showFollowsCarExtras: MarketSettingsAsFunctions['showFollowsCarExtras'] =
  ({ salesModel }) => salesModel === SALES_MODELS.CASH;

export const showFollowsCustomerExtras: MarketSettingsAsFunctions['showFollowsCustomerExtras'] =
  ({ salesModel }) =>
    salesModel !== SALES_MODELS.LEASE && salesModel !== SALES_MODELS.LOAN;
export const showServicePlans: MarketSettingsAsFunctions['showServicePlans'] =
  ({ salesModel }) => salesModel === SALES_MODELS.CASH;
export const enableBenefitInKind: MarketSettingsAsFunctions['enableBenefitInKind'] =
  ({ customerType }) => customerType === CUSTOMER_TYPE.b2b;
