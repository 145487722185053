import { FALLBACK_LOCALE } from './is-valid-locale.constants';
import { getAllMarketSites } from '@volvo-cars/market-sites';

// TODO: A smiliar function might end up in @volvo-cars/market-sites. If so, remove this one.
export const isValidLocale = (locale: string | null | undefined) => {
  return (
    getAllMarketSites().some((marketSite) => marketSite.locale === locale) ||
    locale === FALLBACK_LOCALE
  );
};
