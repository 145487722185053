import { GetOfferSelectorAPIEndpointHeadersProps } from './offers.types';

type ObjectType = { [key: string | number]: any };

export const isEmpty = (obj: ObjectType) => !Object.keys(obj).length;

// Returns true if obj1 is a subset of obj2
export const isSubsetOf = (obj1: ObjectType, obj2: ObjectType) =>
  Object.entries(obj1).every(
    ([key, value]: [key: string, value: string]) => obj2[key] === value,
  );

export const capitalizeString = (str: string) => {
  return str
    .split(' ')
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(' ');
};

export const getOfferSelectorAPIEndpointHeaders = ({
  deployEnv,
  testwwwHeader,
  qawwwHeader,
  clientName,
}: GetOfferSelectorAPIEndpointHeadersProps) => ({
  ...(deployEnv !== 'prod'
    ? {
        testwww: testwwwHeader ?? '',
        qawww: qawwwHeader ?? '',
      }
    : {}),
  'x-client-name': clientName,
});

export const getTestIdFromContentField = (contentField: string) => {
  return contentField?.replace(
    /([A-Z])/g,
    (capture) => '-' + capture?.toLocaleLowerCase(),
  );
};
