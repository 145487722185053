import React from 'react';
import { FinanceDetailsWithCustomData } from './finance-details.custom';
import { FinanceDetailsWithDefaultData } from './finance-details.default';
import { FinanceDetailsInputProps } from './finance-details.types';
import { FinanceDetailsWithoutToken } from './finance-details.without-token';
import { useContent } from '@vcc-package/offers-provider';

export const FinanceDetails = ({
  customerType = '',
  salesModel = '',
  onViewChange,
  financeDetails,
  token,
  model,
  styles,
  testId,
  translate: customTranslate,
}: FinanceDetailsInputProps) => {
  const { translate } = useContent();
  if (financeDetails) {
    return (
      <FinanceDetailsWithCustomData
        financeDetails={financeDetails}
        onViewChange={onViewChange}
        translate={customTranslate ?? translate}
        styles={styles}
        testId={testId}
      />
    );
  }

  if (token) {
    return (
      <FinanceDetailsWithDefaultData
        token={token}
        onViewChange={onViewChange}
        styles={styles}
        testId={testId}
      />
    );
  }

  return (
    <FinanceDetailsWithoutToken
      onViewChange={onViewChange}
      customerType={customerType}
      salesModel={salesModel}
      model={model}
      styles={styles}
      testId={testId}
    />
  );
};
