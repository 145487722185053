import { useCallback, useMemo } from 'react';
import { OfferQuery } from '../../queries/fed-graph/entire-offer.query.gql-types';
import { Product } from '@vcc-www/federated-graph-types';
import { ADDITIONAL_TAGS } from '../entire-offer.constants';
import { useSettings } from '../../settings/offers-provider.settings.provider';
import { useContent } from '../../content/offers-provider.content.provider';

export const useEntireOfferCarAdditionals = ({
  offerData,
}: {
  offerData?: OfferQuery;
}) => {
  const { settingIncludedServicePlanPrice } = useSettings();
  const { translate } = useContent();
  const { accessories, packages, services } =
    offerData?.offerByToken?.products || {};

  const optionalAdditionals = {
    accessories: accessories?.filter((a) => a?.optional) ?? [],
    packages: packages?.filter((p) => p?.optional) ?? [],
    services: services?.filter((s) => s?.optional) ?? [],
  };

  const adaptProductOutput = useCallback(
    (product: Product | any) => ({
      id: product.id,
      displayName: product.content?.displayName?.value ?? '',
      displayNameSources: ['data:product.content.displayName.value'],
      price: product.priceSummary?.price?.displayPrice?.amount ?? 0,
      priceText:
        (product.priceSummary?.price?.displayPrice?.amount &&
          product.optional) ||
        (product.tags?.includes(ADDITIONAL_TAGS.VSA) &&
          settingIncludedServicePlanPrice)
          ? product.priceSummary?.price?.displayPrice?.display
          : translate('Cart.additionalPurchases.included'),
      vatAmount: product.priceSummary?.price?.vatAmount?.amount ?? '',
      priceTextSources:
        product.__typename === 'OfferPackage' &&
        product.priceSummary?.price?.displayPrice?.display &&
        (product.optional ||
          (product.tags?.includes(ADDITIONAL_TAGS.VSA) &&
            settingIncludedServicePlanPrice))
          ? ['data:product.priceSummary.price.displayPrice.display']
          : ['sharedDict:Cart.additionalPurchases.included'],
      tags: product.tags,
      components: product?.items
        ?.filter((i: any) => i?.content?.displayName?.value)
        ?.map((i: any) => i?.content?.displayName?.value),
      images: [
        {
          url: product.content?.image?.url ?? '',
          alt: product.content?.displayName?.value ?? '',
        },
      ],
      imagesSources: ['data:product.content.image.url'],
      isUserAdded: product.optional ?? false,
    }),
    [settingIncludedServicePlanPrice, translate],
  );

  const accessoriesData = useMemo(
    () => ({
      followsCustomer: optionalAdditionals?.accessories
        .map(adaptProductOutput)
        .filter((a) => a.tags?.includes(ADDITIONAL_TAGS.FOLLOWS_CUSTOMER)),
      followsCar: optionalAdditionals?.accessories // Question: Will any accessories here follow the car?
        .map(adaptProductOutput)
        .filter((a) => a.tags?.includes(ADDITIONAL_TAGS.FOLLOWS_CAR)),
    }),
    [optionalAdditionals?.accessories, adaptProductOutput],
  );

  const packagesData = useMemo(
    () => ({
      followsCustomer: optionalAdditionals?.packages
        .map(adaptProductOutput)
        .filter((p) => p.tags?.includes(ADDITIONAL_TAGS.FOLLOWS_CUSTOMER)),
      followsCar: optionalAdditionals?.packages
        .map(adaptProductOutput)
        .filter((p) => p.tags?.includes(ADDITIONAL_TAGS.FOLLOWS_CAR)),
    }),
    [optionalAdditionals?.packages, adaptProductOutput],
  );

  const servicesData = useMemo(() => {
    // TODO: Do we even need this? Or is all the services we need in the packages data?
    const services = {
      followsCar: optionalAdditionals?.services
        .map(adaptProductOutput)
        .filter((s) => s.tags?.includes(ADDITIONAL_TAGS.FOLLOWS_CAR)),
    };

    const packageServices =
      packages
        ?.map(adaptProductOutput)
        .filter((p) => p.tags?.includes(ADDITIONAL_TAGS.VSA)) ?? [];

    return [...services.followsCar, ...packageServices];
  }, [optionalAdditionals?.services, packages, adaptProductOutput]);

  const additionals = {
    accessories: accessoriesData,
    packages: packagesData,
    services: servicesData,
  };

  return additionals;
};
