export const LOG_SERVICES = {
  API_ORDER_CHECKOUT: '/api/order/checkout',
  API_ORDER_CONTENT: '/api/order/content',
  API_ORDER_ENERGY_DECLARATION: '/api/order/energy-declaration',
  API_ORDER_MODELS: '/api/order/models',
  API_ORDER_PRICES: '/api/order/prices',
  API_ORDER_SESSION: '/api/order/session',
  APPS_MODULES_APOLLO_CLIENT_GET: 'modules/apollo-client/getApolloClient',
  APPS_MODULES_CONTENT_MAKE_API_CALL: 'modules/content/makeApiCall',
  APPS_MODULES_FLEET_SESSION_GET_FLEET_SESSION_DATA:
    'modules/fleet-session/getFleetSessionData',
  APPS_MODULES_MODELS_GET_MODELS: 'modules/models/getModels',
  APPS_MODULES_PRICES_GET_PRICES: 'modules/prices/getPrices',
  APPS_MODULES_PRIMARY_CTA_GET_CHECKOUT_URL:
    'modules/primary-cta/getCheckoutUrl',
  APPS_MODULES_REQUESTED_DELIVERY_DATE_GET_DATA:
    'modules/requested-delivery-date/getData',
  APPS_MODULES_SERVER_SIDE_PROPS_GET_ORDER_SERVER_SIDE_PROPS:
    'modules/server-side-props/getOrderServerSideProps',
  APPS_MODULES_SERVER_SIDE_PROPS_GET_ORDER_SERVER_SIDE_PROPS_GET_BASE_OFFER_GET_FLEET_SESSION_DATA:
    'modules/server-side-props/getOrderServerSideProps/getBaseOfferData-getFleetSessionData',
  APPS_MODULES_SERVER_SIDE_PROPS_GET_ORDER_SERVER_SIDE_PROPS_GET_FEATURE_FLAGS:
    'modules/server-side-props/getOrderServerSideProps/getFeatureFlags',
  APPS_MODULES_SERVER_SIDE_PROPS_GET_ORDER_SERVER_SIDE_PROPS_GET_MARKET_SETTINGS:
    'modules/server-side-props/getOrderServerSideProps/getMarketSettings',
  APPS_MODULES_SERVER_SIDE_PROPS_GET_ORDER_SERVER_SIDE_PROPS_GET_PAGE_CONTENT:
    'modules/server-side-props/getOrderServerSideProps/getPageContent',
  APPS_MODULES_SERVER_SIDE_PROPS_GET_ORDER_SERVER_SIDE_PROPS_GET_PAGE_CONTENT_GET_INCENTIVES_FROM_FED_GRAPH:
    'modules/server-side-props/getOrderServerSideProps/getPageContent/getIncentivesFromFedGraph',
  APPS_MODULES_REDIRECT_BASED_ON_OFFER_NOT_FOUND:
    'apps/offer-selector/src/utils/server-side/server-side.utils.ts/redirectBasedOnOfferNotFound',
  APPS_MODULES_REDIRECT_BASED_ON_INVALID_MARKET:
    'apps/offer-selector/src/utils/server-side/server-side.utils.ts/redirectBasedOnInvalidMarket',
  SHARED_FLEET_SESSION_GET_FLEET_SESSION_DATA:
    'offers-utils/data/fleet-session/getFleetSessionData',
  APPS_MODULES_VCC_SESSION_VALIDATE_VCC_SESSION:
    'modules/fleet-session/validateVccSession',
  APPS_OFFER_SELECTOR_MODULES_URLS_SHORTENING:
    'apps/offer-selector/modules/urls/shortening',
  APPS_OFFER_SELECTOR_OFFERS_LEGAL_CONTENT:
    'apps/offer-selector/src/modules/legal-content/legal-content.ts',
  APPS_OFFER_SELECTOR_OFFERS_SET_DISCOUNT_SESSION:
    'apps/offer-selector/src/utils/set-discount-session.ts',
  APPS_UTILS_ELASTIC: 'apps/utils/elastic',
  DOCUMENT_SITE_NAV: 'fetchSiteNavigationProps or fetchSiteFooterProps error',
  ERROR_BOUNDARY: 'ErrorBoundary',
  ENERGY_DECLARATION_GET_ENERGY_DECLARATION_DATA:
    'energy-declaration/getEnergyDeclarationData',
  FEDERATED_GRAPH_CLIENT: 'fedGraphClient',
  OFFER_SELECTOR_FEATURE_FLAGS_AND_SETTINGS_GET_FEATURE_FLAGS:
    'offer-selector/feature-flags-and-settings/feature-flags-and-settings/getFeatureFlagProps',
  PAGE_ENERGY_DECLARATION: 'order/energy-declaration/page',
  SHARED_CART_GET_CART_DATA: 'shared/cart/getCartData',
  SHARED_COPY_LINK: 'shared/copy-link',
  SHARED_OFFERS_PROVIDER_UPDATE_FLEET_SESSION:
    'shared/offers-provider/src/fleet-session/offers-provider.fleet-session.utils.ts',
  SHARED_OFFERS_PROVIDER_USE_ENTIRE_OFFER:
    'shared/offers-provider/useEntireOffer',
  SHARED_OFFERS_PROVIDERS_MODELS_HOOK: 'shared/offers-provider/models-hook',
  SHARED_OFFERS_PROVIDERS_USE_OFFERS_PROVIDER_CONTENT:
    'shared/offers-provider/useOffersProviderContent',
  SHARED_OFFER_SELECTOR: 'shared/offer-selector/',
  SHARED_OFFER_SELECTOR_CHECKOUT_GET_BUNDLE_FROM_COMMERCIAL_ITEM:
    'shared/offer-selector-checkout/getBundleFromCommercialItem',
  SHARED_OFFER_SELECTOR_CHECKOUT_GET_CHECKOUT_PAGE_URL:
    'shared/offer-selector-checkout/getCheckoutPageUrl',
  SHARED_OFFER_SELECTOR_CHECKOUT_GET_FOLLOW_CUSTOMER_ACCESSORIES:
    'shared/offer-selector-checkout/getFollowTheCustomerAccessories',
  SHARED_OFFER_SELECTOR_CHECKOUT_GET_OR_CREATE_COMMERCIAL_ITEM:
    'shared/offer-selector-checkout/getOrCreateCommercialItem',
  SHARED_OFFER_SELECTOR_CHECKOUT_GET_PRODUCT:
    'shared/offer-selector-checkout/storefront-temp//getProduct',
  SHARED_OFFER_SELECTOR_CHECKOUT_GET_PRODUCT_BY_ID:
    'shared/offer-selector-checkout/getProductById',
  SHARED_OFFER_SELECTOR_CHECKOUT_GET_PRODUCT_LINES_FROM_CAR_TOKEN:
    'shared/offer-selector-checkout/getProductLinesFromCarToken',
  SHARED_OFFER_SELECTOR_GET_STOREFRONT_CHECKOUT_URL:
    'shared/offer-selector-checkout/getStoreFrontCheckoutUrl',
  SHARED_OFFER_SELECTOR_RETURN_STOREFRONT_CHECKOUT_URL:
    'shared/offer-selector-checkout/returnStorefrontCheckoutUrl',
  SHARED_OFFER_SELECTOR_SESSION_CLIENT:
    'shared/offer-selector-checkout/storefront-temp/sessionClient',
  SHARED_PRICE_BREAKDOWN_DATA: 'shared/price-breakdown',
  SHARED_OFFER_SELECTOR_STOREFRONT_CLIENT:
    'shared/offer-selector-checkout/storefront-temp/storefrontClient',
} as const;

export type LogServices = (typeof LOG_SERVICES)[keyof typeof LOG_SERVICES];
