import { BUSINESS_MODELS, SALES_MODELS } from '@vcc-package/offers-utils';
import type {
  MarketSettings,
  MarketSettingsAsFunctions,
  MarketSettingsInputParams,
} from './market-settings.types';
import { BREAKDOWN_ITEMS } from '../price-breakdown.constants';
const {
  FINAL_PAYMENT,
  CONTRACT_LENGTH,
  MILEAGE,
  DEPOSIT,
  DISCOUNT,
  PURCHASE_PRICE,
  PAYABLE_AMOUNT,
  EXCESS_MILEAGE_RATE,
  PAF,
  AMOUNT_OF_CREDIT,
  APR,
} = BREAKDOWN_ITEMS;

export const supportsVat: MarketSettings['supportsVat'] = true;
export const allowDepositInAfterCheckoutBreakdown: MarketSettings['allowDepositInAfterCheckoutBreakdown'] =
  false;
export const depositDictionaryItem: MarketSettingsAsFunctions['depositDictionaryItem'] =
  ({ salesModel }: MarketSettingsInputParams) =>
    salesModel?.toLocaleLowerCase() === SALES_MODELS.CASH
      ? 'PriceBreakdown.priceBreakdown.refundableDeposit'
      : 'PriceBreakdown.priceSummary.firstMonthlyPayment';
export const enableTwoPartDeposit: MarketSettings['enableTwoPartDeposit'] =
  false;
export const priceAnnotationCharacter: MarketSettings['priceAnnotationCharacter'] =
  '';
export const showTotalPriceAsDueBeforeDeliveryTitle: MarketSettings['showTotalPriceAsDueBeforeDeliveryTitle'] =
  false;
export const showRegularModelTitle: MarketSettings['showRegularModelTitle'] =
  false;
export const showFinancingDetails: MarketSettings['showFinancingDetails'] =
  true;
export const showDownPaymentAsOnlyAfterCheckoutItem: MarketSettings['showDownPaymentAsOnlyAfterCheckoutItem'] =
  false;
export const enableDepositInPayMonthlyBreakdown: MarketSettings['enableDepositInPayMonthlyBreakdown'] =
  false;
export const showFirstMonthlyPaymentLineItemInPayLater: MarketSettings['showFirstMonthlyPaymentLineItemInPayLater'] =
  false;
export const taxesTypeInPayLater: MarketSettingsAsFunctions['taxesTypeInPayLater'] =
  ({ businessModel }) =>
    businessModel === BUSINESS_MODELS.DIRECT ? 'transaction' : null;
export const enableEditConfiguration: MarketSettings['enableEditConfiguration'] =
  false;
export const enableDiscountInPayLater: MarketSettings['enableDiscountInPayLater'] =
  false;
export const enableDiscountInFinancingDetails: MarketSettings['enableDiscountInFinancingDetails'] =
  false;
export const supportedFinancialBreakdownItems: MarketSettings['supportedFinancialBreakdownItems'] =
  [
    CONTRACT_LENGTH,
    MILEAGE,
    AMOUNT_OF_CREDIT,
    DEPOSIT,
    DISCOUNT,
    EXCESS_MILEAGE_RATE,
    FINAL_PAYMENT,
    PAYABLE_AMOUNT,
    PURCHASE_PRICE,
    APR,
    PAF,
  ];
export const showFollowsCarExtras: MarketSettings['showFollowsCarExtras'] =
  false;
export const showFollowsCustomerExtras: MarketSettings['showFollowsCustomerExtras'] =
  false;
export const showServicePlans: MarketSettings['showServicePlans'] = false;
export const roundingStrategy: MarketSettings['roundingStrategy'] = null;
export const enableBenefitInKind: MarketSettings['enableBenefitInKind'] = false;
export const enableTotalCostOfOwnership: MarketSettings['enableTotalCostOfOwnership'] =
  false;
