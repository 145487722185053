import { useEffect } from 'react';
import { useSettings } from '../../settings/offers-provider.settings.provider';
import { CUSTOMER_TYPE } from '../../offers.constants';
import { useFleetSession } from '../../fleet-session/offers-provider.fleet-session.provider';
import { UseFleetTokenUpdateProps } from '../entire-offer.types';

export const useFleetTokenUpdate = ({
  offerData,
  offerSalesModel,
  onFleetTokenUpdate,
  isStockCar,
  vehicleId,
}: UseFleetTokenUpdateProps) => {
  const configuration = offerData?.offerByToken?.configuration;
  const { enableRedirectToB2BOffer, permittedSalesModels } = useSettings();
  const { session } = useFleetSession();

  useEffect(() => {
    // Redirect to a B2B token if Fleet session user is active
    if (enableRedirectToB2BOffer && !!configuration?.selections?.length) {
      const b2bEquivalentToken = configuration?.selections?.find(
        ({ type, identifier }) =>
          type === 'CustomerType' && identifier === CUSTOMER_TYPE.B2B,
      )?.appliedChange?.token?.short;

      if (typeof onFleetTokenUpdate === 'function') {
        if (isStockCar) {
          onFleetTokenUpdate({
            token: vehicleId ?? '',
            offerToken: b2bEquivalentToken ?? '',
          });
        } else {
          onFleetTokenUpdate({ token: b2bEquivalentToken ?? '' });
        }
      }
    }
  }, [
    configuration?.selections,
    enableRedirectToB2BOffer,
    isStockCar,
    onFleetTokenUpdate,
    vehicleId,
  ]);

  useEffect(() => {
    // if isFleetKeyAccount true and if the current token does not contain 'CASH' offer then upgrade token to CASH offer
    // permittedSalesModels will contain only 'CASH' if isFleetKeyAccount true
    if (
      session?.sharedData?.fleetIsKeyAccount &&
      offerSalesModel &&
      !permittedSalesModels?.includes(offerSalesModel) &&
      !!configuration?.selections?.length
    ) {
      let tokenToUse = '';
      let offerTokenToUse = '';
      const equivalentToken = configuration?.selections?.find(
        ({ type, identifier }) =>
          type === 'SalesModel' && identifier === permittedSalesModels?.[0],
      )?.appliedChange?.token?.short;
      if (isStockCar) {
        tokenToUse = vehicleId ?? '';
        offerTokenToUse = equivalentToken ?? '';
      } else {
        tokenToUse = equivalentToken ?? '';
      }
      typeof onFleetTokenUpdate === 'function' &&
        onFleetTokenUpdate({ token: tokenToUse, offerToken: offerTokenToUse });
    }
  }, [
    configuration?.selections,
    isStockCar,
    offerSalesModel,
    onFleetTokenUpdate,
    permittedSalesModels,
    session?.sharedData?.fleetIsKeyAccount,
    vehicleId,
  ]);
};
