import {
  PRICE_BREAKDOWN_SECTIONS,
  PriceBreakdownSectionProps,
} from '../entire-offer/entire-offer.types';
import { useContent } from '../content/offers-provider.content.provider';
import { useSettings } from '../settings/offers-provider.settings.provider';
import { useMemo } from 'react';
import { usePriceLabels } from '../hooks/usePriceLabels';
import { UseRecurringInputProps } from './price-breakdown.types';
import { PRICE_SUMMARY_TEST_IDS } from './price-breakdown.constants';

const TEST_ID = PRICE_SUMMARY_TEST_IDS.DUE_MONTHLY;

export const useRecurring = ({
  discountRateDisplay,
  discountRateDisplaySources,
  financingPriceSummary,
  isDiscountApplied,
  isLeaseOrLoan,
  priceSummary,
  vehiclePrice,
}: UseRecurringInputProps): PriceBreakdownSectionProps => {
  const { translate } = useContent();
  const { supportsVAT } = useSettings();
  const { getVatLabel, getVatLabelSources, totalLabel, totalLabelSources } =
    usePriceLabels();
  const totalPrice = priceSummary?.price?.displayPrice;

  // Monthly Price Text for Lease and Loan (PCP/VL)
  const monthlyPriceTextForLeaseAndLoan =
    financingPriceSummary?.breakdown?.perInstallmentPrice?.display ?? '';
  const monthlyPriceForLeaseAndLoan =
    financingPriceSummary?.breakdown?.perInstallmentPrice?.amount ?? 0;

  // Monthly VAT Amount for Lease and Loan (PCP/VL)
  const monthlyVatAmountForLeaseAndLoan = 0; // There is no data available for this one

  // Monthly Price Text for all sales models except Lease and Loan (PCP/VL)
  const monthlyPriceText =
    priceSummary?.aggregations?.total?.paymentPlan?.recurring?.displayPrice
      ?.display ?? '';
  const monthlyPrice =
    priceSummary?.aggregations?.total?.paymentPlan?.recurring?.displayPrice
      ?.amount ?? 0;

  const isPriceIncludingVAT = isLeaseOrLoan
    ? financingPriceSummary?.breakdown?.perInstallmentPrice?.includingVat
    : priceSummary?.aggregations?.total?.paymentPlan?.recurring?.displayPrice
        ?.includingVat;

  // Monthly VAT amount for all sales models except Lease and Loan (PCP/VL)
  const monthlyVat =
    priceSummary?.aggregations?.total?.paymentPlan?.recurring?.vatAmount;

  const recurringPriceText = isLeaseOrLoan
    ? monthlyPriceTextForLeaseAndLoan
    : monthlyPriceText;
  const recurringPrice = isLeaseOrLoan
    ? monthlyPriceForLeaseAndLoan
    : monthlyPrice;

  const recurringVatAmount = isLeaseOrLoan
    ? monthlyVatAmountForLeaseAndLoan
    : monthlyVat?.amount ?? 0;

  const shouldShowSection = isLeaseOrLoan
    ? !!financingPriceSummary?.breakdown?.perInstallmentPrice?.amount
    : !!priceSummary?.aggregations?.total?.paymentPlan?.recurring?.displayPrice
        ?.amount;

  // Monthly VAT Price Text for Lease and Loan (PCP/VL)
  const monthlyVatPriceForLeaseAndLoan = '';

  const recurringVAT = isLeaseOrLoan
    ? monthlyVatPriceForLeaseAndLoan
    : monthlyVat?.display ?? '';

  const shouldShowVAT = supportsVAT && recurringVatAmount > 0;

  const monthlyTotalPriceTextWithVat =
    priceSummary?.aggregations?.total?.paymentPlan?.recurring?.priceInclTax
      ?.display ?? '';
  const monthlyTotalPriceWithVat =
    priceSummary?.aggregations?.total?.paymentPlan?.recurring?.priceInclTax
      ?.amount ?? 0;

  const recurringTotalPriceTextWithVAT = isLeaseOrLoan
    ? recurringPriceText
    : monthlyTotalPriceTextWithVat;
  const recurringTotalPriceWithVAT = isLeaseOrLoan
    ? recurringPrice
    : monthlyTotalPriceWithVat;

  const vatLabel = getVatLabel(
    !!totalPrice?.includingVat && !!isPriceIncludingVAT,
  );
  const vatLabelSources = getVatLabelSources(
    !!totalPrice?.includingVat && !!isPriceIncludingVAT,
  );

  return useMemo(
    () => ({
      sectionType: PRICE_BREAKDOWN_SECTIONS.MONTHLY,
      shouldShowSection,
      price: recurringPrice,
      priceText: recurringPriceText,
      priceTextSources: ['data:recurringPriceText'],
      displayName: translate('PriceBreakdown.priceSummary.dueMonthly'),
      displayNameSources: ['sharedDict:PriceBreakdown.priceSummary.dueMonthly'],
      testId: TEST_ID,
      vatLabel,
      vatLabelSources,
      priceBreakdownItems: [
        {
          displayName: translate(
            'PriceBreakdown.priceBreakdown.monthlyPayment',
          ),
          displayNameSources: [
            'sharedDict:PriceBreakdown.priceBreakdown.monthlyPayment',
          ],
          ...(isDiscountApplied && !isLeaseOrLoan
            ? {
                vatLabel: vehiclePrice.vatLabelBeforeDiscount,
                vatLabelSources:
                  vehiclePrice.vatLabelBeforeDiscountSources.concat(
                    discountRateDisplaySources,
                  ),
                testId: `${TEST_ID}-price-before-discount`,
                price: vehiclePrice.beforeDiscountAmount ?? 0,
                priceText: vehiclePrice.beforeDiscount ?? '',
                priceTextSources: ['data:discounts.priceBeforeDiscount'],
                shouldShowVatLabel: vehiclePrice.vatAmountBeforeDiscount > 0,
              }
            : {
                vatLabel,
                vatLabelSources,
                price: recurringPrice,
                priceText: recurringPriceText,
                testId: `${TEST_ID}-monthly-payment`,
                priceTextSources: ['data:recurringPriceText'],
                shouldShowVatLabel: recurringVatAmount > 0,
              }),
        },
        ...(isDiscountApplied && !isLeaseOrLoan
          ? [
              {
                displayName: `${translate(
                  'PriceBreakdown.priceBreakdown.discount',
                  {
                    discount: discountRateDisplay,
                  },
                )}`,
                displayNameSources: [
                  'sharedDict:PriceBreakdown.priceBreakdown.discount',
                  'data:discountRateDisplay',
                ],
                testId: `${TEST_ID}-discount`,
                vatLabel,
                vatLabelSources: vatLabelSources.concat(
                  discountRateDisplaySources,
                ),
                price: -(vehiclePrice.discountAmountValue ?? 0),
                priceText: `- ${vehiclePrice.discountAmount}`,
                priceTextSources: [
                  'text:-',
                  'data:priceSummary.discountAmount',
                ],
                shouldShowVatLabel: vehiclePrice.discountVatAmount > 0,
                hasEmphasis: true,
              },
            ]
          : []),
      ],
      totalBreakdownItem: {
        displayName: translate('PriceBreakdown.priceBreakdown.total'),
        displayNameSources: ['sharedDict:PriceBreakdown.priceBreakdown.total'],
        vatLabel,
        vatLabelSources,
        price: recurringPrice,
        priceText: recurringPriceText,
        priceTextSources: ['data:recurringPriceText'],
        hasEmphasis: true,
        testId: `${TEST_ID}-total`,
      },
      totalAndVatSection: {
        items: [
          /** VAT title and amount */
          {
            shouldShowSection: shouldShowVAT,
            title: translate('PriceBreakdown.priceBreakdown.totalVat'),
            titleSources: ['sharedDict:PriceBreakdown.priceBreakdown.totalVat'],
            price: recurringVatAmount,
            priceText: recurringVAT,
            priceTextSources: ['data:recurringVAT'],
            testId: `${TEST_ID}-total-vat`,
          },
          /** Total title and amount */
          {
            title: totalLabel,
            titleSources: totalLabelSources,
            price: recurringTotalPriceWithVAT,
            priceText: recurringTotalPriceTextWithVAT,
            priceTextSources: ['data:recurringTotalPriceWithVAT'],
            testId: `${TEST_ID}-total-price-with-vat`,
          },
        ],
      },
    }),
    [
      shouldShowSection,
      recurringPrice,
      recurringPriceText,
      translate,
      vatLabel,
      vatLabelSources,
      isDiscountApplied,
      isLeaseOrLoan,
      vehiclePrice.vatLabelBeforeDiscount,
      vehiclePrice.vatLabelBeforeDiscountSources,
      vehiclePrice.beforeDiscountAmount,
      vehiclePrice.beforeDiscount,
      vehiclePrice.vatAmountBeforeDiscount,
      vehiclePrice.discountAmountValue,
      vehiclePrice.discountAmount,
      vehiclePrice.discountVatAmount,
      discountRateDisplaySources,
      recurringVatAmount,
      discountRateDisplay,
      shouldShowVAT,
      recurringVAT,
      totalLabel,
      totalLabelSources,
      recurringTotalPriceWithVAT,
      recurringTotalPriceTextWithVAT,
    ],
  );
};
