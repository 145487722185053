'use client';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { type OverlayPageType } from './overlay.types';
import { useBreakpoints } from '@vcc-www/hooks/useBreakpoints';

export const useOverlayProvider = () => {
  const [stack, setStack] = useState<OverlayPageType[]>([]);
  const { fromL } = useBreakpoints(); // eslint-disable-line vcc-www/use-breakpoints

  const currentPage = useMemo(() => {
    return stack[stack.length - 1] ?? null;
  }, [stack]);

  const openOverlayPage = useCallback((page: OverlayPageType) => {
    setStack((prevStack) => [...prevStack, page]);
  }, []);

  const goBack = useCallback(() => {
    if (stack.length > 1) {
      setStack(stack.slice(0, -1));
    }
  }, [stack]);

  const resetAndCloseOverlay = useCallback(() => {
    setStack([]);
    currentPage?.onClose?.();
  }, [currentPage]);

  const properties = useMemo(
    () => ({ openOverlayPage, resetAndCloseOverlay }),
    [openOverlayPage, resetAndCloseOverlay],
  );

  const currentPageContent = useMemo(() => {
    if (currentPage?.component) {
      return currentPage.component;
    }
  }, [currentPage]);

  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    const dialog = dialogRef.current;

    if (dialog) {
      dialog.addEventListener('cancel', resetAndCloseOverlay);
      dialog.addEventListener('close', resetAndCloseOverlay);
    }
    return () => {
      dialog?.removeEventListener?.('cancel', resetAndCloseOverlay);
      dialog?.removeEventListener?.('close', resetAndCloseOverlay);
    };
  }, [dialogRef, resetAndCloseOverlay]);

  useEffect(() => {
    const dialog = dialogRef.current;
    currentPage ? dialog?.showModal?.() : dialog?.close?.();
  }, [currentPage, dialogRef]);

  return useMemo(
    () => ({
      showBackButton: stack.length > 1,
      currentPage,
      currentPageContent,
      goBack,
      properties,
      fromL,
      dialogRef,
    }),
    [
      currentPage,
      currentPageContent,
      fromL,
      goBack,
      properties,
      stack.length,
      dialogRef,
    ],
  );
};
