'use client';
import { cssJoin } from '@volvo-cars/css/utils';
import { SharedComponentsDictionariesProvider } from '@vcc-www/shared-dictionaries/DictionariesProvider';
import Video from '@vcc-www/video';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useSpringCarousel } from 'react-spring-carousel';
import styles from '../finance-details.module.css';

import {
  FinanceDetailsViewHowItWorksProps,
  HowItWorksCarouselProps,
  HowItWorksKeyPoint,
} from '../finance-details.types';

const HowItWorksKeyPointCard = ({
  title,
  image,
  shortDescription,
  width,
  videoUrl,
}: HowItWorksKeyPoint) => {
  return (
    <div style={{ width: width }} className="flex-col gap-16">
      {videoUrl ? (
        <SharedComponentsDictionariesProvider
          locale="en"
          dictionaries={{
            'VideoPlayer.controls.pause': 'pause',
            'VideoPlayer.controls.play': 'play',
          }}
        >
          <Video
            srcMp4={videoUrl}
            data-autoid="finance-details:video"
            data-testid="finance-details:video"
            aspectRatio={{
              default: [16, 9],
            }}
            shouldAutoplay
            loop
            forceLoad
            muted
          />
        </SharedComponentsDictionariesProvider>
      ) : image ? (
        <img src={image} alt="" className="aspect-4/3 object-cover w-full" />
      ) : null}
      <h3 className="font-medium">{title}</h3>
      <p>{shortDescription}</p>
    </div>
  );
};

const HowItWorksCarousel = ({ carouselItems }: HowItWorksCarouselProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current?.clientWidth || 0);
    const getWidth = () => {
      setWidth(ref.current?.clientWidth || 0);
    };
    window.addEventListener('resize', getWidth);
    return () => window.removeEventListener('resize', getWidth);
  }, []);

  const { carouselFragment } = useSpringCarousel({
    gutter: 0.04 * width,
    itemsPerSlide: carouselItems?.length > 2 ? 2 : 1,
    items: carouselItems?.length
      ? carouselItems?.map((item: any, index: number) => {
          return {
            id: `howItWorksCarousel-${index}`,
            renderItem: (
              <HowItWorksKeyPointCard
                width={width * 0.93}
                image={item?.image}
                title={item?.title}
                shortDescription={item?.shortDescription}
                videoUrl={item?.videoUrl}
              />
            ),
          };
        })
      : [
          {
            id: 'loading',
            renderItem: <div className="flex-row" />,
          },
        ],
  });

  return (
    <div ref={ref} className="md:hidden flex-row w-full">
      {carouselFragment}
    </div>
  );
};

export const FinanceDetailsViewHowItWorks = ({
  title,
  keyPoints,
}: FinanceDetailsViewHowItWorksProps) => {
  if (!keyPoints || keyPoints?.length === 0) return null;

  return (
    <div className="flex-col gap-16">
      <h2 className="font-20 font-medium pb-16 border-b border-ornament">
        {title}
      </h2>
      <div className="flex-col md:hidden -mr-24 pt-8">
        <HowItWorksCarousel carouselItems={keyPoints} />
      </div>
      <div
        className={cssJoin(
          keyPoints?.length > 2
            ? [
                'until-md:hidden w-full grid pt-8 gap-x-24 gap-y-24',
                styles.how_it_works_grid,
              ]
            : 'until-md:hidden flex-row gap-16',
        )}
      >
        {keyPoints?.map((item: HowItWorksKeyPoint, index: number) => (
          <HowItWorksKeyPointCard
            key={`keyPoint-${index}`}
            title={item?.title}
            image={item?.image}
            shortDescription={item?.shortDescription}
            videoUrl={item?.videoUrl}
            width="100%"
          />
        ))}
      </div>
    </div>
  );
};
