import { useMemo } from 'react';
import { useSettings } from '../settings/offers-provider.settings.provider';
import {
  InterestRates,
  UseAprCampaignDataProps,
  UseDiscountInFinanceDetailsProps,
  UseDiscountInPriceBreakdownProps,
  UseDiscountsInputProps,
} from './price-breakdown.types';
import { InterestRateStep } from '@vcc-www/federated-graph-types';

export const useDiscounts = ({
  downPayment,
  financingPriceSummary,
  priceSummary,
}: UseDiscountsInputProps) => {
  const {
    standardRateDisplay,
    aprDiscountRateDisplay,
    aprDiscountMinimumDownPaymentAmount,
    aprDiscountMinimumDownPaymentDisplay,
    shouldShowAprDiscountOffer,
    isDiscountAppliedApr,
  } = useAprCampaignData({ financingPriceSummary, downPayment });

  const {
    discountAmountDisplayPriceInPriceBreakdown,
    discountAmountInPriceBreakdown,
    discountRateDisplay,
    discountRateDisplaySources,
    discountVatAmount,
    isDiscountAppliedForPriceBreakdown,
    priceBeforeDiscountForPriceBreakdown,
    priceAmountBeforeDiscountForPriceBreakdown,
  } = useDiscountInPriceBreakdown({ priceSummary });

  const {
    isDiscountAppliedForFinanceDetails,
    priceBeforeDiscountInFinanceDetails,
    priceAmountBeforeDiscountInFinanceDetails,
    discountAmountDisplayPriceInFinanceDetails,
    discountAmountInFinanceDetails,
  } = useDiscountInFinanceDetails({ financingPriceSummary, priceSummary });

  return useMemo(
    () => ({
      discountAmountDisplayPrice: isDiscountAppliedForFinanceDetails
        ? discountAmountDisplayPriceInFinanceDetails
        : discountAmountDisplayPriceInPriceBreakdown,
      discountAmount: isDiscountAppliedForFinanceDetails
        ? discountAmountInFinanceDetails
        : discountAmountInPriceBreakdown,
      discountRateDisplay,
      discountRateDisplaySources,
      discountVatAmount,
      isDiscountAppliedForPriceBreakdown,
      isDiscountAppliedForFinanceDetails,
      priceBeforeDiscount: isDiscountAppliedForFinanceDetails
        ? priceBeforeDiscountInFinanceDetails
        : priceBeforeDiscountForPriceBreakdown,
      priceAmountBeforeDiscount: isDiscountAppliedForFinanceDetails
        ? priceAmountBeforeDiscountInFinanceDetails
        : priceAmountBeforeDiscountForPriceBreakdown,
      standardRateDisplay,
      aprDiscountRateDisplay,
      aprDiscountMinimumDownPaymentAmount,
      aprDiscountMinimumDownPaymentDisplay,
      shouldShowAprDiscountOffer,
      isDiscountAppliedApr,
    }),
    [
      discountAmountDisplayPriceInPriceBreakdown,
      discountAmountInPriceBreakdown,
      discountAmountDisplayPriceInFinanceDetails,
      discountAmountInFinanceDetails,
      discountRateDisplay,
      discountRateDisplaySources,
      discountVatAmount,
      isDiscountAppliedForPriceBreakdown,
      isDiscountAppliedForFinanceDetails,
      priceAmountBeforeDiscountForPriceBreakdown,
      priceBeforeDiscountForPriceBreakdown,
      priceBeforeDiscountInFinanceDetails,
      priceAmountBeforeDiscountInFinanceDetails,
      standardRateDisplay,
      aprDiscountRateDisplay,
      aprDiscountMinimumDownPaymentAmount,
      aprDiscountMinimumDownPaymentDisplay,
      shouldShowAprDiscountOffer,
      isDiscountAppliedApr,
    ],
  );
};

const useDiscountInPriceBreakdown = ({
  priceSummary,
}: UseDiscountInPriceBreakdownProps) => {
  const {
    enableDiscountInPriceBreakdown,
    enableDiscountInPriceBreakdownForFDM,
  } = useSettings();

  const discounts = priceSummary?.discounts;

  const discountAmount = discounts?.discountAmount?.displayPrice?.amount;

  const discountAmountDisplayPriceInPriceBreakdown =
    discounts?.discountAmount?.displayPrice?.display;

  const discountVatAmount = discounts?.discountAmount?.vatAmount?.amount;

  const priceBeforeDiscountForPriceBreakdown =
    discounts?.priceBeforeDiscount?.displayPrice?.display; // Total price before discount (accessories included)
  const priceAmountBeforeDiscountForPriceBreakdown =
    discounts?.priceBeforeDiscount?.displayPrice?.amount; // Total price before discount (accessories included)

  const discountRateDisplay = discounts?.nominalDiscountRate?.display;

  const discountRateDisplaySources = useMemo(
    () => ['data:offerByTokenPriceSummary.discounts.nominalDiscountRate'],
    [],
  );
  const isDiscountAppliedForPriceBreakdown =
    !!(
      enableDiscountInPriceBreakdown || enableDiscountInPriceBreakdownForFDM
    ) && !!discountAmount;

  return {
    discountAmountInPriceBreakdown: discountAmount,
    discountAmountDisplayPriceInPriceBreakdown,
    discountRateDisplay,
    discountRateDisplaySources,
    discountVatAmount,
    isDiscountAppliedForPriceBreakdown,
    priceBeforeDiscountForPriceBreakdown,
    priceAmountBeforeDiscountForPriceBreakdown,
  };
};

const useDiscountInFinanceDetails = ({
  financingPriceSummary,
  priceSummary, // TODO: Remove when POP switch to new discount data
}: UseDiscountInFinanceDetailsProps) => {
  const { enableDiscountInFinanceDetails } = useSettings();

  // PCP/VL
  const financeDiscountContribution =
    financingPriceSummary?.breakdown?.financeDiscountContribution;

  const priceBeforeDiscountInFinanceDetails =
    financingPriceSummary?.breakdown?.adjustedPreFinancingAmount?.displayPrice
      ?.display;
  const priceAmountBeforeDiscountInFinanceDetails =
    financingPriceSummary?.breakdown?.adjustedPreFinancingAmount?.displayPrice
      ?.amount;

  const discountAmount =
    financeDiscountContribution?.displayPrice?.amount ||
    priceSummary?.discounts?.discountAmount?.displayPrice?.amount;

  const discountAmountDisplayPriceInFinanceDetails =
    financeDiscountContribution?.displayPrice?.display ||
    priceSummary?.discounts?.discountAmount?.displayPrice?.display;

  const isDiscountAppliedForFinanceDetails =
    !!enableDiscountInFinanceDetails && !!discountAmount;

  return {
    isDiscountAppliedForFinanceDetails,
    priceBeforeDiscountInFinanceDetails,
    priceAmountBeforeDiscountInFinanceDetails,
    discountAmountDisplayPriceInFinanceDetails,
    discountAmountInFinanceDetails: discountAmount,
    discountAmount,
  };
};

export const useAprCampaignData = ({
  financingPriceSummary,
  downPayment,
}: UseAprCampaignDataProps) => {
  const { enableAprDiscountOffer } = useSettings();

  const interestRateSteps = useMemo(
    () => financingPriceSummary?.breakdown?.interestRateSteps ?? [],
    [financingPriceSummary?.breakdown?.interestRateSteps],
  );

  // TODO: Write test for this one.
  const { standardRate, discountRate }: InterestRates = useMemo(() => {
    if (interestRateSteps?.length < 2) {
      // If we only have one interest rate step, we don't have a discount rate.
      return { standardRate: null, discountRate: null };
    }

    return interestRateSteps
      .filter((step) => step.type === 'APR')
      .reduce(
        (accumulator: InterestRates, step: InterestRateStep) => {
          if (
            !accumulator.standardRate ||
            (step.rate?.percentage ?? 0) >
              (accumulator.standardRate.rate?.percentage ?? 0)
          ) {
            accumulator.standardRate = step;
          }
          if (
            !accumulator.discountRate ||
            (step.rate?.percentage ?? 0) <
              (accumulator.discountRate.rate?.percentage ?? 0)
          ) {
            accumulator.discountRate = step;
          }
          return accumulator;
        },
        { standardRate: null, discountRate: null },
      );
  }, [interestRateSteps]);

  const shouldShowAprDiscountOffer =
    !!enableAprDiscountOffer && !!standardRate && !!discountRate;

  const isDiscountAppliedApr =
    shouldShowAprDiscountOffer &&
    Number(downPayment?.value ?? 0) >=
      (discountRate?.minimumDownpayment?.amount ?? 0);

  return {
    standardRateDisplay: standardRate?.rate?.display,
    aprDiscountRateDisplay: discountRate?.rate?.display,
    aprDiscountMinimumDownPaymentAmount:
      discountRate?.minimumDownpayment?.amount,
    aprDiscountMinimumDownPaymentDisplay:
      discountRate?.minimumDownpayment?.display,
    shouldShowAprDiscountOffer,
    isDiscountAppliedApr,
  };
};
