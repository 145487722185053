'use client';
import React, { createContext, FC, useContext } from 'react';
import { useOffersProviderFleetSession } from './offers-provider.fleet-session.hook';
import {
  FleetProviderContextType,
  FleetProviderProps,
  UseFleetSessionProps,
} from './offers-provider.fleet-session.types';
import { getDefaultFleetSessionValues } from './offers-provider.fleet-session.utils';

export const FleetSessionContext = createContext<FleetProviderContextType>(
  getDefaultFleetSessionValues(),
);

FleetSessionContext.displayName = 'FleetSessionContext';

export const FleetSessionProvider: FC<
  React.PropsWithChildren<FleetProviderProps>
> = ({ children, ...props }) => {
  const fleetSessionData = useOffersProviderFleetSession(props);

  return (
    <FleetSessionContext.Provider value={fleetSessionData}>
      {children}
    </FleetSessionContext.Provider>
  );
};

export const useFleetSession = (props?: UseFleetSessionProps) => {
  const { benefitInKind, ...restOfFleetContext } =
    useContext(FleetSessionContext);
  if (restOfFleetContext === undefined) {
    // TODO: What do we do here?
  }
  if (
    benefitInKind?.benefitInKindProps?.handleOpen &&
    props?.openBenefitInKindModal
  ) {
    benefitInKind.benefitInKindProps.handleOpen = props?.openBenefitInKindModal;
  }

  return {
    ...restOfFleetContext,
    benefitInKind,
  };
};

export const useIsFleetSessionLoading = () => {
  const fleetSessionContext = useContext(FleetSessionContext);
  if (fleetSessionContext === undefined) {
    // TODO: What do we do here?
  }
  return fleetSessionContext?.isLoading ?? false;
};
