import { useMemo } from 'react';
import {
  getAcquisitionalTaxes,
  getFormattedTaxes,
  getIncentiveTaxes,
  getOwnershipTaxes,
  getTransactionBaseTaxes,
} from '../entire-offer/entire-offer.utils';
import { UseTaxesInputProps } from './price-breakdown.types';

export const useTaxes = ({
  isDirectSales,
  isPriceExcludingVAT,
  testId,
  priceSummary,
  isUSNewModel,
}: UseTaxesInputProps) => {
  const acquisitionTaxes = priceSummary?.carPriceSummary?.taxes?.acquisition;
  const informationalTaxes =
    priceSummary?.carPriceSummary?.taxes?.informational;
  const ownershipTaxes = priceSummary?.carPriceSummary?.taxes?.ownership;

  const transactionBaseTaxes =
    priceSummary?.carPriceSummary?.transactionBaseTaxes?.map((item: any) => ({
      id: item?.id ?? '',
      pretaxWithTaxes: item?.pretaxWithTaxes ?? {},
      pretaxWithTaxesExclVat: item?.pretaxWithTaxesExclVat ?? {},
      taxId: item?.taxId ?? '',
    }));
  /** Taxes in a proper format */
  const acquisitionalTaxes = getFormattedTaxes({
    taxes: getAcquisitionalTaxes(acquisitionTaxes),
    testId,
  });

  const transactionalTaxes = getFormattedTaxes({
    taxes: getTransactionBaseTaxes(transactionBaseTaxes, isPriceExcludingVAT),
    testId,
  });
  const taxes = useMemo(
    () =>
      isDirectSales || isUSNewModel
        ? transactionalTaxes
        : !isUSNewModel
          ? acquisitionalTaxes
          : [],
    [isDirectSales, isUSNewModel, transactionalTaxes, acquisitionalTaxes],
  );
  const incentiveTaxes = getFormattedTaxes({
    taxes: getIncentiveTaxes(informationalTaxes),
    testId,
  });
  const ownershipTaxesFormatted = getFormattedTaxes({
    taxes: getOwnershipTaxes(ownershipTaxes),
    testId,
  });

  return useMemo(
    () => ({
      taxes,
      incentiveTaxes,
      ownershipTaxesFormatted,
    }),
    [taxes, incentiveTaxes, ownershipTaxesFormatted],
  );
};
