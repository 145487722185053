import gql from 'graphql-tag';

export const BASIC_OFFER_QUERY = gql`
  query BasicOffer($token: String!) {
    offerByToken(token: $token) {
      offerKey
      businessModel {
        id
      }
      configuration {
        selections {
          id
          type
        }
      }
    }
  }
`;
