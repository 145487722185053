'use client';
import React from 'react';
import { TrackingProvider } from '@volvo-cars/tracking';
import { type EngineType } from 'src/types/queries';
import { useQueries } from 'src/hooks/useQueries';
import { findCarAndFormatDisclaimer } from 'src/utils/queries';
import { type ImageEntry } from '@volvo-cars/content-management-client';
import { PromotionDisplay } from '@vcc-package/promotions';
import { Markdown } from '@vcc-package/text';
import { Spacing } from 'src/components/layout/Spacing';

export interface SuperHeroProps {
  type: 'superHero';
  model?: string;
  engineType?: EngineType;
  disclaimer?: string;
  mobileImage?: ImageEntry;
  tabletImage?: ImageEntry;
  desktopImage?: ImageEntry;
  body?: string;
  cta?: {
    href: string;
    text: string;
  };
  title?: string;
}

export const SuperHero: React.FC<SuperHeroProps> = ({
  disclaimer,
  model,
  engineType,
  mobileImage,
  tabletImage,
  desktopImage,
  body,
  cta,
  title,
}) => {
  const { graphCars } = useQueries();

  let formattedDisclaimer = disclaimer;
  if (model && engineType && disclaimer) {
    formattedDisclaimer = findCarAndFormatDisclaimer({
      disclaimer,
      graphCars,
      model,
      engineType,
    });
  }

  return (
    <TrackingProvider eventCategory="super hero">
      <Spacing>
        <div data-testid="super-hero">
          <PromotionDisplay
            body={body}
            title={title ?? ''}
            cta={cta ?? { href: '', text: '' }}
            responsiveImages={{
              sm: mobileImage ?? { src: '', alt: '' },
              md: tabletImage ?? { src: '', alt: '' },
              lg: desktopImage ?? { src: '', alt: '' },
            }}
          />
          {formattedDisclaimer && (
            <div className="container mt-24 md:text-center">
              <Markdown
                markdown={formattedDisclaimer}
                className="text-secondary micro"
              />
            </div>
          )}
        </div>
      </Spacing>
    </TrackingProvider>
  );
};
