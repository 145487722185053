import { LOG_SERVICES, OrderError, log } from '@vcc-package/offers-utils';
import { useCallback, useMemo } from 'react';
import { ERRORS } from '../../offers.constants';
import { UseErrorProps } from '../entire-offer.types';

export const useError = ({
  clientName,
  isStockCar,
  market,
  token,
  networkStatus,
  carError,
  offerError,
  carData,
  isLoading,
  isFleetLoading,
  onError,
  formattedCarError,
  formattedOfferError,
}: UseErrorProps) => {
  const logger = useCallback(
    (message: string) =>
      log.error({
        service: LOG_SERVICES.SHARED_OFFERS_PROVIDER_USE_ENTIRE_OFFER,
        message,
        clientName,
      }),
    [clientName],
  );

  const error = useMemo(() => {
    if (networkStatus === 8) {
      const networkError = new Error(ERRORS.NETWORK_ERROR) as OrderError;
      networkError.statusCode = 404;
      logger(`Network error: ${networkError.message}`);
      return networkError;
    }

    if (carError) {
      // TODO: Handle error?
      logger(carError.message);
    }

    if (
      !!token &&
      (isStockCar ? !carData?.stockCars?.hits?.length : !carData?.carByToken) &&
      !isLoading &&
      market &&
      !isFleetLoading
    ) {
      const carNotFound = new Error(ERRORS.CAR_NOT_FOUND) as OrderError;
      carNotFound.statusCode = 404;
      logger(carNotFound.message);
      return carNotFound;
    }

    if (offerError) {
      const hasFinancialCalculationError = /"errorCode":"01020"/.test(
        offerError?.graphQLErrors?.[0]?.extensions?.response?.body?.detail,
      );
      let entireOfferError;
      if (hasFinancialCalculationError) {
        entireOfferError = new Error(
          ERRORS.FINANCIAL_CALCULATION_ERROR,
        ) as OrderError;
      } else {
        entireOfferError = new Error(ERRORS.OFFER_ERROR) as OrderError;
      }

      logger(offerError.message);
      return entireOfferError;
    }
  }, [
    carData?.carByToken,
    carData?.stockCars?.hits,
    carError,
    isFleetLoading,
    isLoading,
    isStockCar,
    logger,
    market,
    networkStatus,
    offerError,
    token,
  ]);

  if (onError && (formattedCarError || formattedOfferError)) {
    onError({
      car: formattedCarError,
      offer: formattedOfferError,
    });
  }

  const updatedError = {
    car: formattedCarError,
    offer: formattedOfferError,
  };

  return { error, updatedError };
};
