export const PROD_DOMAIN = 'www.volvocars.com';
export const QA_DOMAIN = 'qawww.volvocars.com';
export const DOMAINS = [PROD_DOMAIN, QA_DOMAIN];
export const APPLICATION_NAME = 'benefit-in-kind-estimator';
export const MILEAGE_THRESHOLD_MAX = '9000000';
export const MILEAGE_THRESHOLD_MIN = '1';
export const SALARY_THRESHOLD_MAX = '54750';
export const LOCALSTORAGE_BIK_STATE_KEY = 'bik-params';
export const SALARY_THRESHOLD_MIN = '36000';
export const SALARY_THRESHOLD_URAX_NAME = 'SalaryThreshold';
export const MARGINAL_TAX_URAX_NAME = 'MarginalTax';
export const MILEAGE_REDUCTION_URAX_NAME = 'MileageReduction';
export const BIK_NET_URAX_TAG = 'BikNet';
export const BIK_GROSS_URAX_TAG = 'BikGross';
export const CCE_ENVIRONMENT_PROD = 'prod';
export const CCE_ENVIRONMENT_STAGING = 'staging';
export const GPE_ENVIRONMENT_PROD = 'gpe-prod-urax-prod';
export const GPE_ENVIRONMENT_STAGING = 'gpe-prod-urax-qa';
export const CCE_ENVIRONMENTS = [
  CCE_ENVIRONMENT_PROD,
  CCE_ENVIRONMENT_STAGING,
] as const;
export const GPE_ENVIRONMENTS = [
  GPE_ENVIRONMENT_PROD,
  GPE_ENVIRONMENT_STAGING,
] as const;
export const FEDERATED_GRAPH_PROD_URI = 'https://www.volvocars.com/api/graphql';
export const RESIDENCIES = ['England', 'Wales', 'Northern Ireland', 'Scotland'];
export const TAXRATES_FOR_RESIDENCIES = [
  {
    residency: RESIDENCIES[0],
    rates: [20, 40, 45],
  },
  {
    residency: RESIDENCIES[1],
    rates: [20, 40, 45],
  },
  {
    residency: RESIDENCIES[2],
    rates: [20, 40, 45],
  },
  {
    residency: RESIDENCIES[3],
    rates: [19, 20, 21, 41, 46],
  },
];
export const DEFAULT_IS_MILEAGE_ABOVE_THRESHOLD = false;
export const DEFAULT_IS_SALARY_ABOVE_THRESHOLD = true;
export const DEFAULT_RESIDENCY = RESIDENCIES[0];
export const DEFAULT_TAX_RATE = TAXRATES_FOR_RESIDENCIES[0].rates[0];
// TRACKING
export const TRACKING_BENEFIT_IN_KIND_EVENT_CATEGORY = 'benefit in kind';
export const TRACKING_BENEFIT_IN_KIND_EVENT_ACTION_OVERVIEW_VISIBLE =
  'overview visible';
export const TRACKING_BENEFIT_IN_KIND_EVENT_ACTION_OVERVIEW_VISIBLE_WITH_ERROR =
  'overview visible with error';
export const TRACKING_BENEFIT_IN_KIND_EVENT_ACTION_CTA_CLICK = 'cta click';
export const TRACKING_BENEFIT_IN_KIND_EVENT_ACTION_INTERACTION = 'interaction';
export const TRACKING_BENEFIT_IN_KIND_EVENT_ACTION_OVERLAY_VISIBLE =
  'overlay visible';
export const TRACKING_BENEFIT_IN_KIND_EVENT_ACTION_OVERLAY_VISIBLE_ERROR =
  'overlay visible with error';
export const TRACKING_BENEFIT_IN_KIND_EVENT_ACTION_INLINE_VISIBLE_WITH_ERROR =
  'inline visible with error';
export const TRACKING_BENEFIT_IN_KIND_EVENT_ACTION_AMOUNT_VISIBLE_WITH_ERROR =
  'amount visible with error';
