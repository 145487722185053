import { SALES_MODELS, SalesModels } from './sales-models.utils.types';

export const getSalesModelFromString = (
  s: SalesModels | string | null | undefined,
): SalesModels => {
  switch (s?.toLowerCase()) {
    case SALES_MODELS.CASH:
      return SALES_MODELS.CASH;
    case SALES_MODELS.FIXED:
      return SALES_MODELS.FIXED;
    case SALES_MODELS.FLEX:
      return SALES_MODELS.FLEX;
    case SALES_MODELS.LEASE:
      return SALES_MODELS.LEASE;
    case SALES_MODELS.LOAN:
      return SALES_MODELS.LOAN;
    case SALES_MODELS.WHOLESALE: // TODO: Remove wholesale once data is in place.
      return SALES_MODELS.WHOLESALE;
    case SALES_MODELS.DEFAULT:
    default:
      return SALES_MODELS.DEFAULT;
  }
};

export const salesModels: { [key: string]: SalesModels } = {
  cash: SALES_MODELS.CASH,
  default: SALES_MODELS.DEFAULT,
  sub_fixed: SALES_MODELS.FIXED,
  sub: SALES_MODELS.FLEX,
  lease: SALES_MODELS.LEASE,
  loan: SALES_MODELS.LOAN,
  wholesale: SALES_MODELS.WHOLESALE, // TODO: Remove wholesale once data is in place.
};

export const hasValidOfferSelections = ({
  salesModel,
  salesModelSelections,
}: {
  salesModel: string | undefined;
  salesModelSelections: string[] | undefined;
}) => {
  if (!salesModel || !salesModelSelections) return false;
  if (salesModel?.toLocaleLowerCase() === SALES_MODELS.WHOLESALE) return false;
  if (
    salesModelSelections?.length < 2 &&
    salesModel.toLocaleLowerCase() === SALES_MODELS.CASH
  ) {
    return false;
  }
  return true;
};
