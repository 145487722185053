import { useMemo } from 'react';
import { useContent } from '../content/offers-provider.content.provider';
import { useSettings } from '../settings/offers-provider.settings.provider';
import {
  INTEREST_RATES,
  PRICE_SUMMARY_TEST_IDS,
  NUMBER_OF_DECIMALS_FOR_APR,
} from './price-breakdown.constants';
import { FinanceDetailsInputProps } from './price-breakdown.types';
import { findFee, truncateToDecimalPlaces } from './price-breakdown.utils';
import { BREAKDOWN_ITEMS } from '@vcc-package/price-breakdown/v2/src/price-breakdown.constants';

const TEST_ID = PRICE_SUMMARY_TEST_IDS.FINANCE_DETAILS;

export const useFinanceDetails = ({
  contractLength,
  discountAmount,
  discountAmountDisplayPrice,
  discountRateDisplay,
  financingPriceSummary,
  isDiscountAppliedForFinanceDetails,
  isDiscountAppliedApr,
  mileage,
  priceBeforeDiscount,
  priceAmountBeforeDiscount,
}: FinanceDetailsInputProps) => {
  const { translate } = useContent();
  const {
    enableExtendedFinanceDetails,
    enablePriceBreakdownFinanceDetails,
    enableEditFinanceDetails,
    enableTruncatedApr,
    supportedFinancialBreakdownItems,
  } = useSettings();

  /** Check if there are financing price details available for this offer */
  const hasPriceDetails = !!(
    enablePriceBreakdownFinanceDetails && financingPriceSummary?.breakdown
  );

  /** Finance details data */
  const originalApr = useMemo(
    () =>
      financingPriceSummary?.breakdown?.interestRates?.find(
        (interestRate) => interestRate?.type === INTEREST_RATES.APR,
      ),
    [financingPriceSummary?.breakdown?.interestRates],
  )?.rate;

  const apr = useMemo(
    () =>
      enableTruncatedApr && originalApr
        ? truncateToDecimalPlaces(originalApr, NUMBER_OF_DECIMALS_FOR_APR)
        : originalApr,
    [originalApr, enableTruncatedApr],
  );

  const fixedRateOfInterest = useMemo(
    () =>
      financingPriceSummary?.breakdown?.interestRates?.find(
        (interestRate) => interestRate?.type === INTEREST_RATES.PAF,
      ),
    [financingPriceSummary?.breakdown?.interestRates],
  )?.rate;

  const optionalFinalPaymentText =
    financingPriceSummary?.breakdown?.finalPaymentAmount?.display;
  const optionalFinalPayment =
    financingPriceSummary?.breakdown?.finalPaymentAmount?.amount;

  const totalPayableAmountText = enableEditFinanceDetails
    ? ''
    : financingPriceSummary?.breakdown?.totalPayableAmount?.display;
  const totalPayableAmount = enableEditFinanceDetails
    ? 0
    : financingPriceSummary?.breakdown?.totalPayableAmount?.amount;

  const financeDetailsDepositText = enableEditFinanceDetails
    ? ''
    : financingPriceSummary?.breakdown?.totalDeposit?.displayPrice?.display;
  const financeDetailsDeposit = enableEditFinanceDetails
    ? 0
    : financingPriceSummary?.breakdown?.totalDeposit?.displayPrice?.amount;

  const amountOfCreditText =
    financingPriceSummary?.breakdown?.creditAmount?.display;
  const amountOfCredit = financingPriceSummary?.breakdown?.creditAmount?.amount;

  const excessMileageCharge =
    financingPriceSummary?.breakdown?.excessMileageRate;

  const purchasePriceText = isDiscountAppliedForFinanceDetails
    ? priceBeforeDiscount
    : financingPriceSummary?.breakdown?.adjustedPreFinancingAmount?.displayPrice
        ?.display;
  const purchasePrice = isDiscountAppliedForFinanceDetails
    ? priceAmountBeforeDiscount
    : financingPriceSummary?.breakdown?.adjustedPreFinancingAmount?.displayPrice
        ?.amount;

  const financialDiscountContributionText = isDiscountAppliedForFinanceDetails
    ? discountAmountDisplayPrice
    : null;
  const financialDiscountContribution = isDiscountAppliedForFinanceDetails
    ? discountAmount
    : null;

  const interestAmount =
    financingPriceSummary?.breakdown?.interestAmount?.amount;
  const interestAmountText =
    financingPriceSummary?.breakdown?.interestAmount?.display;

  const financedAmount =
    financingPriceSummary?.breakdown?.financedAmount?.amount;
  const financedAmountText =
    financingPriceSummary?.breakdown?.financedAmount?.display;

  const creditCostAmount =
    financingPriceSummary?.breakdown?.creditCostAmount?.amount;
  const creditCostAmountText =
    financingPriceSummary?.breakdown?.creditCostAmount?.display;

  const fees = financingPriceSummary?.breakdown?.fees;

  const IFFee = findFee(fees, 'IF');
  const IFFeeAmount = IFFee.amount;
  const IFFeeText = IFFee.text;

  const CFFFee = findFee(fees, 'CFF');
  const CFFFeeAmount = CFFFee.amount;
  const CFFFeeText = CFFFee.text;

  const isLegacyDiscounts = !!discountRateDisplay; // TODO: Remove this when price engine has switched to financeDiscountContributionAmount

  const isNumber = (value: any): value is number => typeof value === 'number';

  const priceBreakdownItems = useMemo(
    () => ({
      [BREAKDOWN_ITEMS.FINAL_PAYMENT]: {
        displayName: translate(
          'PriceBreakdown.financeDetails.optionalFinalPayment',
        ),
        displayNameSources: [
          'sharedDict:PriceBreakdown.financeDetails.optionalFinalPayment',
        ],
        price: optionalFinalPayment ?? 0,
        priceText: optionalFinalPaymentText ?? '',
        priceTextSources: [
          'data:financingPriceSummary.breakdown.finalPaymentAmount.display',
        ],
        type: 'optionalPayment',
        testId: `${TEST_ID}-optional-final-payment`,
      },
      [BREAKDOWN_ITEMS.CONTRACT_LENGTH]: {
        displayName: translate('PriceBreakdown.financeDetails.contractLength'),
        displayNameSources: [
          'sharedDict:PriceBreakdown.financeDetails.contractLength',
        ],
        price: 0,
        priceText: contractLength ?? '',
        priceTextSources: ['data:offerData.contractLength.content.formatted'],
        type: 'contractLength',
        testId: `${TEST_ID}-contract-length`,
      },
      [BREAKDOWN_ITEMS.DEPOSIT]: {
        displayName: translate('PriceBreakdown.financeDetails.totalDeposit'),
        displayNameSources: [
          'sharedDict:PriceBreakdown.financeDetails.totalDeposit',
        ],
        price: financeDetailsDeposit ?? 0,
        priceText: financeDetailsDepositText ?? '',
        priceTextSources: enableEditFinanceDetails
          ? ['']
          : [
              'data:financingPriceSummary.breakdown.totalDeposit.displayPrice.display',
            ],
        type: 'deposit',
        testId: `${TEST_ID}-total-deposit`,
      },
      [BREAKDOWN_ITEMS.MILEAGE]: {
        displayName: translate('PriceBreakdown.financeDetails.mileage'),
        displayNameSources: [
          'sharedDict:PriceBreakdown.financeDetails.mileage',
        ],
        price: 0,
        priceText: mileage ?? '',
        priceTextSources: ['data:finance.selectedOffer.mileage.text'],
        type: 'mileage',
        testId: `${TEST_ID}-mileage`,
      },
      ...(isDiscountAppliedForFinanceDetails
        ? // TODO: Remove this when price engine has switched to financeDiscountContributionAmount
          isLegacyDiscounts
          ? {
              [BREAKDOWN_ITEMS.DISCOUNT]: {
                displayName: `${translate(
                  'PriceBreakdown.financeDetails.discount',
                  {
                    discount: discountRateDisplay,
                  },
                )}`,
                displayNameSources: [
                  'sharedDict:PriceBreakdown.financeDetails.discount',
                  'data:discountRateDisplay',
                ],
                price: -(financialDiscountContribution ?? 0),
                priceText: '- ' + financialDiscountContributionText,
                priceTextSources: ['data:discountAmountDisplayPrice'],
                type: 'financialDiscountContribution',
                testId: `${TEST_ID}-discount`,
              },
              [BREAKDOWN_ITEMS.PURCHASE_PRICE]: {
                displayName: translate(
                  'PriceBreakdown.financeDetails.purchasePriceWithDiscount',
                ),
                displayNameSources: [
                  'sharedDict:PriceBreakdown.financeDetails.purchasePriceWithDiscount',
                ],
                price: purchasePrice ?? 0,
                priceText: purchasePriceText ?? '',
                priceTextSources: ['data:totalPrice?.display'],
                type: 'purchasePriceInclFDC',
                testId: `${TEST_ID}-purchase-price-with-discount`,
              },
            }
          : {
              [BREAKDOWN_ITEMS.DISCOUNT]: {
                displayName: translate(
                  'PriceBreakdown.financeDetails.financialDiscountContribution',
                ),
                displayNameSources: [
                  'sharedDict:PriceBreakdown.financeDetails.financialDiscountContribution',
                ],
                price: -(financialDiscountContribution ?? 0),
                priceText: '- ' + financialDiscountContributionText,
                priceTextSources: [
                  'data:financingPriceSummary.breakdown.financeDiscountContribution.displayPrice.display',
                ],
                type: 'financeDiscountContribution',
                testId: `${TEST_ID}-financial-discount-contribution`,
              },
              [BREAKDOWN_ITEMS.PURCHASE_PRICE]: {
                displayName: translate(
                  'PriceBreakdown.financeDetails.purchasePriceFdc',
                ),
                displayNameSources: [
                  'sharedDict:PriceBreakdown.financeDetails.purchasePriceFdc',
                ],
                price: purchasePrice ?? 0,
                priceText: purchasePriceText ?? '',
                priceTextSources: ['data:totalPrice?.display'],
                type: 'purchasePriceInclFDC',
                testId: `${TEST_ID}-purchase-price-fdc`,
              },
            }
        : {}),
      ...(isLegacyDiscounts || !isDiscountAppliedForFinanceDetails
        ? {
            [BREAKDOWN_ITEMS.PURCHASE_PRICE]: {
              displayName: translate(
                'PriceBreakdown.financeDetails.purchasePrice',
              ),
              displayNameSources: [
                'sharedDict:PriceBreakdown.financeDetails.purchasePrice',
              ],
              price: purchasePrice ?? 0,
              priceText: purchasePriceText ?? '',
              priceTextSources: [
                isDiscountAppliedForFinanceDetails
                  ? 'data:priceBeforeDiscount'
                  : 'data:totalPrice.display',
              ],
              type: 'purchasePrice',
              testId: `${TEST_ID}-purchase-price`,
            },
          }
        : {}),
      [BREAKDOWN_ITEMS.PAYABLE_AMOUNT]: {
        displayName: translate(
          'PriceBreakdown.financeDetails.totalAmountPayable',
        ),
        displayNameSources: [
          'sharedDict:PriceBreakdown.financeDetails.totalAmountPayable',
        ],
        price: totalPayableAmount ?? 0,
        priceText: totalPayableAmountText ?? '',
        priceTextSources: enableEditFinanceDetails
          ? ['']
          : ['data:financingPriceSummary.breakdown.totalPayableAmount.display'],
        type: 'totalAmount',
        testId: `${TEST_ID}-total-amount-payable`,
      },
      [BREAKDOWN_ITEMS.EXCESS_MILEAGE_RATE]: {
        displayName: translate(
          'PriceBreakdown.financeDetails.excessMileageCharge',
        ),
        displayNameSources: [
          'sharedDict:PriceBreakdown.financeDetails.excessMileageCharge',
        ],
        price: excessMileageCharge ?? 0,
        priceText: excessMileageCharge ? `${excessMileageCharge}p` : '',
        priceTextSources: [
          'data:financingPriceSummary.breakdown.excessMileageRate',
        ],
        type: 'excessMileageCharge',
        testId: `${TEST_ID}-excess-mileage-charge`,
      },
      [BREAKDOWN_ITEMS.PAF]: {
        displayName: translate('PriceBreakdown.financeDetails.interestRate'),
        displayNameSources: [
          'sharedDict:PriceBreakdown.financeDetails.interestRate',
        ],
        price: fixedRateOfInterest ?? 0,
        priceText: isNumber(fixedRateOfInterest)
          ? `${fixedRateOfInterest.toString()}%`
          : '',
        priceTextSources: [
          'data:financingPriceSummary.breakdown.interestRates[type=InterestRates.PAF]',
        ],
        type: 'paf',
        testId: `${TEST_ID}-interest-rate`,
      },
      [BREAKDOWN_ITEMS.AMOUNT_OF_CREDIT]: {
        displayName: translate('PriceBreakdown.financeDetails.amountOfCredit'),
        displayNameSources: [
          'sharedDict:PriceBreakdown.financeDetails.amountOfCredit',
        ],
        price: amountOfCredit ?? 0,
        priceText: amountOfCreditText ?? '',
        priceTextSources: [
          'data:financingPriceSummary.breakdown.creditAmount.display',
        ],
        type: 'amountOfCredit',
        testId: `${TEST_ID}-amount-of-credit`,
      },
      [BREAKDOWN_ITEMS.APR]: {
        displayName: translate('PriceBreakdown.financeDetails.apr'),
        displayNameSources: ['sharedDict:PriceBreakdown.financeDetails.apr'],
        price: apr ?? 0,
        priceText: isNumber(apr) && !isNaN(apr) ? `${apr.toString()}%` : '',
        priceTextSources: [
          'data:financingPriceSummary.breakdown.interestRates[type=InterestRates.APR]',
        ],
        type: 'apr',
        testId: `${TEST_ID}-apr`,
        hasEmphasis: isDiscountAppliedApr,
      },
      ...(enableExtendedFinanceDetails
        ? {
            [BREAKDOWN_ITEMS.INTEREST_AMOUNT]: {
              displayName: translate(
                'PriceBreakdown.financeDetails.interestAmount',
              ),
              displayNameSources: [
                'sharedDict:PriceBreakdown.financeDetails.interestAmount',
              ],
              price: interestAmount ?? 0,
              priceText: interestAmountText ?? '',
              priceTextSources: [
                'data:financingPriceSummary.breakdown.interestAmount.display',
              ],
              type: 'interestAmount',
              testId: `${TEST_ID}-interest-amount`,
            },
            [BREAKDOWN_ITEMS.FINANCED_AMOUNT]: {
              displayName: translate(
                'PriceBreakdown.financeDetails.financedAmount',
              ),
              displayNameSources: [
                'sharedDict:PriceBreakdown.financeDetails.financedAmount',
              ],
              price: financedAmount ?? 0,
              priceText: financedAmountText ?? '',
              priceTextSources: [
                'data:financingPriceSummary.breakdown.financedAmount.display',
              ],
              type: 'financedAmount',
              testId: `${TEST_ID}-financed-amount`,
            },
            [BREAKDOWN_ITEMS.CREDIT_COST_AMOUNT]: {
              displayName: translate(
                'PriceBreakdown.financeDetails.creditCostAmount',
              ),
              displayNameSources: [
                'sharedDict:PriceBreakdown.financeDetails.creditCostAmount',
              ],
              price: creditCostAmount ?? 0,
              priceText: creditCostAmountText ?? '',
              priceTextSources: [
                'data:financingPriceSummary.breakdown.creditCostAmount.display',
              ],
              type: 'creditCostAmount',
              testId: `${TEST_ID}-credit-cost-amount`,
            },
            [BREAKDOWN_ITEMS.PROCESSING_FEE]: {
              displayName: translate(
                'PriceBreakdown.financeDetails.processingFee',
              ),
              displayNameSources: [
                'sharedDict:PriceBreakdown.financeDetails.processingFee',
              ],
              price: IFFeeAmount ?? 0,
              priceText: IFFeeText ?? '',
              priceTextSources: [
                'data:financingPriceSummary.breakdown.fees.IF.amount',
              ],
              type: 'IF',
              testId: `${TEST_ID}-IF-fee`,
            },
            [BREAKDOWN_ITEMS.ESTABLISHMENT_FEE]: {
              displayName: translate(
                'PriceBreakdown.financeDetails.establishmentFee',
              ),
              displayNameSources: [
                'sharedDict:PriceBreakdown.financeDetails.establishmentFee',
              ],
              price: CFFFeeAmount ?? 0,
              priceText: CFFFeeText ?? '',
              priceTextSources: [
                'data:financingPriceSummary.breakdown.fees.CFF.amount',
              ],
              type: 'CFF',
              testId: `${TEST_ID}-CFF-fee`,
            },
          }
        : {}),
    }),
    [
      CFFFeeAmount,
      CFFFeeText,
      IFFeeAmount,
      IFFeeText,
      amountOfCredit,
      amountOfCreditText,
      apr,
      contractLength,
      creditCostAmount,
      creditCostAmountText,
      discountRateDisplay,
      enableEditFinanceDetails,
      enableExtendedFinanceDetails,
      excessMileageCharge,
      financeDetailsDeposit,
      financeDetailsDepositText,
      financedAmount,
      financedAmountText,
      financialDiscountContribution,
      financialDiscountContributionText,
      fixedRateOfInterest,
      interestAmount,
      interestAmountText,
      isDiscountAppliedApr,
      isDiscountAppliedForFinanceDetails,
      isLegacyDiscounts,
      mileage,
      optionalFinalPayment,
      optionalFinalPaymentText,
      purchasePrice,
      purchasePriceText,
      totalPayableAmount,
      totalPayableAmountText,
      translate,
    ],
  );

  return useMemo(
    () => ({
      displayName: translate('PriceBreakdown.financeDetails.title'),
      displayNameSources: ['sharedDict:PriceBreakdown.financeDetails.title'],
      testId: TEST_ID,
      shouldShowSection: hasPriceDetails,
      priceBreakdownItems: supportedFinancialBreakdownItems?.map(
        (item) => priceBreakdownItems[item],
      ),
    }),
    [
      hasPriceDetails,
      priceBreakdownItems,
      supportedFinancialBreakdownItems,
      translate,
    ],
  );
};
