import React from 'react';
import { TitleWithSubTitleProps } from '../part-exchange.types';

export const TitleWithSubTitle = ({
  title,
  subTitle,
  body,
  isMediumVariant,
}: TitleWithSubTitleProps) => {
  return (
    <>
      <Title isMediumVariant={isMediumVariant} hasBody={!!body}>
        {title}
        {!!subTitle && (
          <span className="heading-2 text-secondary">{' ' + subTitle}</span>
        )}
      </Title>
      {!!body && (
        <p
          data-testid="part-exchange-tool:title-with-subtitle-body"
          className="font-16 text-secondary mt-16 mb-32"
        >
          {body}
        </p>
      )}
    </>
  );
};

const Title: React.FC<
  React.PropsWithChildren<{
    isMediumVariant?: boolean;
    hasBody?: boolean;
  }>
> = ({ isMediumVariant, hasBody, ...props }) => {
  const variant = isMediumVariant ? 'heading-1' : 'heading-2';

  return (
    <h1
      data-testid="part-exchange-tool:title-with-subtitle-title"
      className={`${variant} pb-16`}
    >
      {props.children}
    </h1>
  );
};
