import isEmpty from 'lodash/isEmpty';
import {
  CONTENT_CACHE,
  SERVER,
  SYSTEM,
  SYSTEM_DEFAULTS,
} from '@vcc-package/preview/src/preview.constants';
import type {
  GetContentCacheProps,
  GetContentEndpointV2Props,
  GetFetchInputProps,
  GetOrderDisclaimersProps,
  GetProdSystemDefaults,
  GetQueryParamsProps,
  GetSettingBySourceIdProps,
  GetTermsProxyExperienceDisclaimersProps,
  UpdateContentProps,
} from './offers-provider.content.types';
import { formatSourcesForQueryParam } from '@vcc-package/preview/src/preview.utils';
import { LOG_SERVICES } from '@vcc-package/offers-utils/utils/log/log.utils.constants';
import { log } from '@vcc-package/offers-utils';

export const getCTA = (
  translations: { [key: string]: string },
  ctaKey: string,
) => {
  return {
    title: translations?.[`${ctaKey}.title`],
    sourceId: translations?.[`${ctaKey}.sourceId`],
    link: {
      text: translations?.[`${ctaKey}.link.text`],
      accessibilityLabel: translations?.[`${ctaKey}.link.accessibilityLabel`],
      target: translations?.[`${ctaKey}.link.target`],
      href: translations?.[`${ctaKey}.link.uri`],
    },
  };
};

export const getCtaGroupNames = (input: string[][]): string[] => {
  const extractedKeys: Set<string> = new Set();

  for (const [key] of input) {
    const parts = key.split('.');
    if (parts.length >= 2) {
      const extractedKey = parts.slice(0, 2).join('.');
      extractedKeys.add(extractedKey);
    }
  }

  return Array.from(extractedKeys);
};

export const getDisclaimer = (
  translations: { [key: string]: string },
  disclaimerKey: string,
) => {
  return {
    title: translations?.[`${disclaimerKey}.title`],
    text: translations?.[`${disclaimerKey}.text`],
    link: {
      text: translations?.[`${disclaimerKey}.link.text`],
      accessibilityLabel:
        translations?.[`${disclaimerKey}.link.accessibilityLabel`],
      target: translations?.[`${disclaimerKey}.link.target`],
      href: translations?.[`${disclaimerKey}.link.uri`],
    },
    annotationCharacter: translations?.[`${disclaimerKey}.annotationCharacter`],
    sourceId: translations?.[`${disclaimerKey}.sourceId`],
  };
};

export const getContentGroupBySourceId = (
  translations: { [key: string]: string },
  groupName: string,
  processFn: (
    translations: { [key: string]: string },
    contentGroupKey: string,
  ) => any & {
    sourceId: string;
  },
) => {
  const contentGroupNames = getCtaGroupNames(
    Object.entries(translations ?? {})?.filter(([key]) =>
      key.startsWith(`${groupName}.`),
    ),
  );

  return contentGroupNames
    .map((contentGroupKey) => processFn(translations ?? {}, contentGroupKey))
    .map((contentType) => ({ [`${contentType.sourceId}`]: contentType }))
    .reduce(
      (prev, curr) => ({
        ...prev,
        ...curr,
      }),
      {},
    );
};

export const getDisclaimersBySourceId = (translations: {
  [key: string]: string;
}) => getContentGroupBySourceId(translations, 'disclaimer', getDisclaimer);

export const getCtasBySourceId = (translations: { [key: string]: string }) =>
  getContentGroupBySourceId(translations, 'cta', getCTA);

export const isValidContentCache = (
  contentCache: string | undefined,
): boolean => {
  return (
    contentCache !== undefined &&
    Object.values(CONTENT_CACHE).includes(contentCache)
  );
};

export const getContentCache = ({
  overrideSources,
  prodSystemDefaults,
}: GetContentCacheProps) => {
  if (!isEmpty(overrideSources)) {
    const contentCache = overrideSources?.[SYSTEM.CONTENT_CACHE];
    if (contentCache && isValidContentCache(contentCache)) {
      return contentCache;
    }
  }
  const contentCacheFromDefaults = prodSystemDefaults?.[SYSTEM.CONTENT_CACHE];
  if (isValidContentCache(contentCacheFromDefaults))
    return contentCacheFromDefaults;
  return CONTENT_CACHE.ENABLED;
};

export const getFetchInput = ({
  clientName,
  contentcache,
  locale,
  overrideSources,
  market,
  salesModel,
  customerType,
  enableDisclaimerContentApi,
}: GetFetchInputProps) => ({
  locale,
  overrideSources,
  clientName,
  emptyCache: contentcache === CONTENT_CACHE.DISABLED,
  ...(market && salesModel && customerType
    ? {
        market: market,
        salesModel: salesModel,
        customerType: customerType,
      }
    : {}),
  ...(enableDisclaimerContentApi
    ? {
        enableDisclaimerContentApi: true,
      }
    : {}),
});

export const getSettingBySourceId = ({
  enableDisclaimerMain,
  enableDisclaimerIndicativePrice,
  enableSubmitDesign,
}: GetSettingBySourceIdProps): {
  [sourceId: string]: boolean | undefined;
} => ({
  main: enableDisclaimerMain,
  indicativePrice: enableDisclaimerIndicativePrice,
  submitDesign: enableSubmitDesign,
});

export const getOrderedDisclaimers = ({
  SETTING_BY_SOURCE_ID,
  disclaimersBySourceId,
}: GetOrderDisclaimersProps): Partial<ReturnType<typeof getDisclaimer>>[] => {
  const compareFun = (a: string, b: string) => {
    if (a === 'main' && b !== 'main') {
      return -1; // 'main' comes before other elements
    } else if (b === 'main' && a !== 'main') {
      return 1; // other elements come after 'main'
    } else {
      return 0; // Maintain the current order when both are 'main' or both are not 'main'
    }
  };
  return Object.keys(disclaimersBySourceId ?? {})
    ?.filter((sourceId) => SETTING_BY_SOURCE_ID[sourceId])
    ?.sort(compareFun)
    .map((sourceId) => disclaimersBySourceId?.[sourceId])
    .filter((entry) => entry.text.trim());
};

export const getTermsProxyExperienceDisclaimers = ({
  translations,
  salesModel,
}: GetTermsProxyExperienceDisclaimersProps): Partial<
  ReturnType<typeof getDisclaimer>
>[] => [
  {
    title: translations?.['disclaimer.main.title'] ?? '',
    text: translations?.termsProxyExperienceDisclaimersText ?? '',
    sourceId: `${salesModel}-termsProxy`,
  },
];

export const getDisclaimers = (
  orderedDisclaimers: Partial<ReturnType<typeof getDisclaimer>>[],
  termsProxyExperienceDisclaimers: Partial<ReturnType<typeof getDisclaimer>>[],
) =>
  [...orderedDisclaimers, ...termsProxyExperienceDisclaimers].filter(
    ({ text }) => text && typeof text === 'string',
  );

export const getContentEndpoint = ({ domain }: { domain: string }) => {
  return `${domain}/api/order/content`;
};

export const getQueryParams = ({
  cache,
  overrideSources,
}: GetQueryParamsProps) => {
  return new URLSearchParams({
    emptyCache: String(cache ?? ''),
    ...(overrideSources && Object.keys(overrideSources).length
      ? { overrideSources: formatSourcesForQueryParam(overrideSources) ?? '' }
      : {}),
  });
};

export const getContentEndpointV2 = ({
  domain,
  queryParams,
}: GetContentEndpointV2Props) => {
  return `${domain}/api/order/v2/content?${queryParams.toString()}`;
};

export const updateContent = async ({
  enableV2ApiEndpoints,
  endpointV2,
  headers,
  clientName,
  endpoint,
  fetchInput,
  setContent,
  setLoading,
}: UpdateContentProps) => {
  try {
    const allContent = enableV2ApiEndpoints
      ? await fetch(endpointV2, {
          headers,
        })
      : await fetch(endpoint, {
          method: 'POST',
          body: JSON.stringify(fetchInput),
          headers,
        });
    if (!allContent.ok) {
      setContent({
        sharedTranslations: {},
        translations: {},
      });
      throw new Error(
        `[${LOG_SERVICES.SHARED_OFFERS_PROVIDERS_USE_OFFERS_PROVIDER_CONTENT}] ${endpoint}: Returned ${allContent.status} ${allContent.statusText}`,
      );
    }
    setContent(await allContent.json());
  } catch (error) {
    setContent({
      sharedTranslations: {},
      translations: {},
    });
    log.error({
      service: LOG_SERVICES.SHARED_OFFERS_PROVIDERS_USE_OFFERS_PROVIDER_CONTENT,
      message: (error as Error)?.message,
      clientName,
    });
  } finally {
    setLoading(false);
  }
};

export const getProdSystemDefaults = ({ deployEnv }: GetProdSystemDefaults) => {
  return SYSTEM_DEFAULTS(deployEnv ?? '')[SERVER.PROD];
};
