export const FLEET_SESSION_TYPE = {
  BUSINESS_PRICING: 'BUSINESS_PRICING',
  FLEET_ACCOUNT: 'FLEET_ACCOUNT',
  FLEET_PRICING_PREVIEW: 'FLEET_PRICING_PREVIEW',
  FLEET_COMPANY_CAR_DRIVER: 'FLEET_COMPANY_CAR_DRIVER',
  FLEET_PENDING_ACCOUNT: 'FLEET_PENDING_ACCOUNT',
  FLEET_APPROVED_ACCOUNT: 'FLEET_APPROVED_ACCOUNT',
} as const;

export type FleetSessionType =
  | (typeof FLEET_SESSION_TYPE)[keyof typeof FLEET_SESSION_TYPE]
  | null;
