import { Fee } from '@vcc-www/federated-graph-types';

export const truncateToDecimalPlaces = (
  number: number | undefined,
  numberOfDecimals: number = 2,
) => {
  const multiplier = Math.pow(10, numberOfDecimals);
  if (number === undefined) {
    console.error('Error in truncateToDecimalPlaces: Input must be a number');
    return NaN;
  }
  return Math.trunc(number * multiplier) / multiplier;
};

export const findFee = (fees: Fee[] | null | undefined, type: string) => {
  const fee = fees?.find((fee) => fee?.type === type);
  return {
    amount: fee?.amount?.amount,
    text: fee?.amount?.display,
  };
};
