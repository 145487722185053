'use client';

import React, { useEffect, useState } from 'react';
import { useDialogNavigation } from './useDialogNavigation';
import { cssJoin } from '@volvo-cars/css/utils';
import styles from './DialogNavigationPage.module.css';

type AnimationState = 'not-started' | 'in-progress' | 'done';

/**
 * Represents a page component used in the dialog navigation.
 *
 * @component
 * @example
 * ```tsx
 * <DialogNavigationPage pathName="/home">
 *   <HomePage />
 * </DialogNavigationPage>
 * ```
 *
 * @param {React.PropsWithChildren<{ pathName: string; title: string: }>} props - The component props.
 * @param {string} props.pathName - The path name of the page.
 * @param {string} props.title - The title of the page.
 * @returns {JSX.Element} The rendered `DialogNavigationPage` component.
 */
export const DialogNavigationPage: React.FC<
  React.PropsWithChildren<{ pathName: string; title?: string }>
> = ({ pathName, title, children }) => {
  const { stack, currentPage, previousPage, dirty, setTitle } =
    useDialogNavigation();
  const prevIndex = stack.indexOf(previousPage || '');
  const currentIndex = stack.indexOf(currentPage);
  const animationDirection =
    prevIndex > -1 && prevIndex < currentIndex ? 'forward' : 'backward';
  const [animationState, setAnimationState] =
    useState<AnimationState>('not-started');

  const onAnimationStart = () => {
    if (animationState === 'in-progress') return;
    setAnimationState('in-progress');
  };
  const onAnimationEnd = () => {
    if (animationState === 'not-started') return;
    setAnimationState('not-started');
  };

  useEffect(() => {
    // Set the title when this page is shown
    if (stack[currentIndex] === pathName) {
      setTitle(title);
    }
  }, [currentIndex, pathName, setTitle, stack, title]);

  return (
    <div
      className={cssJoin(
        // TODO: remove overflow-x-hidden when we have a better way of animating and toggling pages
        // This is partially what makes this EXPERIMENTAL. Until we've found a good way of toggling views, this will have to do.
        // JTHORUPP, 2 May 2024
        'flex-grow overflow-x-hidden',
      )}
      // Scrollable elements should be keyboard selectable: https://accessibilityinsights.io/info-examples/web/scrollable-region-focusable/
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
    >
      <div
        className={cssJoin(
          dirty && animationDirection === 'forward' && styles['slide-in'],
          dirty && animationDirection === 'backward' && styles['slide-out'],
          animationState === 'not-started' &&
            stack[currentIndex] !== pathName &&
            styles['hidden-page'],
        )}
        onAnimationEnd={onAnimationEnd}
        onAnimationStart={onAnimationStart}
      >
        {children}
      </div>
    </div>
  );
};
