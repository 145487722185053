import { useCallback, useMemo } from 'react';
import { useContent } from '../content/offers-provider.content.provider';
import { useSettings } from '../settings/offers-provider.settings.provider';

export const usePriceLabels = () => {
  const { supportsVAT } = useSettings();
  const { translate } = useContent();

  const translations = useMemo(
    () => ({
      business: translate('OfferSelector.customerTypeStage.business'),
      personal: translate('OfferSelector.customerTypeStage.personal'),
      inclVat: translate('PriceBreakdown.priceBreakdown.includingVat'),
      exclVat: translate('PriceBreakdown.priceBreakdown.excludingVat'),
      exclTaxesAndFees: translate(
        'PriceBreakdown.priceBreakdown.exclTaxesAndFees',
      ),
      sources: {
        business: ['sharedDict:OfferSelector.customerTypeStage.business'],
        personal: ['sharedDict:OfferSelector.customerTypeStage.personal'],
        inclVat: ['sharedDict:PriceBreakdown.priceBreakdown.includingVat'],
        exclVat: ['sharedDict:PriceBreakdown.priceBreakdown.excludingVat'],
        exclTaxesAndFees: [
          'sharedDict:PriceBreakdown.priceBreakdown.exclTaxesAndFees',
        ],
      },
    }),
    [translate],
  );

  const getVatLabel = useCallback(
    (includingVAT: boolean) =>
      !supportsVAT
        ? translations.exclTaxesAndFees
        : includingVAT
          ? translations.inclVat
          : translations.exclVat,
    [
      supportsVAT,
      translations.exclTaxesAndFees,
      translations.inclVat,
      translations.exclVat,
    ],
  );

  const getVatLabelSources = useCallback(
    (includingVAT: boolean) =>
      !supportsVAT
        ? translations.sources.exclTaxesAndFees
        : includingVAT
          ? translations.sources.inclVat
          : translations.sources.exclVat,
    [
      supportsVAT,
      translations.sources.exclTaxesAndFees,
      translations.sources.inclVat,
      translations.sources.exclVat,
    ],
  );

  const totalLabel = useMemo(
    () =>
      `${translate('PriceBreakdown.priceBreakdown.total')} (${
        supportsVAT ? translations.inclVat : translations.exclTaxesAndFees
      })`,
    [
      supportsVAT,
      translate,
      translations.exclTaxesAndFees,
      translations.inclVat,
    ],
  );

  const totalLabelSources = useMemo(
    () => [
      'sharedDict:PriceBreakdown.priceBreakdown.total',
      ...(supportsVAT
        ? translations.sources.inclVat
        : translations.sources.exclTaxesAndFees),
    ],
    [
      supportsVAT,
      translations.sources.exclTaxesAndFees,
      translations.sources.inclVat,
    ],
  );

  return {
    getVatLabel,
    getVatLabelSources,
    totalLabel,
    totalLabelSources,
    ...translations,
  };
};
