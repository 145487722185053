import { Group } from '@vcc-www/federated-graph-types';
import { getModelSlugByKey } from '@vcc-package/offers-utils';
import { useCallback, useMemo } from 'react';
import { UseEntireOfferCarConfigurationAdapterProps } from '../entire-offer.types';
import {
  getCarExpandedDisplayNameAndModelYear,
  getDisplayPriceByCode,
} from '../entire-offer.utils';
import { useSettings } from '../../settings/offers-provider.settings.provider';

export const useEntireOfferCarConfigurationAdapter = ({
  carData,
  offerData,
  isStockCar,
  market,
}: UseEntireOfferCarConfigurationAdapterProps) => {
  const { enableVehiclePriceBreakdownInCart } = useSettings();
  const { stockCars, carByToken } = carData ?? {};
  const [firstHit] = stockCars?.hits ?? [];

  const specification = isStockCar
    ? firstHit?.vehicle?.specification
    : carByToken;

  const carPrice = offerData?.offerByToken?.priceSummary?.carPriceSummary; // TODO: Use carPrice

  const priceBreakdown = carPrice?.breakDown;
  const all = priceBreakdown?.all;

  const selectedPackages = carByToken?.packages;
  const stockCarSelectedPackages = firstHit?.vehicle?.specification?.packages;

  const selectedOptions = specification?.options?.filter(
    ({ packages, content }) =>
      packages.map((pkg) =>
        selectedPackages?.filter((p) => p?.code !== pkg.code),
      )?.length === 0 &&
      content?.group?.value !== Group.WHEELS &&
      content?.group?.value !== Group.WINTER_WHEELS,
  );

  const stockCarSelectedOptions =
    firstHit?.vehicle?.specification?.options?.filter(
      ({ packages, content, visible }) =>
        packages.map((pkg) =>
          selectedPackages?.filter((p) => p?.code !== pkg.code),
        )?.length === 0 &&
        content?.group?.value !== Group.WHEELS &&
        content?.group?.value !== Group.WINTER_WHEELS &&
        visible,
    );

  // Check if any wheel is selected
  const isAnyWheelSelected = carByToken?.configurationByToken?.options?.some(
    (option) =>
      (option.content?.group?.value === Group.WHEELS ||
        option?.content?.group?.value === Group.WINTER_WHEELS) &&
      option.inConfiguration?.selected,
  );

  const selectedWheels = carByToken?.configurationByToken?.options?.filter(
    (option) =>
      (option.content?.group?.value === Group.WHEELS ||
        option?.content?.group?.value === Group.WINTER_WHEELS) &&
      ((isAnyWheelSelected && option.inConfiguration?.selected) ||
        (!isAnyWheelSelected && option.inConfiguration?.included)),
  );

  const stockCarsSelectedWheels =
    firstHit?.vehicle?.specification?.options?.filter(
      (option) =>
        option.content?.group?.value === Group.WHEELS ||
        option?.content?.group?.value === Group.WINTER_WHEELS,
    );

  const userSelectedOptions = isStockCar
    ? stockCarSelectedOptions
    : selectedOptions?.filter((option) =>
        carByToken?.configurationByToken?.options.find(
          (opt) => opt?.code === option?.code && opt?.visible,
        ),
      );

  const userSelectedPackages = isStockCar
    ? stockCarSelectedPackages
    : selectedPackages?.filter((pack) =>
        carByToken?.configurationByToken?.packages.find(
          (pkg) =>
            pkg?.code === pack?.code &&
            (pkg?.inConfiguration?.selected || pkg.inConfiguration?.included),
        ),
      );

  const displayPriceByCode = useCallback(
    (code?: string) => getDisplayPriceByCode(all, code),
    [all],
  );

  return useMemo(() => {
    const {
      carKeyExpanded,
      model,
      visualizations,
      color,
      upholstery,
      engine,
      theme,
      edition,
      carType,
      trim,
    } = specification ?? {};

    const [firstVisualizations] = visualizations ?? [];
    const imageUrl =
      firstVisualizations?.views?.exterior?.studio?.front?.sizes?.medium?.url ??
      '';

    return {
      userFriendlyCode: carByToken?.configuration?.token?.userFriendly,
      displayName: model?.displayName?.value ?? '',
      displayNameSources: ['data:model.displayName.value'],
      displayNameExpanded: specification?.displayName ?? '',
      displayNameExpandedSources: ['data:specification?.displayName'],
      displayNameExpandedAndModelYear: getCarExpandedDisplayNameAndModelYear(
        specification?.displayName ?? '',
        String(carKeyExpanded?.modelYear ?? ''),
      ),
      displayNameExpandedAndModelYearSources: [
        'data:displayName.value',
        'data:specification?.carKeyExpanded?.modelYear',
      ],
      modelKey: model?.key ?? '',
      carTypeCode: carType?.code ?? '',
      modelSlug: getModelSlugByKey(model?.key ?? '') ?? '',
      modelYear: String(carKeyExpanded?.modelYear ?? ''),
      duplicatesIdentifier: firstHit?.vehicle?.duplicatesIdentifier,
      imageUrl,
      pno34: isStockCar
        ? firstHit?.vehicle?.configuration?.pno?.pno34PlusOptions
        : carByToken?.pno?.pno34,
      baseCar: {
        displayNames: {
          level: trim?.displayName?.value,
          powertrain: engine?.content?.displayName?.value,
          seats: isStockCar
            ? firstHit?.vehicle?.configuration?.seats?.numberOfSeatsDescriptions?.find(
                ({ language }) => language === market?.toLowerCase(),
              )?.text
            : carByToken?.carType?.content?.seatCapacity?.formatted,
          theme: theme?.displayName?.value,
          edition: edition?.displayName?.value,
        },
        priceText: enableVehiclePriceBreakdownInCart
          ? priceBreakdown?.baseCar?.price?.display
          : undefined,
        priceTextSources: enableVehiclePriceBreakdownInCart
          ? ['data:priceBreakdown.baseCar.price.display']
          : undefined,
      },
      engineType: engine?.content?.engineType?.value,
      color: {
        displayName: color?.content?.displayName?.value,
        displayNameSources: ['data:color.content.displayName.value'],
        ...displayPriceByCode(color?.code),
        code: color?.code,
      },
      interior: {
        displayName: upholstery?.content?.displayName?.value,
        displayNameSources: ['data:upholstery.content.displayName.value'],
        ...displayPriceByCode(upholstery?.code),
        code: upholstery?.code,
      },
      wheels:
        (selectedWheels ?? stockCarsSelectedWheels)?.map((wheel) => ({
          displayName: wheel.content?.displayName?.value,
          displayNameSources: ['data:wheel.content.displayName.value'],
          code: wheel?.code,
          ...displayPriceByCode(wheel?.code),
        })) ?? [],
      options:
        selectedOptions?.map(({ content, code }) => ({
          displayName: content?.displayName?.value,
          image: content?.images?.[0]?.url,
          displayNameSources: ['data:content.displayName?.value'],
          ...displayPriceByCode(code),
          code,
        })) ?? [],
      packages:
        selectedPackages?.map(({ content, code, components }) => ({
          displayName: content?.displayName?.value,
          displayNameSources: ['data:content.displayName.value'],
          ...displayPriceByCode(content?.packageCode),
          code,
          components: components?.map(
            (component) => component?.content?.displayName?.value ?? '',
          ),
        })) ?? [],
      userSelectedOptions:
        userSelectedOptions?.map(({ content, code }) => ({
          displayName: content?.displayName?.value,
          displayNameSources: ['data:content.displayName?.value'],
          ...displayPriceByCode(code),
          code,
        })) ?? [],
      userSelectedPackages:
        userSelectedPackages?.map((pkg) => {
          const { content, code, components } = pkg;
          return {
            displayName: content?.displayName?.value,
            displayNameSources: ['data:content.displayName?.value'],
            ...displayPriceByCode(code),
            code,
            components: components?.map(
              (component) => component?.content?.displayName?.value ?? '',
            ),
          };
        }) ?? [],
      totalPrice: carPrice?.totalPrice?.display,
    };
  }, [
    specification,
    carByToken?.configuration?.token?.userFriendly,
    carByToken?.pno?.pno34,
    carByToken?.carType?.content?.seatCapacity?.formatted,
    firstHit?.vehicle?.duplicatesIdentifier,
    firstHit?.vehicle?.configuration?.pno?.pno34PlusOptions,
    firstHit?.vehicle?.configuration?.seats?.numberOfSeatsDescriptions,
    isStockCar,
    enableVehiclePriceBreakdownInCart,
    priceBreakdown?.baseCar?.price?.display,
    displayPriceByCode,
    selectedWheels,
    stockCarsSelectedWheels,
    selectedOptions,
    selectedPackages,
    userSelectedOptions,
    userSelectedPackages,
    carPrice?.totalPrice?.display,
    market,
  ]);
};
