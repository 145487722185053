'use client';

import React, {
  useContext,
  createContext,
  useMemo,
  useCallback,
  useState,
} from 'react';
import {
  OverlayContextType,
  OverlayProviderType,
  ShowPartExchangeToolProps,
} from '../part-exchange.types';
import { DictionariesProvider as SharedContentDictionariesProvider } from '../part-exchange.dictionaries-provider';
import { BreakpointsProvider } from '@vcc-www/hooks';
import { PartExchangeProvider } from '../part-exchange.hook';
import { PartExchangeOverlay } from './overlay';

const initialContext = {
  showPartExchangeTool: () => {},
};

const OverlayContext = createContext<OverlayContextType>(initialContext);

export const OverlayProvider: React.FC<OverlayProviderType> = ({
  loginRoute,
  authTokenRoute,
  children,
  dictionaries,
  content,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');

  const showPartExchangeTool = useCallback(
    (props?: ShowPartExchangeToolProps) => {
      setTitle(props?.title || 'Part Exchange');
      setIsOpen(true);
    },
    [],
  );

  const properties = useMemo(
    () => ({ showPartExchangeTool }),
    [showPartExchangeTool],
  );

  return (
    <OverlayContext.Provider value={properties}>
      <BreakpointsProvider>
        <SharedContentDictionariesProvider
          dictionaries={dictionaries}
          locale="en-GB"
        >
          <PartExchangeProvider
            content={content}
            loginRoute={loginRoute}
            authTokenRoute={authTokenRoute}
          >
            {children}
            <PartExchangeOverlay
              open={isOpen}
              onClose={() => setIsOpen(false)}
              overlayTitle={title}
            />
          </PartExchangeProvider>
        </SharedContentDictionariesProvider>
      </BreakpointsProvider>
    </OverlayContext.Provider>
  );
};

export const usePartExchangeOverlay = () => {
  const overlayContext = useContext(OverlayContext);
  if (overlayContext === undefined) {
    throw new Error('useOverlay must be inside a Provider');
  }
  return useContext(OverlayContext);
};
