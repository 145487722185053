import React from 'react';
import { TextInput } from 'vcc-ui';
import { SingleSelect } from '@vcc-package/single-select';
import { Markdown } from '@vcc-package/text';
import { usePartExchange, usePartExchangeTool } from './part-exchange.hook';
import { Option } from './part-exchange.types';
import { isValidNumber } from './part-exchange.utils';
import { TitleWithSubTitle } from './components/TitleWithSubTitle';
import { useTranslate } from './part-exchange.dictionaries-provider';
import { YesNoQuestion } from '@vcc-package/yes-no-question';
import { ErrorView } from './components/ErrorView';

interface QuestionViewProps {
  groupIndex: number;
}

export const QuestionsView: React.FC<QuestionViewProps> = ({ groupIndex }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const {
    setQuestions,
    questions,
    outstandingFinance,
    setOutstandingFinance,
    error,
  } = usePartExchange();
  const { saveItemToLocalStorage } = usePartExchangeTool();

  const translate = useTranslate();

  const defaultSelected = (options: Option[], answer: string) => {
    const filteredOption = options.find((option) => {
      return option.slug === answer;
    });
    if (filteredOption?.slug === 'yes' || filteredOption?.slug === 'true') {
      return 'yes';
    } else if (
      filteredOption?.slug === 'no' ||
      filteredOption?.slug === 'false'
    ) {
      return 'no';
    }
    return null;
  };

  const titles = [
    translate('financeOptions.partExchangeTool.conditionTitle'),
    translate('financeOptions.partExchangeTool.aboutCarTitle'),
    translate('financeOptions.partExchangeTool.financeTitle'),
  ];
  const subTitles = [
    translate('financeOptions.partExchangeTool.conditionSubTitle'),
    translate('financeOptions.partExchangeTool.aboutCarSubTitle'),
    translate('financeOptions.partExchangeTool.financeSubTitle'),
    '',
  ];

  if (error) {
    return <ErrorView title={error.title} body={error.body} />;
  }

  return (
    <div>
      <TitleWithSubTitle
        title={titles[groupIndex]}
        subTitle={subTitles[groupIndex]}
      />
      {questions[groupIndex] &&
        questions[groupIndex].map((question, index) => {
          const options = question.question.options;

          if (
            question.question.slug ===
              'outstandingFinanceVolvoUkVolvoExcitement' ||
            question.question.slug ===
              'outstandingFinanceVolvoVolvoUkVolvoCareFifteenSure'
          ) {
            return (
              <OutstandingFinanceInput
                key={question.question.slug}
                selectedIndex={selectedIndex % options.length}
                defaultSelectedIndex={options.findIndex(
                  (option) =>
                    option.slug === questions[groupIndex][0].answer.answer,
                )}
                options={options}
                onAnswerChange={(selectedIndex) => {
                  setSelectedIndex(selectedIndex);
                  const updatedAnswers = [...questions];
                  updatedAnswers[groupIndex][0].answer.answer =
                    options[selectedIndex].slug;
                  setQuestions(updatedAnswers);
                  saveItemToLocalStorage(
                    'questionsWithAnswers',
                    updatedAnswers,
                  );
                }}
              >
                <TextInput
                  value={outstandingFinance}
                  isValid={
                    isValidNumber(outstandingFinance) ||
                    outstandingFinance === ''
                  }
                  errorMessage={
                    isValidNumber(outstandingFinance) ||
                    outstandingFinance === ''
                      ? ''
                      : translate(
                          'financeOptions.partExchangeTool.financeInputErrorMsg',
                        )
                  }
                  onChange={(event) => {
                    setOutstandingFinance(event.target.value);
                    saveItemToLocalStorage(
                      'outstandingFinance',
                      event.target.value,
                    );
                  }}
                  label="Amount"
                />
              </OutstandingFinanceInput>
            );
          }

          return question.question.type === 'Slider' ? (
            <div key={question.question.slug}>
              <SingleSelect
                defaultSelectedIndex={options.findIndex(
                  (option) =>
                    option.slug === questions[groupIndex][0].answer.answer,
                )}
                options={options.map((option) => ({
                  ...option,
                  title: option.text,
                }))}
                onChange={(selectedIndex) => {
                  setSelectedIndex(selectedIndex);
                  const updatedAnswers = [...questions];
                  updatedAnswers[groupIndex][0].answer.answer =
                    options[selectedIndex].slug;
                  setQuestions(updatedAnswers);
                  saveItemToLocalStorage(
                    'questionsWithAnswers',
                    updatedAnswers,
                  );
                }}
              >
                <p>{options[selectedIndex].tooltip}</p>
                <Markdown
                  className="micro text-start mt-16 text-secondary"
                  markdown={options[selectedIndex].description}
                  enableLists
                />
              </SingleSelect>
            </div>
          ) : (
            <div className="mb-24" key={question.question.slug}>
              <YesNoQuestion
                defaultValue={defaultSelected(
                  options,
                  questions[groupIndex][index].answer.answer,
                )}
                titleVariant="font-16"
                descriptionVariant="micro"
                title={question.question.text}
                titleWeight="standard"
                description={question.question.tooltip}
                yesText={options[1].text}
                noText={options[0].text}
                onChange={(value: boolean) => {
                  const updatedAnswers = [...questions];
                  updatedAnswers[groupIndex][index].answer.answer = value
                    ? options[1].slug
                    : options[0].slug;
                  setQuestions(updatedAnswers);
                  saveItemToLocalStorage(
                    'questionsWithAnswers',
                    updatedAnswers,
                  );
                }}
              />
            </div>
          );
        })}
    </div>
  );
};

const OutstandingFinanceInput: React.FC<{
  defaultSelectedIndex: number;
  options: Option[];
  onAnswerChange: (value: number, option?: any) => void;
  selectedIndex: number;
  children?: React.ReactNode;
}> = ({ defaultSelectedIndex, options, onAnswerChange, children }) => {
  const translate = useTranslate();
  const padding = defaultSelectedIndex === 1 ? '' : 'pb-64';
  return (
    <div className={padding}>
      <SingleSelect
        alwaysShowChildren
        defaultSelectedIndex={defaultSelectedIndex}
        options={options.map((option) => ({
          ...option,
          title:
            option.text === 'Yes'
              ? translate('financeOptions.partExchangeTool.financeYesOption')
              : translate('financeOptions.partExchangeTool.financeNoOption'),
        }))}
        onChange={onAnswerChange}
      />
      {defaultSelectedIndex === 1 && (
        <div className="mt-16">
          <p
            data-testid="part-exchange-tool:car-input-view-input-title"
            className="font-16 font-medium mb-8"
          >
            {translate('financeOptions.partExchangeTool.financeInputTitle')}
          </p>
          {children}
        </div>
      )}
    </div>
  );
};
