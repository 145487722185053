import gql from 'graphql-tag';
import {
  EMISSIONS_AND_SAFETY_DATA_FRAGMENT,
  ENERGY_EFFICIENCY_SETTINGS_FRAGMENT,
  SALES_MODEL_SETTINGS_FRAGMENT,
} from './entire-offer.fragments.query';

export const BTO_CAR_QUERY = gql`
  query BtoCar($token: String!, $locale: String!, $url: String!) {
    carByToken(token: $token) {
      id: carKey
      #FOR TRACKING PURPOSE
      pno {
        pno34
      }
      modelFamily(locale: $locale) {
        key
      }
      productLine(locale: $locale) {
        key
      }
      carKey
      carKeyExpanded {
        modelYear
      }
      #END FOR TRACKING PURPOSE
      offer {
        baseOffer
      }
      technicalData(locale: $locale) {
        ...TechnicalData
      }
      efficiencyClassification {
        ...EnergyClassification
      }
      safetyRating(locale: $locale) {
        ...SafetyRating
      }
      tyreLabels(locale: $locale) {
        ...TyreLabels
      }
      btoDeliveryLeadTimeToMarket(locale: $locale) {
        formatted
      }
      # DESIGN CODE
      configuration {
        token {
          userFriendly
        }
      }

      # DISPLAY NAME
      model(locale: $locale) {
        key
        displayName {
          value
        }
      }

      # OR
      displayName(locale: $locale)

      # LEVEL
      trim(locale: $locale) {
        displayName {
          value
        }
        key
      }

      # POWERTRAIN
      engine {
        code
        content(locale: $locale) {
          engineCode
          displayName {
            value
          }
          engineType {
            value
          }
        }
        code
      }

      # SEAT
      carType {
        content(locale: $locale) {
          seatCapacity {
            formatted
          }
        }
        code
      }

      # THEME
      theme(locale: $locale) {
        displayName {
          value
        }
        key
      }

      # EDITION
      edition(locale: $locale) {
        displayName {
          value
        }
        key
      }

      # COLOR
      color {
        content(locale: $locale) {
          displayName {
            value
          }
        }
        code
      }

      # WHEELS
      configurationByToken(token: $token) {
        options: components(type: OPTION) {
          visible
          content(locale: $locale) {
            ... on OptionContent {
              displayName {
                value
              }
              group {
                value
              }
            }
            # Needed for type checking
            ... on ColourContent {
              displayName {
                value
              }
              group {
                value
              }
            }
            # Needed for type checking
            ... on PackageContent {
              displayName {
                value
              }
              group {
                value
              }
            }
            # Needed for type checking
            ... on UpholsteryContent {
              displayName {
                value
              }
              group {
                value
              }
            }
          }
          inConfiguration {
            selected
            included
          }
          code
        }
        packages: components(type: PACKAGE) {
          content(locale: $locale) {
            ... on OptionContent {
              displayName {
                value
              }
              group {
                value
              }
            }
            # Needed for type checking
            ... on ColourContent {
              displayName {
                value
              }
              group {
                value
              }
            }
            # Needed for type checking
            ... on PackageContent {
              displayName {
                value
              }
              group {
                value
              }
            }
            # Needed for type checking
            ... on UpholsteryContent {
              displayName {
                value
              }
              group {
                value
              }
            }
          }
          inConfiguration {
            selected
            included
          }
          code
        }
      }

      options {
        content(locale: $locale) {
          displayName {
            value
          }
          images {
            url
          }
          group {
            value
          }
        }
        code
        packages {
          code
        }
      }

      # INTERIOR
      upholstery {
        content(locale: $locale) {
          displayName {
            value
          }
        }
        code
      }

      # PACKAGES
      packages {
        code
        content(locale: $locale) {
          displayName {
            value
          }
          packageCode
        }
        components {
          content(locale: $locale) {
            displayName {
              value
            }
          }
        }
      }

      # IMAGE
      visualizations(marketOrLocale: $locale) {
        views(backgroundColor: "f3f3f3") {
          exterior {
            studio {
              front {
                sizes {
                  medium {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }

    clientConfigurationQuery(PARAMETERS: [{ key: "url", value: $url }]) {
      configuration {
        salesModelSettings {
          ...SalesModelSettings
        }
        energyEfficiencySettings {
          ...EnergyEfficiencySettings
        }
      }
    }
  }

  ${EMISSIONS_AND_SAFETY_DATA_FRAGMENT}
  ${ENERGY_EFFICIENCY_SETTINGS_FRAGMENT}
  ${SALES_MODEL_SETTINGS_FRAGMENT}
`;
