'use client';
// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable no-restricted-imports */
import React from 'react';
import SubNavigation from '@vcc-package/sub-navigation';
import { useTopbarPosition } from '@volvo-cars/site-nav-embed';
import type { LinkEntry } from '@volvo-cars/content-management-client';

export type DotcomPdpSubmenuProps = {
  type: 'dotcomPdpSubmenu';
  fallbackModelName: string | undefined;
  modelSubMenuPageLinks: { link: LinkEntry }[];
  alternativeCta: LinkEntry | undefined;
};

export const DotcomPdpSubmenu = ({
  fallbackModelName,
  modelSubMenuPageLinks,
  alternativeCta,
}: DotcomPdpSubmenuProps) => {
  useTopbarPosition('relative');

  return (
    <SubNavigation.Container background="solid" sticky>
      <SubNavigation.Title
        title={fallbackModelName}
        className="until-md:hidden"
      />
      <SubNavigation.Links
        links={modelSubMenuPageLinks.map((linkObject) => {
          return {
            eventLabel: 'sub-nav-link',
            href: linkObject.link?.href || '',
            text: linkObject.link?.text || '',
            // TODO: Add accessibility label
            accessibilityLabel: '',
          };
        })}
      />
      {alternativeCta && (
        <SubNavigation.CTAs
          primaryCta={{
            eventLabel: 'sub-nav-cta',
            href: alternativeCta?.href || '',
            text: alternativeCta?.text || '',
            // TODO: Add accessibility label
            accessibilityLabel: '',
          }}
        />
      )}
    </SubNavigation.Container>
  );
};
