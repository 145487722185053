import React from 'react';
import { FinanceDetailsWithDefaultDataProps } from './finance-details.types';
import { useFinanceDetailsWithDefaultData } from './hooks/finance-details.default.hook';
import { FinanceDetailsView } from './views/finance-details.view';

export const FinanceDetailsWithDefaultData = ({
  onViewChange,
  token,
  styles,
  testId,
}: FinanceDetailsWithDefaultDataProps) => {
  const financeDetailsProps = useFinanceDetailsWithDefaultData({
    token,
    onViewChange,
    styles,
  });
  return (
    <FinanceDetailsView
      testId={testId}
      styles={styles}
      {...financeDetailsProps}
    />
  );
};
