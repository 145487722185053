import { getMarketSite } from '@volvo-cars/market-sites';

const newModels = ['ex30-electric', 'ex90-electric'];

const overriddenMaxDownPaymentForEx90 = 28000;

export const isUSRegularModel = ({
  market,
  model,
}: Partial<{ market: string; model: string }>): boolean => {
  if (!market || !model) {
    return false;
  }
  return isUSMarket(market) && !isNewModel(model);
};

export const isUSNewModel = ({
  market,
  model,
}: Partial<{ market: string; model: string }>): boolean => {
  if (!market || !model) {
    return false;
  }
  return isUSMarket(market) && isNewModel(model);
};

export const isUSMarket = (siteSlug: string | undefined): boolean => {
  const { regionCode } = getMarketSite(siteSlug);
  return regionCode === 'US';
};

export const isNewModel = (model: string | undefined): boolean => {
  return model ? newModels.includes(model) : false;
};

export const getOverriddenMaxDownPayment = ({
  market,
  model,
}: Partial<{ market: string; model: string }>): number => {
  if (isUSMarket(market) && model === 'ex90-electric') {
    return overriddenMaxDownPaymentForEx90;
  }
  return 0;
};

export const isUSPreOrderModelOrderSummaryPage = ({
  market,
  model,
  isPreOrder,
}: Partial<{
  market: string;
  model: string;
  isPreOrder: boolean;
}>): boolean => !isPreOrder && isUSMarket(market) && model === 'ex90-electric';
