'use client';
import { getDictionariesProvider } from '@vcc-www/react-translate';
import type { DictionaryItemTypes } from '@vcc-www/shared-dictionaries/DictionaryItemTypes';
import React, { createContext, FC, useContext } from 'react';
import { FALLBACKS } from '../offers.constants';
import { useOffersProviderContent } from './offers-provider.content.hook';
import type {
  ContentContextType,
  ContentProviderProps,
} from './offers-provider.content.types';
import { useBasicOffer } from '../basic-offer/offers-provider.basic-offer.provider';

// Rename to avoid reexport warning.
const {
  useTranslate: useTranslateTemp,
  DictionariesProvider: DictionariesProviderTemp,
} = getDictionariesProvider<DictionaryItemTypes>();

export const useTranslate = useTranslateTemp;
export const DictionariesProvider = DictionariesProviderTemp;

export const ContentContext = createContext<ContentContextType>({
  loading: false,
  disclaimers: [],
  sharedTranslations: {},
  translate: () => '',
});

ContentContext.displayName = 'ContentContext';

export const ContentProvider: FC<
  React.PropsWithChildren<ContentProviderProps>
> = ({ children, initialContent }) => {
  const {
    clientName,
    isInStore,
    isKompass,
    locale,
    market,
    deployEnv,
    env: { qawwwHeader, testwwwHeader } = {},
    salesModel,
    customerType,
    previewState,
  } = useBasicOffer();
  const content = useOffersProviderContent({
    clientName,
    initialContent,
    isInStore,
    isKompass,
    locale,
    market,
    deployEnv,
    qawwwHeader,
    testwwwHeader,
    salesModel,
    customerType,
    previewState,
  });

  return (
    <DictionariesProviderTemp
      locale={locale ?? FALLBACKS.locale}
      dictionaries={content?.sharedTranslations ?? {}}
    >
      <ContentContext.Provider value={content}>
        {children}
      </ContentContext.Provider>
    </DictionariesProviderTemp>
  );
};

export const useContent = (): ContentContextType => {
  const contentContext = useContext(ContentContext);
  if (contentContext === undefined) {
    throw new Error('useContent must be inside a Provider with a value');
  }
  return contentContext;
};

export const useIsContentLoading = () => {
  const contentContext = useContext(ContentContext);
  if (contentContext === undefined) {
    throw new Error(
      'useIsContentLoading must be inside a Provider with a value',
    );
  }
  return contentContext?.loading ?? false;
};
