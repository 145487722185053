import { useOverlay } from '@vcc-package/overlays/DEPRECATED';
import { useMemo } from 'react';
import {
  FinanceDetailsProps,
  FinanceDetailsWithDefaultDataProps,
} from '../finance-details.types';
import {
  getDisclaimers,
  getFinancialOptionDescriptions,
  getHowItWorks,
  getSalesModelImageUrl,
  getServiceDescriptions,
  getServicesAndPackages,
} from '../finance-details.utils';
import { useFinanceDetailsWithDefaultDataQuery } from './finance-details.default-query.hook';
import { useContent } from '@vcc-package/offers-provider';

export const useFinanceDetailsWithDefaultData = ({
  onViewChange,
  token,
  styles,
}: FinanceDetailsWithDefaultDataProps): FinanceDetailsProps => {
  const { translate } = useContent();
  const { openOverlayPage } = useOverlay();

  const { data, loading } = useFinanceDetailsWithDefaultDataQuery({
    token,
  });

  const { sortedServices, packages } = data?.offerByToken?.products ?? {};
  const { content } = data?.offerByToken?.salesModel ?? {};

  const financialOptionDescriptions = useMemo(
    () => getFinancialOptionDescriptions({ content }),
    [content],
  );

  const disclaimers = useMemo(
    () => getDisclaimers({ content, sortedServices, translate }),
    [content, sortedServices, translate],
  );

  const howItWorks = useMemo(
    () => getHowItWorks({ content, translate }),
    [content, translate],
  );

  const serviceDescriptions = useMemo(() => {
    const servicesAndPackages = getServicesAndPackages({
      sortedServices,
      packages,
    });

    return getServiceDescriptions({
      translate,
      servicesAndPackages,
      onViewChange: onViewChange ?? openOverlayPage,
      styles,
    });
  }, [
    onViewChange,
    packages,
    sortedServices,
    translate,
    openOverlayPage,
    styles,
  ]);

  const salesModelImageUrl = useMemo(
    () => getSalesModelImageUrl({ content }),
    [content],
  );

  return {
    salesModelImageUrl,
    financialOptionDescriptions,
    howItWorks,
    serviceDescriptions,
    disclaimers,
    loading,
  };
};
