import { useMemo } from 'react';
import {
  FinanceDetailsProps,
  FinanceDetailsWithCustomDataProps,
} from '../finance-details.types';
import { getCustomServiceDescriptions } from '../finance-details.utils';
import { useOverlay } from '@vcc-package/overlays/DEPRECATED';

export const useFinanceDetailsWithCustomData = ({
  financeDetails,
  onViewChange,
  styles,
  translate,
}: FinanceDetailsWithCustomDataProps): FinanceDetailsProps => {
  const { openOverlayPage } = useOverlay();

  const serviceDescriptions = useMemo(
    () =>
      getCustomServiceDescriptions({
        financeDetails,
        onViewChange: onViewChange ?? openOverlayPage,
        translate,
        styles,
      }),
    [financeDetails, onViewChange, openOverlayPage, translate, styles],
  );

  return {
    ...financeDetails,
    serviceDescriptions,
    loading: false,
  };
};
