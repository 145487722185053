// United States of America

import { BUSINESS_MODELS, SALES_MODELS } from '@vcc-package/offers-utils';
import {
  MarketSettingsAsFunctions,
  MarketSettings,
} from './market-settings.types';
import { supportedFinancialBreakdownItems as globalSupportedFinancialBreakdownItems } from './global';
import { BREAKDOWN_ITEMS } from '../price-breakdown.constants';

const { CONTRACT_LENGTH, PURCHASE_PRICE, AMOUNT_OF_CREDIT, APR } =
  BREAKDOWN_ITEMS;

const isEarlyAccessFlow = (modelSlug?: string) =>
  ['ex30-electric', 'ex90-electric'].includes(modelSlug ?? '');

export const supportsVat: MarketSettings['supportsVat'] = false;

export const priceAnnotationCharacter: MarketSettingsAsFunctions['priceAnnotationCharacter'] =
  ({ modelSlug }) => (isEarlyAccessFlow(modelSlug) ? '*' : '');

export const showTotalPriceAsDueBeforeDeliveryTitle: MarketSettingsAsFunctions['showTotalPriceAsDueBeforeDeliveryTitle'] =
  ({ businessModel, salesModel, modelSlug, preOrderId }) =>
    businessModel === BUSINESS_MODELS.WHOLESALE &&
    !(salesModel === SALES_MODELS.LEASE || salesModel === SALES_MODELS.LOAN) &&
    isEarlyAccessFlow(modelSlug) &&
    !preOrderId;

export const showRegularModelTitle: MarketSettingsAsFunctions['showRegularModelTitle'] =
  ({ modelSlug, preOrderId }) => isEarlyAccessFlow(modelSlug) && !preOrderId;

export const showFinancingDetails: MarketSettingsAsFunctions['showFinancingDetails'] =
  ({ salesModel }) => salesModel === SALES_MODELS.LOAN;

export const showDownPaymentAsOnlyAfterCheckoutItem: MarketSettingsAsFunctions['showDownPaymentAsOnlyAfterCheckoutItem'] =
  ({ salesModel }) => salesModel === SALES_MODELS.LOAN;

export const showFirstMonthlyPaymentLineItemInPayLater: MarketSettingsAsFunctions['showFirstMonthlyPaymentLineItemInPayLater'] =
  ({ salesModel }) => salesModel === SALES_MODELS.LEASE;

export const taxesTypeInPayLater: MarketSettingsAsFunctions['taxesTypeInPayLater'] =
  ({ modelSlug }) =>
    isEarlyAccessFlow(modelSlug) ? 'transaction' : 'acquisition';

export const enableEditConfiguration: MarketSettings['enableEditConfiguration'] =
  true;

export const supportedFinancialBreakdownItems: MarketSettingsAsFunctions['supportedFinancialBreakdownItems'] =
  ({ modelSlug }) =>
    isEarlyAccessFlow(modelSlug)
      ? [CONTRACT_LENGTH, PURCHASE_PRICE, AMOUNT_OF_CREDIT, APR]
      : globalSupportedFinancialBreakdownItems;
