'use client';
import {
  initialValue,
  useFeatureFlagsWithoutProvider,
} from '@vcc-www/feature-flags';
import { SYSTEM } from '@vcc-package/preview/src/preview.constants';
import { supportedFinancialBreakdownItems as chSupportedFinancialBreakdownItems } from '@vcc-package/price-breakdown/v2/src/market-settings/ch';
import { supportedFinancialBreakdownItems as deSupportedFinancialBreakdownItems } from '@vcc-package/price-breakdown/v2/src/market-settings/de';
import { supportedFinancialBreakdownItems as fiSupportedFinancialBreakdownItems } from '@vcc-package/price-breakdown/v2/src/market-settings/fi';
import { supportedFinancialBreakdownItems as frSupportedFinancialBreakdownItems } from '@vcc-package/price-breakdown/v2/src/market-settings/fr';
import { supportedFinancialBreakdownItems as globalSupportedFinancialBreakdownItems } from '@vcc-package/price-breakdown/v2/src/market-settings/global';
import { supportedFinancialBreakdownItems as ukSupportedFinancialBreakdownItems } from '@vcc-package/price-breakdown/v2/src/market-settings/uk';
import { supportedFinancialBreakdownItems as usSupportedFinancialBreakdownItems } from '@vcc-package/price-breakdown/v2/src/market-settings/us';
import isEqual from 'lodash/isEqual';
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useBasicOffer } from '../basic-offer/offers-provider.basic-offer.provider';
import { clientSideIDByDeployEnv } from '../constants/launch-darkly';
import { usePrevious } from '../entire-offer/entire-offer.utils';
import {
  isUSNewModel,
  getOverriddenMaxDownPayment,
  isUSMarket,
} from '../market-checks/us/market-checks';
import {
  MarketSettings,
  SettingsFlagData,
  SettingsProviderProps,
} from './offers-provider.settings.types';
import {
  BUSINESS_MODELS,
  getTypeOfBrowser,
  hasValidOfferSelections,
  isPreOrderModelOrderSummaryFlow,
} from '@vcc-package/offers-utils';
import Cookies from 'js-cookie';
import { GA4_ANALYTICS_COOKIE } from '../offers.constants';
import { FlagsProviderContext } from '@vcc-www/feature-flags/src/flags.types';
import { MARKET_DISCLAIMERS } from './offers-provider.settings.constants';

const initialSettingsContext = {
  ...initialValue,
  marketSettings: {},
  overrideFlags: {},
};

type SettingsProviderContextType = FlagsProviderContext & {
  marketSettings?: MarketSettings | undefined;
  overrideFlags?: Partial<SettingsFlagData> | undefined;
};

export const SettingsContext = createContext<SettingsProviderContextType>(
  initialSettingsContext,
);

SettingsContext.displayName = 'SettingsContext';

export const SettingsProvider: FC<
  React.PropsWithChildren<SettingsProviderProps>
> = ({ children, enabled = false }) => {
  const {
    customerType,
    deployEnv,
    flagsProviderProps,
    isInStore,
    isKompass,
    isDirectSales,
    isStockCar,
    market,
    marketSettings,
    model,
    modelYear,
    overrideFlags,
    pno12,
    previewState,
    preOrderId,
    salesModel,
    salesModelSelections,
    useGA4CookieAsLDUserKey,
  } = useBasicOffer();
  const { isServer } = getTypeOfBrowser();

  /*
   * The reason for using GA4 analytics cookie here is that all the fact data (collected by Google Analytics)
   * also has that cookie attached to it. So it's much easier to calculate reliable metrics when randomizing.
   */
  let gaUserKey;
  if (!isServer) {
    gaUserKey = Cookies?.get(GA4_ANALYTICS_COOKIE);
  }

  const customAttributes = useMemo(
    () => ({
      businessModel: isDirectSales
        ? BUSINESS_MODELS.DIRECT
        : BUSINESS_MODELS.WHOLESALE,
      model: model || '',
      market: market || '',
      isStockCar: !!isStockCar,
      customerType: customerType || '',
      salesModel: salesModel || '',
      pno12: pno12 || '',
      sessionType: null,
      fleetRole: '',
      fleetIsKeyAccount: false,
      isPreOrder: !!preOrderId,
      isLoggedIn: false,
      // required for Global DotCom
      isCbv: isDirectSales,
      modelSlug: model || '',
      modelYear: modelYear || '',
      siteSlug: market || '',
      isKompass,
      isInStore,
    }),
    [
      customerType,
      isInStore,
      isKompass,
      isDirectSales,
      isStockCar,
      market,
      model,
      modelYear,
      pno12,
      preOrderId,
      salesModel,
    ],
  );
  const previousCustomAttributes = usePrevious(customAttributes);
  // fetch the flags directly
  const flagsContext = useFeatureFlagsWithoutProvider({
    clientName: 'offers-provider',
    customAttributes,
    globalFlagsEnv: deployEnv,
    ldClientId:
      clientSideIDByDeployEnv[
        String(previewState?.overrideSources?.[SYSTEM.LAUNCH_DARKLY] ?? '') ||
          deployEnv ||
          'prod'
      ],
    initialFlags: flagsProviderProps?.initialFlags,
    ldUserKey: useGA4CookieAsLDUserKey
      ? gaUserKey
      : flagsProviderProps?.ldUserKey,
    ldOptions: flagsProviderProps?.ldOptions,
  });

  // Update LD flags when attributes change (required for Offer Drawer on Car Listing Page in Car Selector app)
  useEffect(() => {
    if (
      previousCustomAttributes &&
      !isEqual(customAttributes, previousCustomAttributes)
    ) {
      flagsContext?.updateAttributes(customAttributes);
    }
  }, [customAttributes, previousCustomAttributes, flagsContext]);

  const supportedFinanceDetailsByMarket = useMemo(() => {
    const MARKET_SUPPORTED_FINANCE_DETAILS: { [key: string]: string[] } = {
      de: deSupportedFinancialBreakdownItems,
      default: globalSupportedFinancialBreakdownItems,
      'de-ch': chSupportedFinancialBreakdownItems,
      fi: fiSupportedFinancialBreakdownItems,
      fr: frSupportedFinancialBreakdownItems,
      'fr-ch': chSupportedFinancialBreakdownItems,
      'it-ch': chSupportedFinancialBreakdownItems,
      uk: ukSupportedFinancialBreakdownItems,
      us: usSupportedFinancialBreakdownItems({ modelSlug: model }),
    };

    return (
      MARKET_SUPPORTED_FINANCE_DETAILS[market ?? ''] ??
      MARKET_SUPPORTED_FINANCE_DETAILS['default']
    );
  }, [market, model]);

  const contextValue = enabled
    ? {
        ...flagsContext,
        overrideFlags: {
          ...overrideFlags,
          ...previewState?.overrideFlags,
        },
        marketSettings: {
          ...marketSettings,
          showSalesModelStageDisclaimer:
            MARKET_DISCLAIMERS?.SALES_MODEL_STAGE?.includes(market ?? '')
              ? true
              : false,
          showFinanceDetailsDisclaimer:
            MARKET_DISCLAIMERS?.FINANCE_DETAILS?.includes(market ?? ''),
          showOfferSelection:
            marketSettings?.showOfferSelection ??
            (hasValidOfferSelections({ salesModel, salesModelSelections }) &&
              (isUSMarket(market)
                ? !isStockCar ||
                  !isPreOrderModelOrderSummaryFlow(String(model), preOrderId)
                : true)),
          showPartExchange: marketSettings?.showPartExchange ?? false,
          showPreOrderSummaryFlow:
            marketSettings?.showPreOrderSummaryFlow ??
            (isUSMarket(market) &&
              !isDirectSales &&
              isPreOrderModelOrderSummaryFlow(
                customAttributes.modelSlug,
                preOrderId,
              )),
          supportedFinancialBreakdownItems: supportedFinanceDetailsByMarket,
        },
        marketChecks: {
          US: {
            isUSNewModel: isUSNewModel({
              market,
              model,
            }),
            overriddenMaxDownPayment: getOverriddenMaxDownPayment({
              market,
              model,
            }),
          },
        },
      }
    : initialSettingsContext;

  return (
    <SettingsContext.Provider value={contextValue}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  const { flags, globalFlags, overrideFlags, marketChecks, marketSettings } =
    useContext(SettingsContext);
  const settings: SettingsFlagData = {
    ...(globalFlags ?? {}),
    ...flags,
    ...marketSettings,
    ...overrideFlags,
    marketChecks,
  };
  return settings;
};

export const useSettingsUtils = () => {
  return useContext(SettingsContext);
};
