'use client';
import { useMemo } from 'react';
import { useSettings } from '../settings/offers-provider.settings.provider';
import { useContent } from '../content/offers-provider.content.provider';
import { UseBenefitInKindProps } from './offers-provider.fleet-session.types';
import {
  getBenefitInKindProps,
  getBenefitInKindTitle,
} from './offers-provider.fleet-session.utils';

export const useBenefitInKind = ({
  isDirectSales,
  isQAHost,
  isStockCar,
  locale,
  openBenefitInKindModal,
  regionCode,
  salesModel,
  token,
}: UseBenefitInKindProps) => {
  const { translate } = useContent();
  const { enableBenefitInKind } = useSettings();
  const benefitInKindTitle = getBenefitInKindTitle({ translate });

  const benefitInKindProps = useMemo(
    () =>
      getBenefitInKindProps({
        enableBenefitInKind,
        isDirectSales: !!isDirectSales,
        isQAHost,
        isStockCar,
        locale,
        openBenefitInKindModal,
        regionCode,
        salesModel,
        token,
      }),
    [
      enableBenefitInKind,
      isDirectSales,
      isQAHost,
      isStockCar,
      locale,
      openBenefitInKindModal,
      regionCode,
      salesModel,
      token,
    ],
  );

  return {
    enableBenefitInKind,
    benefitInKindProps,
    benefitInKindTitle,
  };
};
