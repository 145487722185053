import { OperationVariables, useQuery } from '@apollo/client';

import { getLocaleForFedGraph } from '@vcc-package/offers-utils';
import { useRef } from 'react';
import { UseCarQueryProps } from '../entire-offer.types';
import { formatApolloError } from '../entire-offer.utils';

export const useCarQuery = <Q, V extends OperationVariables>({
  skip,
  clientName,
  locale,
  query,
  market,
  optionalVariables,
}: UseCarQueryProps) => {
  const storedData = useRef<Q | null>(null);

  const variables = {
    locale: getLocaleForFedGraph(locale),
    url: `http://www.volvocars.com/${market}/all`,
    ...optionalVariables,
  } as unknown as V;

  const { data, error, loading, previousData, networkStatus } = useQuery<Q, V>(
    query,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      variables,
      skip: skip || !!storedData?.current,
      onCompleted: (d) => (storedData.current = d),
      context: { headers: { 'x-client-name': clientName } },
    },
  );

  return {
    data,
    networkStatus,
    formattedCarError: error
      ? formatApolloError({ apolloError: error, variables, query })
      : null,
    loading,
    error,
    carData: data || previousData || storedData.current || undefined,
  };
};
