import { useCallback, useEffect, useState } from 'react';
import {
  useSettings,
  useSettingsUtils,
} from '../settings/offers-provider.settings.provider';
import {
  FleetProviderContextType,
  FleetProviderProps,
} from './offers-provider.fleet-session.types';
import isEqual from 'lodash/isEqual';
import { getEnv, getSessionType } from '@vcc-package/offers-utils';
import { useBenefitInKind } from './offers-provider.fleet-session.benefit-in-kind.hook';
import {
  getDeployEnv,
  getFleetSessionAttributesToUpdate,
  getFleetSessionFetchInput,
  initialFleetSessionData,
  updateFleetSession,
} from './offers-provider.fleet-session.utils';
import type { Session } from '@vcc-www/auth-client/session-service/types';

export const useOffersProviderFleetSession = ({
  clientName,
  deployEnv = 'prod',
  isDirectSales,
  isStockCar,
  locale,
  previewState,
  qawwwHeader,
  regionCode,
  salesModel,
  testwwwHeader,
  token,
}: FleetProviderProps): FleetProviderContextType => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { updateAttributes } = useSettingsUtils();
  const { enableEditConfiguration } = useSettings();
  const [fleetSessionData, setFleetSessionData] = useState<Session>(
    initialFleetSessionData,
  );

  useEffect(() => {
    setIsLoaded(false);
    setFleetSessionData(initialFleetSessionData);
  }, []);

  const deployEnvironment = getDeployEnv({ previewState, deployEnv });

  const { domain, isQAHost } = getEnv({
    deployEnv: deployEnvironment,
    clientName,
  });

  const fleetSessionFetchInput = getFleetSessionFetchInput({
    clientName,
    regionCode,
    enableEditConfiguration,
  });

  const onUpdateFleetSession = useCallback(
    () =>
      updateFleetSession({
        setIsLoading,
        domain,
        clientName,
        fleetSessionFetchInput,
        deployEnv,
        testwwwHeader,
        qawwwHeader,
        setFleetSessionData,
        setIsLoaded,
      }),
    [
      clientName,
      deployEnv,
      domain,
      fleetSessionFetchInput,
      qawwwHeader,
      testwwwHeader,
    ],
  );

  useEffect(() => {
    if (
      !isLoaded &&
      !isLoading &&
      regionCode &&
      isEqual(fleetSessionData, initialFleetSessionData)
    ) {
      onUpdateFleetSession();
    }
  }, [fleetSessionData, isLoaded, isLoading, regionCode, onUpdateFleetSession]);

  // backup safety net to ensure we don't get stuck in a loading state
  useEffect(() => {
    if (isLoaded && isLoading) {
      setIsLoading(false);
    }
  }, [isLoaded, isLoading]);

  const { sharedData } = fleetSessionData || {};

  const sessionType = getSessionType(sharedData);

  const benefitInKind = useBenefitInKind({
    isDirectSales,
    isQAHost,
    token,
    regionCode,
    locale,
    salesModel,
    isStockCar,
    openBenefitInKindModal: () => {},
  });

  const fleetSessionAttributesToUpdate = getFleetSessionAttributesToUpdate({
    fleetPersona: sharedData?.fleetPersona,
    fleetSessionType: sessionType,
    isFleetKeyAccount: !!sharedData?.fleetIsKeyAccount,
  });

  useEffect(() => {
    if (sharedData?.fleetPersona) {
      updateAttributes(fleetSessionAttributesToUpdate);
    }
  }, [
    fleetSessionAttributesToUpdate,
    sharedData?.fleetPersona,
    updateAttributes,
  ]);

  return {
    isLoading,
    session: fleetSessionData,
    onUpdateFleetSession,
    benefitInKind,
  };
};
