'use client';
import React, { createContext, useContext } from 'react';
import { useOffersProviderBasicOffer } from './offers-provider.basic-offer.hook';
import { OfferProviderType } from '../offer/offer.provider.types';
import { FALLBACKS } from '../offers.constants';

export const BasicOfferContext = createContext<OfferProviderType>({
  market: '',
  clientName: '',
  country: '',
  locale: '',
  regionCode: '',
  isStockCar: false,
});

BasicOfferContext.displayName = 'BasicOfferContext';

export const BasicOfferProvider: React.FC<
  React.PropsWithChildren<OfferProviderType>
> = ({ children, ...props }) => {
  const offer = useOffersProviderBasicOffer({
    ...props,
    ...props?.dotcom,
    ...props?.clientType,
    ...props?.env,
    deployEnv: props?.deployEnv,
    ...props?.offer,
    ...props?.prices,
    locale: props?.locale ?? FALLBACKS.locale,
    country: props?.country,
    regionCode: props?.regionCode,
    isStockCar: props?.isStockCar,
    previewState: props?.previewState,
    initialPrices: props?.initialPrices,
    onError: props?.onError,
  });
  return (
    <BasicOfferContext.Provider value={{ ...offer }}>
      {children}
    </BasicOfferContext.Provider>
  );
};

export const useBasicOffer = () => {
  const offersContext = useContext<OfferProviderType>(BasicOfferContext);
  if (!offersContext?.clientName)
    throw new Error('useBasicOffer must be inside a Provider with a value');
  return offersContext;
};
