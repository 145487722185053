import { useCallback, useMemo } from 'react';
import { BtoCarQuery } from '../../queries/fed-graph/entire-offer.bto.query.gql-types';
import {
  ENERGY_EFFICIENT_TYPE,
  EnergyEfficiencyType,
  Grades,
  TyreLabelVariant,
  UseEntireOfferCarEmissionsAdapterProps,
} from '../entire-offer.types';
import { kebabToCamelCase } from '../entire-offer.utils';

const convertKeys = (
  clientConfigurationQuery: BtoCarQuery['clientConfigurationQuery'],
  type: EnergyEfficiencyType,
) => {
  return clientConfigurationQuery?.configuration?.energyEfficiencySettings?.energyEfficiencyKeys
    ?.find(
      (energyEfficiencyKey) =>
        energyEfficiencyKey?.name?.toUpperCase() === type,
    )
    ?.values?.map((value) => kebabToCamelCase(value)); // The new keys are in camelCase, the old keys are in kebab-case
};
const wltpNedcFilter = (keys: string[] | undefined) => (technicalData: any) =>
  keys?.includes(technicalData.key || technicalData.legacyKey || '');
const wltpNedcMap = (wltpNedc: any) => ({
  name: wltpNedc.label || '',
  nameSources: [`data:wltpData.label`],
  value: wltpNedc.formatted,
  valueSources: [`data:wltpData.formatted`],
});

export const useEntireOfferCarEmissionsAdapter = ({
  carData,
  isStockCar,
}: UseEntireOfferCarEmissionsAdapterProps) => {
  const { stockCars, carByToken, clientConfigurationQuery } = carData ?? {};
  const [firstHit] = stockCars?.hits ?? [];

  const dataField = isStockCar ? firstHit?.vehicle?.specification : carByToken;
  const dataSource = isStockCar
    ? 'firstHit.vehicle.specification'
    : 'carByToken';
  const allTechnicalData = dataField?.technicalData?.all;

  const wltpKeys = convertKeys(
    clientConfigurationQuery,
    ENERGY_EFFICIENT_TYPE.WLTP,
  );
  const nedcKeys = convertKeys(
    clientConfigurationQuery,
    ENERGY_EFFICIENT_TYPE.NEDC,
  );
  const wltpData = allTechnicalData
    ?.filter(wltpNedcFilter(wltpKeys))
    ?.map(wltpNedcMap);
  const nedcData = allTechnicalData
    ?.filter(wltpNedcFilter(nedcKeys))
    ?.map(wltpNedcMap);
  const safetyRating = Number(dataField?.safetyRating?.safetyRating ?? -1);
  const safetyRatingSources = useMemo(
    () => [`data:${dataSource}.safetyRating.safetyRating`],
    [dataSource],
  );
  const modelCode = dataField?.safetyRating?.modelCode;
  const modelCodeSources = useMemo(
    () => [`data:${dataSource}.safetyRating.modelCode`],
    [dataSource],
  );
  const efficiencyClass = Number(
    dataField?.efficiencyClassification?.efficiencyClass ?? -1,
  );
  const efficiencyClassSources = useMemo(
    () => [`data:${dataSource}.efficiencyClassification.efficiencyClass`],
    [dataSource],
  );
  const hasErrorFindingEnergyEfficiency =
    !!dataField?.efficiencyClassification?.errorMessage;
  const errorMessage = hasErrorFindingEnergyEfficiency
    ? `${dataField?.efficiencyClassification?.errorMessage?.errorCode}: ${dataField?.efficiencyClassification?.errorMessage?.errorEfficiencyClass}`
    : '';
  const getTyreLabels = useCallback(
    () =>
      dataField?.tyreLabels?.map((tyreLabel) => ({
        imageUrl: tyreLabel.url,
        variant: tyreLabel.metadata?.winter
          ? TyreLabelVariant.WINTER
          : TyreLabelVariant.SUMMER,
        position: tyreLabel.metadata?.position,
        linkUrl: tyreLabel.metadata?.eprelUrl,
      })) || [],
    [dataField?.tyreLabels],
  );
  const tyreLabels = getTyreLabels();

  return useMemo(
    () => ({
      hasData:
        dataField?.efficiencyClassification !== null &&
        dataField?.efficiencyClassification?.efficiencyClass !== null,
      wltpData,
      nedcData,
      safetyRating,
      safetyRatingSources,
      modelCode,
      modelCodeSources,
      efficiencyClass,
      efficiencyClassSources,
      grade: dataField?.efficiencyClassification?.efficiencyClass as Grades,
      grades: dataField?.efficiencyClassification?.efficiencyRanges as Grades[],
      errorMessage,
      standardTyres: tyreLabels
        ?.filter((tyreLabel) => tyreLabel.variant === TyreLabelVariant.SUMMER)
        .map(({ variant, ...rest }) => rest),
      winterTyres: tyreLabels
        ?.filter((tyreLabel) => tyreLabel.variant === TyreLabelVariant.WINTER)
        .map(({ variant, ...rest }) => rest),
    }),
    [
      dataField?.efficiencyClassification,
      efficiencyClass,
      efficiencyClassSources,
      errorMessage,
      modelCode,
      modelCodeSources,
      nedcData,
      wltpData,
      safetyRating,
      safetyRatingSources,
      tyreLabels,
    ],
  );
};
