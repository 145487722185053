import React from 'react';
import { cssMerge } from '@volvo-cars/css/utils';
import styles from '../SubNavigation.module.css';
import '../global.css';

export type ContainerProps = {
  background?: 'transparent' | 'blur' | 'solid';
  colorMode?: 'light' | 'dark';
  sticky?: boolean;
  className?: string;
  classNameInnerContainer?: string;
};

const Container: React.FC<React.PropsWithChildren<ContainerProps>> = ({
  background,
  colorMode = 'light',
  sticky,
  className,
  classNameInnerContainer,
  children,
}) => (
  <div
    data-autoid="sub-navigation"
    data-testid="sub-navigation"
    className={cssMerge(
      'w-full z-navigation',
      styles.container,
      background === 'transparent' && 'bg-transparent',
      background === 'blur' && styles.backgroundBlur,
      background === 'solid' && 'bg-primary',
      sticky && 'sticky top-0',
      className,
    )}
    data-color-mode={colorMode}
  >
    <div
      className={cssMerge(
        'container-xl flex lg:grid relative h-full justify-between items-center',
        styles.innerContainer,
        classNameInnerContainer,
      )}
      data-bleed="until-md"
    >
      {children}
    </div>
  </div>
);

export default Container;
