export enum SalesModelName {
  Sub = 'sub',
  SubFixed = 'subFixed',
  Cash = 'cash',
  Loan = 'loan',
  Lease = 'lease',
}

export enum SalesModelNameLegacy {
  Sub = 'sub',
  SubFixed = 'subFixed',
  Cash = 'cash',
}
