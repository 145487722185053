'use client';
import React from 'react';
import { TrackingProvider, useTracker } from '@volvo-cars/tracking';
import {
  type SalesModelId,
  type FinanceOption,
} from '@vcc-www/api/finance-options/types';
import { BaseImage } from '@vcc-package/media';
import { type ShowFinanceDetailsProps } from '@vcc-package/finance-details/src/finance-details.types';
import { useFinanceDetails } from '@vcc-package/finance-details';
import { OverlayProvider as FinanceDetailsProvider } from '@vcc-package/overlays/DEPRECATED';
import { useFinanceOptionToFinanceDetails } from '@vcc-www/api/finance-options/utils';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { ListItem } from '@vcc-package/list-item';
import { cssJoin } from '@volvo-cars/css/utils';

import { Spacing } from 'src/components/layout/Spacing';
import { useQueries } from 'src/hooks/useQueries';
import styles from './styles.module.css';
import type { LinkEntry } from '@volvo-cars/content-management-client';

export interface FinanceOptionsEntryPointsProps {
  type: 'financeOptionsEntryPoints';
  title: string;
  entryPoints: EntryPoint[];
  toggleCTAOn?: boolean;
}

interface EntryPoint {
  salesModel: SalesModelId;
  cta: LinkEntry;
}

type CombinedData = EntryPoint & FinanceOption;

export const FinanceOptionsEntryPoints: React.FC<
  React.PropsWithChildren<FinanceOptionsEntryPointsProps>
> = (props) => {
  return (
    <FinanceDetailsProvider>
      <Wrapper {...props} />
    </FinanceDetailsProvider>
  );
};

const Wrapper: React.FC<
  React.PropsWithChildren<FinanceOptionsEntryPointsProps>
> = ({ title, entryPoints, toggleCTAOn }) => {
  const { financeOptions } = useQueries();

  const combinedEntryPoint: CombinedData[] = entryPoints
    .map((entrypoint) => {
      const matchingFinanceOption = financeOptions[entrypoint.salesModel];
      if (matchingFinanceOption === undefined) {
        return null;
      }
      const combinedData = {
        ...entrypoint,
        ...matchingFinanceOption,
      };

      return combinedData;
    })
    .filter((val): val is CombinedData => val !== null);
  const { showFinanceDetails } = useFinanceDetails();

  return (
    <TrackingProvider eventCategory="finance options Entry Points">
      <FinanceDetailsProvider>
        <Spacing>
          <div className="container">
            <h2
              data-testid="finance-options-entry-points__title"
              className="heading-2 mb-32 md:mb-64 md:text-center"
            >
              {title}
            </h2>
            {combinedEntryPoint && (
              <div
                className={cssJoin(
                  'flex-col lg:justify-center lg:flex-row gap-24 m-auto',
                )}
              >
                {combinedEntryPoint.map((entryPoint, i) => (
                  <FinanceOptionEntryPoint
                    {...entryPoint}
                    key={i}
                    toggleCTAOn={toggleCTAOn}
                    showFinanceDetails={showFinanceDetails}
                  />
                ))}
              </div>
            )}
          </div>
        </Spacing>
      </FinanceDetailsProvider>
    </TrackingProvider>
  );
};

const FinanceOptionEntryPoint: React.FC<
  React.PropsWithChildren<
    CombinedData & {
      showFinanceDetails: (props: ShowFinanceDetailsProps) => void;
      toggleCTAOn?: boolean;
    }
  >
> = ({
  displayName,
  shortDescription,
  thumbnail,
  description,
  image,
  disclaimer,
  howItWorks,
  services,
  cta,
  toggleCTAOn,
  showFinanceDetails,
}) => {
  const translate = useSharedComponentsTranslate();
  const financeDetailsData = useFinanceOptionToFinanceDetails({
    displayName,
    description,
    image,
    disclaimer,
    howItWorks,
    services,
  });

  const ga4Tracker = useTracker({}, { mode: 'ga4' });
  const eventCategory = 'finance options entry points';

  return (
    <div className={styles['item-container']}>
      <ListItem
        testId="finance-options-entry-point__entry"
        title={cta?.text ?? displayName}
        titleSources={[cta?.text ? 'meta:cta.text' : 'meta:displayName']}
        description={shortDescription}
        thumbnail={
          <BaseImage
            width={72}
            height={72}
            src={thumbnail}
            alt={displayName}
            className="h-full"
            data-autoid="finance-options-entry-point__finance-option-image"
          />
        }
        {...(toggleCTAOn && cta
          ? {
              href: cta.href,
              onClick: () => {
                ga4Tracker.customEvent({
                  eventCategory,
                  eventLabel: `${cta.text || displayName} | ${cta.href}`,
                  eventAction: 'link|click',
                });
              },
            }
          : {
              onClick: () => {
                showFinanceDetails({
                  title: translate(
                    'FinanceDetails.overlay.financialOptionTitle',
                  ),
                  financeDetails: financeDetailsData,
                });
                ga4Tracker.customEvent({
                  eventCategory,
                  eventLabel: `${displayName} | open overlay`,
                  eventAction: 'button|click',
                });
              },
            })}
        linkVariant="chevron-title"
        descriptionVariant="font-16"
      />
    </div>
  );
};
