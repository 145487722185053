import gql from 'graphql-tag';
import {
  SALES_LEVEL_DATA_FRAGMENT,
  EMISSIONS_AND_SAFETY_DATA_FRAGMENT,
  ENERGY_EFFICIENCY_SETTINGS_FRAGMENT,
  SALES_MODEL_SETTINGS_FRAGMENT,
} from './entire-offer.fragments.query';

export const STOCK_CAR_QUERY = gql`
  query StockCar(
    $filter: CLESearchFilterWithDistributionChannel!
    $locale: String!
    $url: String!
  ) {
    stockCars(filter: $filter) {
      hits {
        vehicle {
          duplicatesIdentifier
          vin
          model {
            code
            description {
              text
            }
            modelFamily
          }
          configuration {
            pno {
              pno34PlusOptions
            }
            color {
              code
              packages
              groupCode
              description {
                text
              }
            }
            options {
              code
              packages
              groupCode
            }
            upholstery {
              code
              packages
              groupCode
            }
            packages {
              code
              groupCode
            }
            salesVersion {
              code
              name
              groupCode
            }
            seats {
              numberOfSeatsCode
              numberOfSeatsDescriptions {
                text
                language
              }
            }
            trimLevel {
              code
              name
            }
            powerTrain {
              engine {
                code
                engineType
                fuelType
              }
            }
            features {
              code
              groupCode
            }
            exteriorTheme {
              code
            }
            accessories {
              code
            }
          }

          specification {
            carKey
            carKeyExpanded {
              modelYear
            }
            carType {
              code
            }
            productLine(locale: $locale) {
              key
            }
            modelFamily(locale: $locale) {
              key
            }
            displayName(locale: $locale)
            technicalData(locale: $locale) {
              ...TechnicalData
            }
            efficiencyClassification {
              ...EnergyClassification
            }
            safetyRating(locale: $locale) {
              ...SafetyRating
            }
            tyreLabels(locale: $locale) {
              ...TyreLabels
            }
            visualizations(marketOrLocale: $locale) {
              views(backgroundColor: "f3f3f3") {
                exterior {
                  studio {
                    front {
                      sizes {
                        medium {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
            engine {
              content(locale: $locale) {
                displayName {
                  value
                }
                engineType {
                  value
                }
                # Do we need this?
                engineCode
              }
            }
            features {
              content(locale: $locale) {
                displayName {
                  value
                }
                matchingCode
              }
              code
            }
            color {
              code
              type
              content(locale: $locale) {
                displayName {
                  value
                }
                group {
                  value
                }
              }
            }
            upholstery {
              code
              type
              content(locale: $locale) {
                displayName {
                  value
                }
                group {
                  value
                }
                entityType {
                  value
                }
              }
            }
            packages {
              code
              type
              components {
                content(locale: $locale) {
                  displayName {
                    value
                  }
                }
              }
              content(locale: $locale) {
                displayName {
                  value
                }
              }
            }
            # Replacing DetailsConfigurationInformation, what do we want from this?
            trimFamily(locale: $locale) {
              ...SalesLevelData
            }
            model(locale: $locale) {
              ...SalesLevelData
            }
            theme(locale: $locale) {
              ...SalesLevelData
            }
            edition(locale: $locale) {
              ...SalesLevelData
            }
            trim(locale: $locale) {
              ...SalesLevelData
            }
            # ERROR
            # level(locale: $locale) {
            #   ...SalesLevelData
            # }
            modelFamily(locale: $locale) {
              ...SalesLevelData
            }

            options {
              code
              visible
              content(locale: $locale) {
                displayName {
                  value
                }
                images {
                  url
                }
                group {
                  value
                  formatted
                }
              }
              packages {
                code
              }
            }
          }
          order {
            estimatedCustomerDeliveryLeadTime
            estimatedCustomerDeliveryLeadTimeUnitLocalized
          }
        }
      }
    }

    clientConfigurationQuery(PARAMETERS: [{ key: "url", value: $url }]) {
      configuration {
        salesModelSettings {
          ...SalesModelSettings
        }
        energyEfficiencySettings {
          ...EnergyEfficiencySettings
        }
      }
    }
  }

  ${SALES_LEVEL_DATA_FRAGMENT}
  ${EMISSIONS_AND_SAFETY_DATA_FRAGMENT}
  ${ENERGY_EFFICIENCY_SETTINGS_FRAGMENT}
  ${SALES_MODEL_SETTINGS_FRAGMENT}
`;
