import { EntireOfferLastStateChange } from '../../entire-offer.types';

import { usePrevious } from '../../entire-offer.utils';
import { OfferQuery } from '../../../queries/fed-graph/entire-offer.query.gql-types';
import { getLastChange } from './entire-offer.last-state-change.utils';
import { useRef } from 'react';

export const useEntireOfferLastStateChange = (
  offerData?: OfferQuery,
): EntireOfferLastStateChange => {
  const lastChangeRef = useRef<EntireOfferLastStateChange>({
    added: [],
    removed: [],
    previousToken: '',
  });
  const lastOfferData = usePrevious(offerData);
  const token = offerData?.offerByToken?.configuration.token?.short ?? '';
  const lastToken = usePrevious(token);
  const hasTokenChanged = lastToken !== token;

  if (offerData && lastOfferData && hasTokenChanged) {
    lastChangeRef.current = getLastChange({
      fromState: lastOfferData,
      toState: offerData,
    });
  }

  return lastChangeRef.current;
};
