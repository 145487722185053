import gql from 'graphql-tag';
import {
  ACCESSORIES_AND_SERVICES_FRAGMENT,
  APPLIED_DISCOUNTS_FRAGMENT,
  DISCOUNTS,
  FINANCE_PRICING_FRAGMENT,
  OFFER_CONFIGURATION_SELECTIONS_WITH_PRICE_SUMMARY_FRAGMENT,
  PRICE_FRAGMENT,
  PRICE_SUMMARY_FRAGMENT,
} from './entire-offer.fragments.query';

export const OFFER_QUERY = gql`
  query Offer(
    $locale: String!
    $priceConfigInputV2: PriceConfigurationInputV2
    $token: String!
    $isStockCar: Boolean!
  ) {
    carByToken(token: $token) @skip(if: $isStockCar) {
      policy
    }
    offerByToken(token: $token) {
      offerKey
      baseOffer
      customerType {
        id
        content(locale: $locale) {
          displayName {
            value
          }
        }
      }
      salesModel {
        identifier: id
        content(locale: $locale) {
          displayName {
            value
          }
        }
      }
      contractLength {
        id
        content(locale: $locale) {
          value
          formatted
        }
      }
      mileage {
        id
        content(locale: $locale) {
          value
        }
      }
      configuration {
        token {
          short
        }
        selections {
          ...OfferSelectionWithPriceSummaryData
        }
      }
      priceSummary(priceConfigInput: $priceConfigInputV2) {
        carPrice {
          price {
            displayPrice {
              ...PriceData
            }
            vatAmount {
              ...PriceData
            }
          }
          priceBeforeDiscount {
            displayPrice {
              ...PriceData
            }
            vatAmount {
              ...PriceData
            }
          }
        }
        mileagePrice {
          displayPrice {
            ...PriceData
          }
          vatAmount {
            ...PriceData
          }
        }
        aggregations {
          total {
            paymentPlan {
              afterCheckout {
                displayPrice {
                  ...PriceData
                }
                vatAmount {
                  ...PriceData
                }
                priceInclTax {
                  ...PriceData
                }
              }
              atCheckout {
                displayPrice {
                  ...PriceData
                }
                vatAmount {
                  ...PriceData
                }
                priceInclTax {
                  ...PriceData
                }
              }
              recurring {
                displayPrice {
                  ...PriceData
                }
                vatAmount {
                  ...PriceData
                }
                priceInclTax {
                  ...PriceData
                }
              }
            }
          }
        }
        price {
          displayPrice {
            ...PriceData
          }
          vatAmount {
            ...PriceData
          }
          priceInclTax {
            ...PriceData
          }
        }
        # TODO: This does not include all the required fields.
        carPriceSummary {
          ...PriceSummaryFields

          availableDownPayments: availableDownpayments {
            ...PriceData
          }
        }
        payments {
          paymentPosition
          paymentType
          amount {
            displayPrice {
              ...PriceData
            }
            priceInclVat {
              ...PriceData
            }
            vatAmount {
              ...PriceData
            }
          }
        }
        discounts {
          ...Discounts
        }
        appliedDiscounts {
          ...AppliedDiscounts
        }
      }
      financingPriceSummary(priceConfigInput: $priceConfigInputV2) {
        ...FinancePricing
      }
      products {
        ...AccessoriesAndServices
      }
    }
  }
  ${OFFER_CONFIGURATION_SELECTIONS_WITH_PRICE_SUMMARY_FRAGMENT}
  ${PRICE_SUMMARY_FRAGMENT}
  ${FINANCE_PRICING_FRAGMENT}
  ${ACCESSORIES_AND_SERVICES_FRAGMENT}
  ${DISCOUNTS}
  ${PRICE_FRAGMENT}
  ${APPLIED_DISCOUNTS_FRAGMENT}
`;
