import React from 'react';
import { useFinanceDetails } from '@vcc-package/finance-details';
import { useTracker } from '@volvo-cars/tracking';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { useFinanceOptionToFinanceDetails } from '@vcc-www/api/finance-options/utils';

import { type GridProps, type MergedOption } from './finopt-comparison.types';
import {
  GA_EVENT_CATEGORY,
  salesModelToLabel,
} from './finopt-comparison.utils';
import styles from './finopt-comparison.module.css';

const LinkContainer = ({ option }: { option: MergedOption }) => {
  const ga4Tracker = useTracker({}, { mode: 'ga4' });
  const translate = useSharedComponentsTranslate();
  const financeDetailsData = useFinanceOptionToFinanceDetails(option);
  const { showFinanceDetails } = useFinanceDetails();

  const linkProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = {};
  const gaEventLabel = `${option?.financeOptionLinkText}|${
    salesModelToLabel[option.salesModel]
  }`;

  if (option.financeOptionLinkUrl) {
    linkProps['href'] = option.financeOptionLinkUrl.href;
    linkProps['onClick'] = () => {
      ga4Tracker.customEvent({
        eventCategory: GA_EVENT_CATEGORY,
        eventAction: 'link|click',
        eventLabel: gaEventLabel,
      });
    };
  } else {
    linkProps['onClick'] = () => {
      showFinanceDetails({
        title: translate('FinanceDetails.overlay.financialOptionTitle'),
        financeDetails: financeDetailsData,
      });
      ga4Tracker.customEvent({
        eventCategory: GA_EVENT_CATEGORY,
        eventAction: 'overlay|click',
        eventLabel: gaEventLabel,
      });
    };
  }

  const cta = React.createElement(
    option.financeOptionLinkUrl ? 'a' : 'button',
    { className: 'button-text', ['data-color']: 'accent', ...linkProps },
    option?.financeOptionLinkText,
  );

  return <div className="flex mt-32">{cta}</div>;
};

const LinksGrid = ({
  firstSalesModel,
  secondSalesModel,
  thirdSalesModel,
  options,
}: GridProps) => {
  const firstFinanceOption = options.find(
    ({ salesModel }) => salesModel === firstSalesModel,
  ) as MergedOption;
  const secondFinanceOption = options.find(
    ({ salesModel }) => salesModel === secondSalesModel,
  ) as MergedOption;
  const thirdFinanceOption = options.find(
    ({ salesModel }) => salesModel === thirdSalesModel,
  ) as MergedOption;

  return (
    <div
      className={styles['grid-container']}
      data-testid="finance-options-comparison:links"
    >
      <LinkContainer option={firstFinanceOption} />
      <LinkContainer option={secondFinanceOption} />
      {thirdSalesModel && <LinkContainer option={thirdFinanceOption} />}
    </div>
  );
};

export default LinksGrid;
